import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '../../assets/icons/home.svg';
import DiversityIcon from '../../assets/icons/diversity.svg';
import AccountBalanceIcon from '../../assets/icons/account_balance.svg';
import DescriptionIcon from '../../assets/icons/description.svg';
import GroupUsersIcon from '../../assets/icons/group_users.svg';
import QuickReferenceAllIcon from '../../assets/icons/quick_reference_all.svg';
import { useMenu } from 'contexts/menu/menu_context';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const menus = [
  {
    id: 'home',
    title: 'E-SEIAF - Início',
    icon: HomeIcon,
    route: ROUTES.HOME,
  },
  {
    id: 'pessoas',
    title: 'Gestão de Pessoas e Instituições',
    icon: DiversityIcon,
    subMenus: [
      { title: 'Agricultores Familiares', route: null },
      { title: 'CICAAF', route: null },
      { title: 'Assentamentos e Comunidades Quilombolas', route: null },
      { title: 'CUFFA', route: null },
      { title: 'Instituições', route: ROUTES.INSTITUTION },
    ],
  },
  {
    id: 'governanca',
    title: 'Gestão de Governança e Controle Social',
    icon: AccountBalanceIcon,
    subMenus: [
      { title: 'Secretarias e Órgãos Municipais', route: null },
      { title: 'CMDRS', route: null },
    ],
  },
  {
    id: 'servicos',
    title: 'Gestão de Serviços e Documentação',
    icon: DescriptionIcon,
    subMenus: [
      { title: 'SIM', route: null },
      { title: 'PMFA', route: null },
      { title: 'Regularização Ambiental', route: null },
    ],
  },
  {
    id: 'usuarios',
    title: 'Gestão de Usuários',
    icon: GroupUsersIcon,
    subMenus: [
      { title: 'Usuários', route: ROUTES.MANAGE_USER },
      { title: 'Perfis de usuário', route: null },
      { title: 'Termos de uso', route: null },
    ],
  },
  {
    id: 'relatorios',
    title: 'Relatórios',
    icon: QuickReferenceAllIcon,
    subMenus: [{ title: 'Registros de auditoria', route: null }],
  },
];

const SideMenu: React.FC = () => {
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
  const { isMenuOpen, toggleMenu } = useMenu();
  const navigate = useNavigate();

  const handleToggle = (menuId: string) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

  const handleNavigation = (route: string | null) => {
    if (route !== null) {
      navigate(route);
      toggleMenu();
    }
  };

  const renderSubMenu = (
    subMenus: { title: string; route: string | null }[],
  ) => (
    <Collapse in={true} timeout='auto' sx={{ width: '100%' }}>
      <List disablePadding sx={{ width: '100%', padding: '15px 0' }}>
        {subMenus.map((subMenu, index) => (
          <ListItemButton
            key={index}
            sx={{ width: '100%', padding: '0 0 5px' }}
            onClick={() => handleNavigation(subMenu.route)}
          >
            <ListItemText secondary={subMenu.title} />
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  );

  const renderMenu = () => (
    <List sx={{ width: '100%' }}>
      {menus.map((menu) => (
        <ListItem
          key={menu.id}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <ListItemButton
            sx={{ padding: '0px', width: '100%' }}
            onClick={() => {
              if (menu.subMenus) {
                handleToggle(menu.id);
              } else if (menu.route) {
                handleNavigation(menu.route);
              }
            }}
          >
            <ListItemIcon>
              <img src={menu.icon} alt={`${menu.title} Icon`} />
            </ListItemIcon>
            <ListItemText primary={menu.title} />
            {menu.subMenus ? (
              openMenus[menu.id] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItemButton>
          {menu.subMenus && openMenus[menu.id] && renderSubMenu(menu.subMenus)}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box>
      <Drawer anchor='right' open={isMenuOpen} onClose={toggleMenu}>
        <Box
          sx={{
            width: '380px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant='h5'
            sx={{ paddingLeft: '16px', paddingTop: '16px' }}
          >
            Serviços
          </Typography>
          {renderMenu()}
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideMenu;

import { Button, Stack } from '@mui/material';
import AlertModal from 'components/shared/alert_modal';
import DataConfirmation from 'components/shared/data_confirmation';
import { PageTitle } from 'components/shared/page_title';
import React from 'react';

import useStep2 from './useStep2';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
  question5: string | null;
};

interface Step2Props {
  onNext: (answers: AnswersType) => void;
}

const Step2: React.FC<Step2Props> = ({ onNext }) => {
  const {
    isSuccessModalOpen,
    isErrorModalOpen,
    errorMessage,
    isLoading,
    formData,
    maskCpf,
    maskPhone,
    maskDate,
    handleSave,
    handleCloseSuccessModal,
    handleCloseErrorModal,
  } = useStep2({ onNext });

  return (
    <>
      <PageTitle
        title='Confirmação dos dados'
        subtitle='Confira todas as informações que foram preenchidas, e altere-as caso necessário.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        <DataConfirmation
          title='Dados Pessoais'
          data={[
            {
              label: 'Nome Completo',
              value: formData?.dadosPessoais?.nomeCompleto,
            },
            {
              label: 'CPF',
              value: maskCpf(formData?.dadosPessoais?.cpf),
            },
            {
              label: 'Email',
              value: formData?.dadosPessoais?.email,
            },
            {
              label: 'Município',
              value: formData?.dadosPessoais?.municipio,
            },
            {
              label: 'Cargo',
              value: formData?.dadosPessoais?.cargo,
            },
            {
              label: 'Função',
              value: formData?.dadosPessoais?.funcao,
            },
            {
              label: 'Telefone 1',
              value: maskPhone(formData?.dadosPessoais?.telefone1),
            },
            {
              label: 'Telefone 2',
              value: maskPhone(formData?.dadosPessoais?.telefone2),
            },
          ]}
        />

        <DataConfirmation
          title='Dados Login'
          data={[
            {
              label: 'Permissão do usuário',
              value: formData?.dadosLogin?.role,
            },
            {
              label: 'Senha',
              value: '******',
            },
            {
              label: 'Confirmação de Senha',
              value: '******',
            },
          ]}
        />

        <DataConfirmation
          title='Dados do Cadastro'
          data={[
            {
              label: 'Vigência - Data Inicial',
              value: maskDate(formData?.dadosVigencia?.dataInicial),
            },
            {
              label: 'Vigência - Data Final',
              value: maskDate(formData?.dadosVigencia?.dataFinal),
            },
            {
              label: 'Data final Indeterminada',
              value: formData?.dadosVigencia?.vigenciaIndeterminada
                ? 'Sim'
                : 'Não',
            },
          ]}
        />

        <DataConfirmation
          title='Anexar Arquivo'
          atas={[
            {
              titlePDF: 'Arquivo Enviado',
              label: 'IMG-20240328-WA0060',
              fileName: 'Arquivo PDF',
              fileSize: '3299 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
        />

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary' disabled>
            Voltar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Salvando...' : 'Confirmar e salvar'}
          </Button>
        </Stack>
        <Stack />

        <AlertModal
          open={isSuccessModalOpen}
          title='Etapa Concluída com Sucesso'
          message='O preenchimento dos dados dessa etapa foi concluído com sucesso.'
          buttonLabel='Avançar'
          onClose={handleCloseSuccessModal}
        />

        <AlertModal
          open={isErrorModalOpen}
          title='Erro'
          message={errorMessage}
          buttonLabel='Fechar'
          onClose={handleCloseErrorModal}
        />
      </Stack>
    </>
  );
};

export default Step2;

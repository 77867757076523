import axios from 'axios';
import { authService } from 'services/auth/auth_service';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/users/validity';

export const getValidity = async (uuid: string): Promise<any> => {
  const response = await axios.get<any>(`${API_URL}/?userUuid=${uuid}`);
  return response.data;
};

export const addValidity = async (data: any): Promise<any> => {
  const response = await axios.post<any>(`${API_URL}`, data);
  return response.data;
};

export const editValidity = async (uuid: string, data: any): Promise<any> => {
  const token = authService.getTokenAPIInterna();
  const response = await axios.patch<any>(`${API_URL}/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

import React, { useState } from 'react';
import { Header } from 'components/home';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import { StepperHorizontal } from 'components/shared/stepper_horizontal';
import Step1 from './Step1';
import Step2 from './Step2';

export interface InstitutionI {
  id: number;
  name: string;
}

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
};

const ComplianceTerm: React.FC = () => {
  const steps = ['Cumprimento do Termo', 'Confirmação dos Dados'];
  const [activeStep, setActiveStep] = useState<number>(0);

  const [answers, setAnswers] = useState<AnswersType>({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
  });

  const handleNext = (newAnswers?: AnswersType) => {
    if (newAnswers) setAnswers(newAnswers);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <MenuProvider>
      <TopNavBar />
      <Header title='CIFA - Cadastrar Município - 2026' showBackButton={true} />
      <StepperHorizontal activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <Step1 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 1 && <Step2 answers={answers} onBack={handleBack} />}
    </MenuProvider>
  );
};

export default ComplianceTerm;

import axios from 'axios';
import { authService } from 'services/auth/auth_service';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/ibge/locations';

export const getMunicipalitiesDropdown = async (): Promise<any> => {
  const eseiafToken = authService.getTokenAPIInterna();

  const response = await axios.get(`${API_URL}`, {
    params: { uf: 'MT' },
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

import { Box, Button, Stack, Typography } from '@mui/material';
import ContainerPainel from 'components/container_painel';
import AlertModal from 'components/shared/alert_modal';
import DataConfirmation from 'components/shared/data_confirmation';
import useValidateTermAdhesion from './use_validate_term_adhesion';
import { ListAgreementTermResponse } from 'api/agreement-term/api.types';
import { useLocation } from 'react-router-dom';
import { formatDate } from 'utils/format_date';
import { Colors } from 'theme';
import EditIcon from '@mui/icons-material/Edit';
import SignPasswordModal from 'components/shared/sign_password_modal';

interface LocationState {
  state?: ListAgreementTermResponse;
}

const ValidateTermAdhesion = () => {
  const location = useLocation() as unknown as LocationState;
  const term = location.state;
  const {
    isModalOpen,
    handleSave,
    handleCloseModal,
    handleCloseSignModal,
    handleConfirmSign,
    handleOpenSignModal,
    isSignModalOpen,
    user,
  } = useValidateTermAdhesion({ term });
  const isMunicipalManager =
    user?.permission?.name === 'REPRESENTANTE LEGAL MUNICIPAL';
  const isStateManager =
    user?.permission?.name === 'REPRESENTANTE LEGAL ESTADUAL';

  if (!term) {
    return <p>Termo não encontrado.</p>;
  }

  return (
    <ContainerPainel pagina='Renovar Termo de Adesão Municipal' showBackButton>
      <Stack marginX='4rem' spacing='2rem'>
        <DataConfirmation title='Conteúdo do Termo de Adesão' showTerm />

        <DataConfirmation
          title='Dados do Termo de Adesão'
          data={[
            {
              label: 'Prefeitura',
              value: term?.institution?.name,
            },
            {
              label: 'Data da Assinatura - Representante Legal Municipal',
              value: formatDate(term?.legalMunicipalRepresentativeSignatureAt),
            },
            {
              label: 'Data da Assinatura - Representante Legal Estadual',
              value: formatDate(term?.legalStateRepresentativeSignatureAt),
            },
          ]}
        />
        {term?.stateRepresentative && (
          <DataConfirmation
            title='Dados do Representante Legal Estadual'
            data={[
              {
                label: 'Nome Completo',
                value: term?.stateRepresentative?.name,
              },
              {
                label: 'CPF',
                value: 'Não informado',
              },
              {
                label: 'E-mail',
                value: term?.stateRepresentative?.email,
              },
              {
                label: 'Município',
                value: 'Não informado',
              },
              {
                label: 'Cargo',
                value: 'Representante Legal Estadual',
              },
              {
                label: 'Função',
                value: 'Não informado',
              },
              {
                label: 'Telefone 1',
                value: 'Não informado',
              },
              {
                label: 'Telefone 2',
                value: 'Não informado',
              },
            ]}
          />
        )}

        {term?.municipalRepresentative && (
          <DataConfirmation
            title='Dados do Representante Legal Municipal'
            data={[
              {
                label: 'Nome Completo',
                value: term?.municipalRepresentative?.name,
              },
              {
                label: 'CPF',
                value: 'Não informado',
              },
              {
                label: 'E-mail',
                value: term?.municipalRepresentative?.email,
              },
              {
                label: 'Município',
                value: 'Não informado',
              },
              {
                label: 'Cargo',
                value: 'Representante Legal Municipal',
              },
              {
                label: 'Função',
                value: 'Não informado',
              },
              {
                label: 'Telefone 1',
                value: 'Não informado',
              },
              {
                label: 'Telefone 2',
                value: 'Não informado',
              },
            ]}
          />
        )}

        {/* Assinatura Representante Municipal */}
        <Stack
          spacing={2}
          sx={{
            backgroundColor: Colors.BRANCO_GOV,
            borderRadius: '8px',
            marginTop: '1rem',
            padding: '1rem',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant='h6' fontWeight='bold'>
            Assinatura Digital - Representante Legal Municipal
          </Typography>

          {/* Campo de Assinatura Digital */}
          <Button
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#0048B6',
              gap: '10px',
              border: '1px solid #0048B6',
              borderRadius: '6px',
              padding: '6px',
              backgroundColor: '#fff',
              '&:disabled': {
                backgroundColor: '#BDBDBD',
                color: '#00000042',
                opacity: 0.6,
              },
            }}
            startIcon={<EditIcon />}
            disabled={!isMunicipalManager}
            onClick={handleOpenSignModal}
          >
            ASSINAR COM SENHA E-SEIAF
          </Button>
          {term?.municipalRepresentative && (
            <Box
              sx={{
                backgroundColor: '#F5F5F5',
                paddingTop: '13px',
                paddingBottom: '13px',
                paddingLeft: '16px',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  color: '#000000DE',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <EditIcon />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#000000DE',
                    fontWeight: '500',
                  }}
                >
                  ASS-DIGITAL-240824
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>

        {/* Assinatura Representante Estadual */}
        <Stack
          spacing={2}
          sx={{
            backgroundColor: Colors.BRANCO_GOV,
            borderRadius: '8px',
            marginTop: '1rem',
            padding: '1rem',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant='h6' fontWeight='bold'>
            Assinatura Digital - Representante Legal Estadual
          </Typography>

          {/* Campo de Assinatura Digital */}
          <Button
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#0048B6',
              gap: '10px',
              border: '1px solid #0048B6',
              borderRadius: '6px',
              padding: '6px',
              backgroundColor: '#fff',
              '&:disabled': {
                backgroundColor: '#BDBDBD',
                color: '#00000042',
                opacity: 0.6,
              },
            }}
            startIcon={<EditIcon />}
            disabled={!isStateManager}
            onClick={handleOpenSignModal}
          >
            ASSINAR COM SENHA E-SEIAF
          </Button>
          {term?.stateRepresentative && (
            <Box
              sx={{
                backgroundColor: '#F5F5F5',
                paddingTop: '13px',
                paddingBottom: '13px',
                paddingLeft: '16px',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  color: '#000000DE',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <EditIcon />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#000000DE',
                    fontWeight: '500',
                  }}
                >
                  ASS-DIGITAL-240824
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
      </Stack>

      {/* Botões */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={2}
        marginTop='20px'
      >
        <Button
          variant='text'
          sx={{
            color: '#1976D2',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          Voltar
        </Button>

        <Button
          variant='contained'
          onClick={handleSave}
          sx={{
            backgroundColor: '#0048B6',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            '&:hover': {
              backgroundColor: '#003399',
            },
          }}
        >
          Confirmar e salvar
        </Button>
      </Box>

      {/* Modal de Senha */}
      <SignPasswordModal
        open={isSignModalOpen}
        onClose={handleCloseSignModal}
        onConfirm={handleConfirmSign}
      />

      {/* Modal */}
      <AlertModal
        open={isModalOpen}
        title='Termo de Adesão Municipal Renovado'
        message='O Termo de Adesão Municipal foi renovado com sucesso.'
        buttonLabel='Voltar'
        onClose={handleCloseModal}
      />
    </ContainerPainel>
  );
};

export default ValidateTermAdhesion;

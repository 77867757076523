import { CircularProgress, Stack, Typography } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import { Header } from 'components/home';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import CustomAccordion from 'components/shared/custom_accordion';
import { CustomLabel } from 'components/shared';
import { getByIdInstitution } from 'api/institutions/institution.api';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { InstitutionStatusEnum } from 'common/enums';
import ContainerPainel from 'components/container_painel';

const InstitutionView: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const fetchInstitution = async () => {
    const result = await getByIdInstitution(uuid as string);
    return result;
  };

  const { data } = useQuery(['fetchInstitution'], fetchInstitution, {
    keepPreviousData: true,
    retry: 1,
  });

  return (
    <ContainerPainel pagina='Instituições' showBackButton={true}>
      <Box sx={{ marginTop: '24px' }}>
        <Typography
          variant='h5'
          fontWeight={400}
          fontSize='1.5rem'
          textAlign='center'
        >
          Visualizar Instituição
        </Typography>

        <Typography
          variant='body1'
          fontWeight={400}
          fontSize='1rem'
          textAlign='center'
          mt='1.5rem'
        >
          Visualize todos os dados de Instituição no sistema.
        </Typography>
      </Box>

      {data ? (
        <Box sx={{ mx: '32px', mt: '32px' }}>
          <CustomAccordion
            accordionSummaryText={'Dados cadastrais'}
            style={{ boxShadow: 'none' }}
          >
            <Box>
              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel text='Nome Fantasia' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.fantasyName as string}
                    labelStyle={valueStyle}
                  />
                </Box>

                <Box sx={columnStyle}>
                  <CustomLabel text='CNPJ' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.identification as string}
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>

              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel text='Razão Social' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.name as string}
                    labelStyle={valueStyle}
                  />
                </Box>

                <Box sx={columnStyle}>
                  <CustomLabel text='CEP' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.address?.zipcode as string}
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>

              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel text='Endereço' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.address?.street as string}
                    labelStyle={valueStyle}
                  />
                </Box>

                <Box sx={columnStyle}>
                  <CustomLabel text='Número' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.address?.number as string}
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>

              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel text='Bairro' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.address?.district as string}
                    labelStyle={valueStyle}
                  />
                </Box>

                <Box sx={columnStyle}>
                  <CustomLabel text='Município' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.address?.municipality as string}
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>

              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel text='Email' labelStyle={labelStyle} />
                  <CustomLabel
                    text={data?.email as string}
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>

              <Box sx={lineStyle}>
                {data?.contactList?.map((contact, index) => (
                  <>
                    <Box sx={columnStyle}>
                      <CustomLabel
                        text={`Telefone ${index + 1}`}
                        labelStyle={labelStyle}
                      />
                      <CustomLabel
                        text={contact.value}
                        labelStyle={valueStyle}
                      />
                    </Box>
                  </>
                ))}
              </Box>
            </Box>
          </CustomAccordion>

          <CustomAccordion
            accordionSummaryText={'Dados administrativos'}
            style={{ boxShadow: 'none' }}
          >
            <Box>
              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel
                    text='Grupo da Instituição'
                    labelStyle={labelStyle}
                  />
                  <CustomLabel
                    text={data?.group?.name as string}
                    labelStyle={valueStyle}
                  />
                </Box>

                <Box sx={columnStyle}>
                  <CustomLabel
                    text='Perfil da Instituição'
                    labelStyle={labelStyle}
                  />
                  <CustomLabel
                    text={data?.profile?.name as string}
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>

              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel
                    text='Possui CAF jurídica ativa (Obs.: Antiga DAP jurídica)?'
                    labelStyle={labelStyle}
                  />
                  <CustomLabel
                    text={data?.caf ? 'Sim' : 'Não'}
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>

              <Box sx={lineStyle}>
                <Box sx={columnStyle}>
                  <CustomLabel text='Cadastro Ativo?' labelStyle={labelStyle} />
                  <CustomLabel
                    text={
                      data?.statusList
                        ?.map((status) => status.description)
                        .includes(InstitutionStatusEnum.REGISTER_ENABLED)
                        ? 'Sim'
                        : 'Não'
                    }
                    labelStyle={valueStyle}
                  />
                </Box>

                <Box sx={columnStyle}>
                  <CustomLabel
                    text='Bloquear Cadastro?'
                    labelStyle={labelStyle}
                  />
                  <CustomLabel
                    text={
                      data?.statusList
                        ?.map((status) => status.description)
                        .includes(InstitutionStatusEnum.REGISTER_BLOCKED)
                        ? 'Sim'
                        : 'Não'
                    }
                    labelStyle={valueStyle}
                  />
                </Box>
              </Box>
            </Box>
          </CustomAccordion>
        </Box>
      ) : (
        <Stack justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Stack>
      )}
    </ContainerPainel>
  );
};

const lineStyle: SxProps<Theme> | undefined = {
  display: 'flex',
};

const columnStyle: SxProps<Theme> | undefined = {
  width: '50%',
};

const labelStyle = {
  color: '#00000099',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  mt: '16px',
};

const valueStyle = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textAlign: 'left',
};

export default InstitutionView;

import React, { useCallback, useState } from 'react';
import AlertDialog from './AlertDialog';
import ConfirmDialog from './ConfirmDialog';
import {
  AlertOptionsType,
  ConfirmOptionsType,
  DialogContext,
} from './DialogContext';

type DialogProviderProps = {
  children: React.ReactNode;
};

const CONFIRM_DEFAULT_OPTIONS: ConfirmOptionsType = {
  title: '',
  content: '',
  cancellationText: 'FECHAR',
  confirmationText: 'CONFIRMAR',
};

const ALERT_DEFAULT_OPTIONS: AlertOptionsType = {
  title: '',
  content: '',
  type: 'primary',
  okText: 'OK 1',
};

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const { alert, alertOpen, alertOption, handleOk } = useAlert();
  const { confirm, confirmOption, handleConfirm, handleCancel } = useConfirm();

  return (
    <DialogContext.Provider
      value={{
        alert,
        confirm,
      }}
    >
      {children}
      <ConfirmDialog
        open={!!confirmOption}
        {...confirmOption!}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      <AlertDialog open={alertOpen} {...alertOption!} onClick={handleOk} />
    </DialogContext.Provider>
  );
};

function useAlert() {
  const [resolvePromise, setResolvePromise] = useState<
    ((value: boolean) => void) | null
  >(null);
  const [alertOption, setAlertOption] = useState<AlertOptionsType | null>(null);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const alert = useCallback((option: Partial<AlertOptionsType>) => {
    setAlertOption({
      ...ALERT_DEFAULT_OPTIONS,
      ...option,
    });
    setAlertOpen(true);

    return new Promise<boolean>((resolve) => {
      setResolvePromise(() => (value: boolean) => {
        if (value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }, []);

  const handleOk = useCallback(() => {
    setAlertOpen(false);
    if (resolvePromise) {
      resolvePromise(true);
    }
  }, [resolvePromise]);

  return { alert, alertOpen, alertOption, handleOk };
}

function useConfirm() {
  const [resolvePromise, setResolvePromise] = useState<
    ((value: boolean) => void) | null
  >(null);
  const [confirmOption, setConfirmOption] = useState<ConfirmOptionsType | null>(
    null
  );
  const confirm = useCallback((option: Partial<ConfirmOptionsType>) => {
    setConfirmOption({
      ...CONFIRM_DEFAULT_OPTIONS,
      ...option,
    });

    return new Promise<boolean>((resolve) => {
      setResolvePromise(() => (value: boolean) => {
        if (value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }, []);

  const handleConfirm = useCallback(() => {
    setConfirmOption(null);
    if (resolvePromise) {
      resolvePromise(true);
    }
  }, [resolvePromise]);

  const handleCancel = useCallback(() => {
    setConfirmOption(null);
    if (resolvePromise) {
      resolvePromise(false);
    }
  }, [resolvePromise]);
  return { confirm, confirmOption, handleConfirm, handleCancel };
}

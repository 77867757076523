import {
  PartialUpdateUserData,
  UpdateVigencia,
  UserListQuery,
} from './api.types';
import { authService } from 'services/auth/auth_service';

import {
  fetchConsultar,
  fetchCadastrar,
  fetchAlterar,
  ResponseAPI,
  ResponseJSON,
} from '../../api/index';

const ROTA = 'users';

export const getUserDetails = async (
  uuid: string,
): Promise<ResponseAPI | ResponseJSON> => {
  return await fetchConsultar(`${ROTA}/details/${uuid}`);
};

export const getUserVigencias = async (
  uuid: string,
): Promise<ResponseAPI | ResponseJSON> => {
  return await fetchConsultar(`${ROTA}/validity/?userUuid=${uuid}`);
};

export const postUserVigencias = async (
  uuid: string,
  data: UpdateVigencia,
): Promise<ResponseAPI | ResponseJSON> => {
  return await fetchAlterar(
    `${ROTA}/validity/${uuid}`,
    data as Record<string, any>,
  );
};

export const checkDuplicateUser = async (
  cpf?: string,
  email?: string,
): Promise<ResponseAPI | ResponseJSON> => {
  let Query = '';
  if (cpf) Query += `cpf=${encodeURIComponent(cpf)}&`;
  if (email) Query += `email=${encodeURIComponent(email)}&`;

  return await fetchConsultar(`${ROTA}/check-duplicate/?${Query}`);
};

export const blockUsers = async (userUuids: string[]): Promise<ResponseAPI> => {
  return await fetchCadastrar(`${ROTA}/block`, { uuidToBlockList: userUuids });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUser = async (userData: any): Promise<ResponseAPI> => {
  return await fetchCadastrar(ROTA, userData);
};

export const partialUpdateUser = async (
  uuid: string,
  data: PartialUpdateUserData,
): Promise<ResponseAPI> => {
  return await fetchAlterar(`${ROTA}/${uuid}`, data as Record<string, any>);
};

export const getListUsersByFilters = async (
  filters: UserListQuery,
): Promise<ResponseAPI | ResponseJSON> => {
  const params = new URLSearchParams();

  params.append('sortBy', 'created_at');

  if (filters?.nome) {
    params.append('name', filters.nome);
  }
  if (filters?.cpf) {
    params.append('identification', filters.cpf);
  }
  if (filters?.groupUuid) {
    params.append('groupUuid', filters.groupUuid);
  }
  if (filters?.profileUuid) {
    params.append('profileUuid', filters.profileUuid);
  }
  if (filters?.permissionUuid) {
    params.append('permissionUuid', filters.permissionUuid);
  }
  if (filters?.page) {
    params.append('page', filters.page);
  }
  if (filters?.limit) {
    params.append('take', filters.limit);
  }
  if (filters?.sort) {
    params.append('orderBy', filters.sort);
  }
  if (filters?.uuid) {
    params.append('uuid', filters.uuid);
  }

  return await fetchConsultar(`${ROTA}?${params.toString()}`);
};

export const getUserStatusDropdown = async (): Promise<
  ResponseAPI | ResponseJSON
> => {
  return await fetchConsultar(`${ROTA}/status`);
};

export const getUsuarioAceitouOsTermoDeUso = async (
  type: string,
): Promise<ResponseAPI | ResponseJSON> => {
  const mtLoginToken = authService.getMtLoginTokeLocalStorage();

  if (!mtLoginToken) {
    throw new Error('Token do MT Login não encontrado.');
  }

  let Query = '';
  if (type) Query += `type=${type}&`;

  return await fetchConsultar(
    `${ROTA}/has-accepted-terms?${Query}`,
    mtLoginToken,
  );
};

import { CONFIG, getURLMTLogout } from './config';
import { BASE_API_URL } from 'common/const.common';

interface responseGetUserInfoMtLogin {
  userInfo: { name: string; email: string; cpf: string } | null;
  error?: { error: string; error_description: string };
}

interface responseGetTokenMtLogin {
  accessToken: string | null;
  error?: { error: string; error_description: string };
}

export const getUserInfoMtLogin = async (
  accessToken: string,
): Promise<responseGetUserInfoMtLogin> => {
  try {
    const resp = await fetch(CONFIG.url_userInfo, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: new URLSearchParams({
        access_token: accessToken,
        client_id: CONFIG.client_id,
      }),
    });

    if (!resp.ok) {
      console.error(`Erro HTTP: ${resp.status} ${resp.statusText}`);
      return {
        userInfo: null,
        error: {
          error: 'http_error',
          error_description: `Erro HTTP: ${resp.status} - ${resp.statusText}`,
        },
      };
    }

    const respJson = await resp.json();

    if (respJson.error || respJson.error_description) {
      return { userInfo: null, error: respJson };
    }

    const { given_name, family_name, email, preferred_username } = respJson;
    return {
      userInfo: {
        name: `${given_name} ${family_name}`,
        email,
        cpf: preferred_username,
      },
    };
  } catch (error) {
    return {
      userInfo: null,
      error: {
        error: 'fetch_error',
        error_description:
          'Ocorreu um erro ao tentar consultar os dados do usuário no MT Login. Por favor, verifique sua conexão ou tente novamente mais tarde.',
      },
    };
  }
};

export const getTokenMtLogin = async (
  code: string,
): Promise<responseGetTokenMtLogin> => {
  try {
    const resp = await fetch(CONFIG.url_token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: CONFIG.grant_type,
        client_id: CONFIG.client_id,
        code: code,
        redirect_uri: CONFIG.redirect_uri,
      }),
    });

    const respJson = await resp.json();

    if (respJson && respJson.access_token) {
      return { accessToken: respJson.access_token };
    } else {
      return { accessToken: null, error: respJson };
    }
  } catch (error) {
    return {
      accessToken: null,
      error: {
        error: 'fetch_error',
        error_description:
          'Ocorreu um erro ao tentar obter o token de acesso no MT Login. Por favor, verifique sua conexão ou tente novamente mais tarde.',
      },
    };
  }
};

export const getTokenAPIInterna = async (
  TokenMTLogin: string,
): Promise<any> => {
  try {
    const URL = `${BASE_API_URL}/login/mt-login`;
    const resp = await fetch(URL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${TokenMTLogin}`,
      },
    });

    const respJson = await resp.json();

    if (respJson && respJson.token) {
      return { token: respJson.token };
    } else {
      return { token: null, error: respJson };
    }
  } catch (error) {
    return {
      accessToken: null,
      error: {
        error: 'fetch_error',
        error_description:
          'Ocorreu um erro ao tentar obter o token da API Interna. Por favor, verifique sua conexão ou tente novamente mais tarde.',
      },
    };
  }
};

import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { Product } from 'pages/service_management/Cifa/2025/CifaCrud2025/FederalSchoolFeeding/CreateFederalSchoolFeeding2025';
import { formatCurrency } from 'utils/format_currency';

interface ListProductsProps {
  products: Product[];
  documentType: string;
  edit: (product: Product) => void;
  remove: (product: Product) => void;
}

export const ListProducts: React.FC<ListProductsProps> = ({
  products,
  documentType,
  edit,
  remove,
}) => {
  return (
    <>
      {products.map((product) => (
        <Card key={product.identification} sx={{ padding: '1rem' }}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <Stack>
              <Typography variant='h6'>{product.name}</Typography>
              <Typography variant='body2' color='#666666'>
                {documentType}: {product.identification}
              </Typography>
              <Typography variant='body2' color='#666666'>
                Valor: {formatCurrency(Number(product.value))}
              </Typography>
            </Stack>

            <Stack flexDirection='row'>
              <IconButton onClick={() => edit(product)}>
                <EditOutlinedIcon />
              </IconButton>

              <IconButton onClick={() => remove(product)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Stack>
          </Box>
        </Card>
      ))}
    </>
  );
};

import { ArquivoData } from 'components/user/attach_file_form';
import { MENSAGENS } from '../utils/uteis';
import { authService } from 'services/auth/auth_service';
import { isArray } from 'lodash';

export const URL_API = process.env.REACT_APP_URL_API ?? '';
export const DEV = process.env.AMBIENTE == 'DEV';

export interface PaginationModel {
  page: number;
  pageSize: number;
}

const REST_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export interface ResponseAPI {
  Status: 'OK' | 'ERRO';
  Mensagem?: string;
  dados?: any;
  total_registros?: number;
}

export type ResponseJSON = any;

export const isResponseAPI = (
  response: ResponseAPI | ResponseJSON,
): response is ResponseAPI => {
  return (
    typeof response === 'object' &&
    response !== null &&
    'Status' in response &&
    ('Mensagem' in response || 'dados' in response)
  );
};

export const STATUS_API: { OK: 'OK'; ERRO: 'ERRO' } = {
  OK: 'OK',
  ERRO: 'ERRO',
};

export const fetchCadastrar = async (
  URL: string,
  Dados?: ResponseJSON | null | undefined,
  TOKEN_PARM?: string,
): Promise<ResponseAPI> => {
  DEV && console.log('fetchCadastrar : ', URL);
  return await fetchAPI(
    `${URL_API}/${URL}`,
    REST_METHODS.POST,
    Dados,
    TOKEN_PARM,
  );
};

export const fetchAlterar = async (
  URL: string,
  Dados?: ResponseJSON | null | undefined,
  TOKEN_PARM?: string,
): Promise<ResponseAPI> => {
  DEV && console.log('fetchAlterar : ', URL);
  return await fetchAPI(
    `${URL_API}/${URL}`,
    REST_METHODS.PATCH,
    Dados,
    TOKEN_PARM,
  );
};

export const fetchDeletar = async (
  URL: string,
  TOKEN_PARM?: string,
): Promise<ResponseAPI> => {
  DEV && console.log('fetchDeletar : ', URL);
  return await fetchAPI(
    `${URL_API}/${URL}`,
    REST_METHODS.DELETE,
    null,
    TOKEN_PARM,
  );
};

const fetchAPI = async (
  URL: string,
  Metodo: string,
  Dados?: ResponseJSON | null | undefined,
  TOKEN_PARM?: string,
): Promise<ResponseAPI> => {
  try {
    const TOKEN = TOKEN_PARM ?? authService.getTokenAPIInterna();

    const Params: {
      method: string;
      headers: { Authorization: string; 'Content-Type'?: string };
      body?: string;
    } = {
      method: Metodo,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    if (Dados) {
      Params.headers['Content-Type'] = 'application/json';
      Params.body = JSON.stringify(Dados);
    }

    const Response = await fetch(`${URL}`, Params);
    console.log(Response);

    return getRespostasPadraoAPI(Response);
  } catch (error) {
    return getErrorTratado(error);
  }
};

export const fetchConsultar = async (
  URL: string,
  TOKEN_PARM?: string,
): Promise<ResponseAPI | ResponseJSON> => {
  DEV && console.log('fetchConsultar : ', URL);
  try {
    const TOKEN = TOKEN_PARM ?? authService.getTokenAPIInterna();

    const Response = await fetch(`${URL_API}/${URL}`, {
      method: REST_METHODS.GET,

      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    });
    if (Response && Response.status >= 200 && Response.status < 300) {
      return await Response.json();
    } else {
      return getRespostasPadraoAPI(Response);
    }
  } catch (error) {
    return getErrorTratado(error);
  }
};

export const fetchConsultarPaginada = async (
  URL: string,
  PaginationModel: PaginationModel,
  TOKEN_PARM?: string,
): Promise<ResponseAPI> => {
  // OBS
  // A grid trabalha com o valor da página iniciando em 0, porem melhor trablhar com 1, então sempre que for passar o valor da página para a API, adicionar +1

  DEV && console.log('fetchConsultarPaginada : ', URL);
  try {
    const TOKEN = TOKEN_PARM ?? authService.getTokenAPIInterna();

    const Response = await fetch(`${URL_API}/${URL}`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        'x-pagina': (PaginationModel.page + 1).toString(),
        'x-qtd-por-pagina': PaginationModel.pageSize.toString(),
      },
    });

    if (Response && Response.status >= 200 && Response.status < 300) {
      const Headers = Response.headers;
      return {
        Status: 'OK',
        dados: await Response.json(),
        total_registros: parseInt(Headers.get('X-Total-Registros') ?? '0'),
      };
    } else {
      return getRespostasErroPadraoAPI(Response);
    }
  } catch (error) {
    return getErrorTratado(error);
  }
};

export const fetchArquivos = async (
  URL: string,
  Dados: FormData,
  TOKEN_PARM?: string,
): Promise<ResponseAPI> => {
  DEV && console.log('fetchArquivos : ', URL);
  try {
    const TOKEN = TOKEN_PARM ?? authService.getTokenAPIInterna();

    const Response = await fetch(`${URL_API}/${URL}`, {
      method: REST_METHODS.POST,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      body: Dados,
    });
    return getRespostasPadraoAPI(Response);
  } catch (error) {
    return getErrorTratado(error);
  }
};

const getErrorTratado = (error: any): ResponseAPI => {
  function isError(error: any): error is Error {
    return error instanceof Error;
  }

  DEV &&
    console.error(
      'Erro ao executar a solicitação para a API: ',
      isError(error) ? error.message : error,
    );
  return {
    Status: 'ERRO',
    Mensagem:
      error && DEV
        ? isError(error)
          ? error.message
          : error
        : 'Erro ao conectar-se à API de comunicação',
  };
};

export const getRespostasPadraoAPI = async (
  Response: Response | null,
): Promise<ResponseAPI> => {
  if (Response && Response.status >= 200 && Response.status < 300) {
    let RespJson = null;
    try {
      RespJson = await Response.json();
    } catch (error) {
      DEV && console.error('getRespostasPadraoAPI: ', error);
    }
    return {
      Status: 'OK',
      Mensagem: 'Operação realizada com sucesso!',
      dados: RespJson,
    };
  } else {
    return getRespostasErroPadraoAPI(Response);
  }
};

export const getRespostasErroPadraoAPI = async (
  Response: Response | null,
): Promise<ResponseAPI> => {
  if (!Response) {
    return { Status: 'ERRO', Mensagem: 'Erro ao realizar a operação!' };
  }

  if (Response.status == 401 || Response.status == 403) {
    const responseJson = await Response.json();
    return {
      Status: 'ERRO',
      Mensagem:
        'Sem permissão para acessar este recurso. Tente fazer o login novamente',
      dados: responseJson,
    };
  }

  const RespJson = await Response?.json();

  const MensagemErro = isArray(RespJson?.message)
    ? RespJson?.message[0]
    : RespJson?.message ?? '';

  const TipoErro = RespJson?.error ? RespJson.error : '';
  const MensagemErroLower = MensagemErro.toLowerCase();

  if (TipoErro == 'Prisma Know Error') {
    if (MensagemErroLower.indexOf('unique') >= 0) {
      switch (true) {
        case MensagemErroLower.indexOf('nome') >= 0:
          return {
            Status: 'ERRO',
            Mensagem: `O nome ${MENSAGENS.REGISTRO_JA_CADASTRADO}`,
          };
        case MensagemErroLower.indexOf('cpf') >= 0:
          return {
            Status: 'ERRO',
            Mensagem: `O cpf ${MENSAGENS.REGISTRO_JA_CADASTRADO}`,
          };
        case MensagemErroLower.indexOf('email') >= 0:
          return {
            Status: 'ERRO',
            Mensagem: `O e-mail ${MENSAGENS.REGISTRO_JA_CADASTRADO}`,
          };
        case MensagemErroLower.indexOf('cnpj') >= 0:
          return {
            Status: 'ERRO',
            Mensagem: `O cnpj ${MENSAGENS.REGISTRO_JA_CADASTRADO}`,
          };
        case MensagemErroLower.indexOf('codigo_ibge') >= 0:
          return {
            Status: 'ERRO',
            Mensagem: `O código ibge ${MENSAGENS.REGISTRO_JA_CADASTRADO}`,
          };
      }
    }
    if (
      MensagemErroLower.indexOf('delete') >= 0 &&
      MensagemErroLower.indexOf('foreign key constraint') >= 0
    ) {
      return {
        Status: 'ERRO',
        Mensagem:
          'Não é possível fazer a exclusão. Este registro esta sendo utilizado.',
      };
    }

    if (MensagemErroLower.indexOf('column is too long') >= 0) {
      return {
        Status: 'ERRO',
        Mensagem:
          'Quantidade de caracteres utilizado é maior do que a permitida',
      };
    }
  }
  //  return { Status: 'ERRO', Mensagem: DEV ? MensagemErro ?? 'Erro ao realizar a operação!' : 'Erro ao realizar a operação!' };
  return {
    Status: 'ERRO',
    Mensagem: MensagemErro ?? 'Erro ao realizar a operação!',
  };
};

const ROTA_ARQUIVOS = 'files';

export const uploadArquivo = async (
  arquivoData: ArquivoData,
): Promise<ResponseAPI> => {
  const { arquivoSelecionado, type, nomeArquivoOriginal } = arquivoData;

  const formData = new FormData();
  formData.append('name', nomeArquivoOriginal);
  formData.append('file', arquivoSelecionado as Blob);
  formData.append('type', type ?? '');

  return await fetchArquivos(ROTA_ARQUIVOS, formData);
};

import React from 'react';
import { Card, CardContent, CardActions, Button, Chip } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

interface MainCardProps {
  navigate: (link: string) => void;
  onAccess: () => void;
}

const MainCard: React.FC<MainCardProps> = ({ navigate, onAccess }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0',
      }}
    >
      <CardContent sx={{ width: '100%', pb: 0 }}>
        {[
          { item: 'Usuários', link: ROUTES.MANAGE_USER },
          { item: 'Instituições', link: ROUTES.INSTITUTION },
          { item: 'CIFAs', link: ROUTES.MANAGE_SERVICE },
        ].map((chip) => (
          <Button
            key={chip.item}
            sx={{
              backgroundColor: '#EAEFF9',
              height: '22px',
              borderRadius: '4px 0px 0px 0px',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '14px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginRight: '10px',
              mt: 0,
              mb: 0,
            }}
            onClick={() => navigate(chip.link)}
          >
            {chip.item}
          </Button>
        ))}
      </CardContent>
      <CardActions sx={{ width: '100%', pb: 0 }}>
        <Button
          size='medium'
          sx={{
            width: '100%',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.40px',
            textAlign: 'left',
            color: '#0048B6',
          }}
          onClick={onAccess}
        >
          ACESSAR
        </Button>
      </CardActions>
    </Card>
  );
};

export default MainCard;

import axios from 'axios';
import { FileUploadResponse } from './api.types';
import { authService } from 'services/auth/auth_service';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/files';
const eseiafToken = authService.getTokenAPIInterna();

/**
 * @param
 * @param
 * @returns
 */
export const uploadFile = async (
  type: string,
  file: File,
): Promise<FileUploadResponse> => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('file', file);

  console.log('FormData antes do envio:', formData);

  const response = await axios.post<FileUploadResponse>(API_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

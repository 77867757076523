import React from 'react';
import { ContainerForm } from './container_form';
import { Box, Stack, Typography } from '@mui/material';
import { Colors } from 'theme';
import { useNavigate } from 'react-router-dom';

interface ItemFormProps {
  title: string;
  subtitle: string;
  status: 'Aguardando Preenchimento' | 'Preenchimento Pendente';
  number: number;
  pathTo?: string;
  state?: Record<string, any>;
}

export const ItemForm: React.FC<ItemFormProps> = ({
  title,
  subtitle,
  status,
  number,
  pathTo,
  state,
}) => {
  const isPending = status === 'Preenchimento Pendente';
  const navigate = useNavigate();

  const handleClick = () => {
    if (pathTo) {
      navigate(pathTo, { state });
    }
  };

  const backgroundColor = isPending ? '#00000014' : '#2B5DC60A';
  const circleColor = isPending ? '#00000061' : '#0048B6';

  return (
    <Stack
      sx={{
        backgroundColor: Colors.BRANCO_GOV,
        marginTop: '1rem',
        padding: '1rem',
        borderRadius: '0.5rem',
        cursor: pathTo ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor: pathTo ? '#f0f0f0' : Colors.BRANCO_GOV,
        },
      }}
      spacing={1}
      onClick={handleClick}
    >
      <Stack direction='row' alignItems='center' spacing={2}>
        <Box
          sx={{
            width: '2rem',
            height: '2rem',
            borderRadius: '50%',
            backgroundColor: circleColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            fontWeight: 'bold',
          }}
        >
          {number}
        </Box>

        <Stack>
          <Typography
            variant='h6'
            component='h6'
            fontSize='1.25rem'
            fontWeight='500'
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography variant='body2' color='textSecondary'>
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Box
        sx={{
          backgroundColor,
          padding: '0.5rem',
          borderRadius: '0.5rem',
          marginTop: '0.5rem',
        }}
      >
        <Typography variant='body2' color='textSecondary'>
          {status}
        </Typography>
      </Box>
    </Stack>
  );
};

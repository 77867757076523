import axios from 'axios';
import { authService } from 'services/auth/auth_service';
import { IUserTerms } from 'types/api_permission_responses';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/users/permissions';

export const getPermissionsDropdown = async (
  profileUuid?: string,
): Promise<any> => {
  const eseiafToken = authService.getTokenAPIInterna();

  const response = await axios.get(`${API_URL}`, {
    params: profileUuid ? { profileUuid } : {},
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

// export const getPermission = async (permissionUuid: string): Promise<any> => {
//   const response = await axios.get(`${API_URL}/uuid/${permissionUuid}`);

//   return response.data;
// };

export const getUserTerms = async (userUuid: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/manage-user-term/${userUuid}`);

  return response.data;
};

export const addUserPermissionTerm = async (
  userUuid: string,
  term: IUserTerms,
): Promise<any> => {
  const response = await axios.post<any>(
    `${API_URL}/manage-user-term/${userUuid}`,
    term,
  );
  return response.data;
};

export const editUserPermissionTerm = async (
  userUuid: string,
  term: IUserTerms,
): Promise<any> => {
  const response = await axios.patch<any>(
    `${API_URL}/manage-user-term/${userUuid}/${term.id}`,
    term,
  );
  return response.data;
};

import React from 'react';
import { Box } from '@mui/material';
import avatarUser from '../../assets/images/avatar-user.png';
import avatarProfile from '../../assets/images/avatar-user-profile.png';
import avatarTerms from '../../assets/images/avatar-user-terms-of-use.png';
import { ModuleCard } from 'components/shared';
import { ROUTES } from 'routes/constants';
import ContainerPainel from 'components/container_painel';

const MenuUser: React.FC = () => {
  return (
    <ContainerPainel pagina='Gestão de Usuários'>
      <Box
        sx={{
          backgroundColor: '#0048B6',
          height: '65px',
          alignContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#0048B6',
            height: '67px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '25px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '90%',
              maxWidth: '1152px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '290px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              gap: '15px',
            }}
          >
            <ModuleCard
              title='Usuários'
              description='Visualize e gerencie os usuários do sistema.'
              image={avatarUser}
              path={ROUTES.MANAGE_USER}
            />
            <ModuleCard
              title='Perfis de usuário'
              description='Visualize e gerencie os Perfis de Usuário disponíveis no sistema.'
              image={avatarProfile}
              path={null}
            />
            <ModuleCard
              title='Termos de Adesão Municipal'
              description='Gerencie os Termos de Adesão Municipal do sistema.'
              image={avatarTerms}
              path={ROUTES.TERMOFADHESION}
            />
          </Box>
        </Box>
      </Box>
    </ContainerPainel>
  );
};

export default MenuUser;

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { isResponseAPI, ResponseAPI, ResponseJSON, uploadArquivo } from 'api';
import { PermissionsI } from 'api/common.types';
import { getGroupsDropdown } from 'api/group/group.api';
import { getMunicipalitiesDropdown } from 'api/municipalities/municipalities.api';
import {
  // getPermission,
  getPermissionsDropdown,
} from 'api/permission/permission.api';
import { getProfilesDropdown } from 'api/profile/profile.api';
import { PartialUpdateUserData } from 'api/user/api.types';
import {
  createUser,
  getListUsersByFilters,
  getUserVigencias,
  partialUpdateUser,
} from 'api/user/user.api';
import ContainerPainel from 'components/container_painel';
import InputDados from 'components/input_dados';
import { DynamicDialog, ErrorDialog } from 'components/shared';
import { ContainerForm } from 'components/shared/container_form';
import { SnackbarFeedback } from 'components/shared/snackbar_feedback';
import AttachFileForm, { ArquivoData } from 'components/user/attach_file_form';
import LoginDataDisplay from 'components/user/login_data_display';
import LoginDataForm from 'components/user/login_data_form';
import PersonalDataDisplay from 'components/user/personal_data_display';
import PersonalDataForm from 'components/user/personal_data_form';
import RegistrationDataDisplay from 'components/user/registration_data_display';
import RegistrationDataForm from 'components/user/registration_dataForm';
import UserFormSteps from 'components/user/user_form_steps';
import { useAuth } from 'contexts/auth/auth_context';
import { Formik, FormikHelpers } from 'formik';
import { isArray } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { formatCPF } from 'services/utils/format_cpf';
import { validateCPF } from 'services/utils/validate_cpf';
import { MENSAGENS, parseDate, validarData, verificarDatas } from 'utils/uteis';
import * as Yup from 'yup';
import RegisteredIcon from '../../assets/icons/registered-icon.svg';
import UpdatedIcon from '../../assets/icons/updated-icon.svg';

export enum UserFormAction {
  CADASTRAR = 'CADASTRAR',
  EDITAR = 'EDITAR',
}

interface UserDetailsData {
  personalData: {
    uuid: string;
    name: string;
    cpf: string;
    email: string;
    phone: string;
    uuidPhone?: string;
    position: string;
    occupation: string;
    municipalityUuid: string;
    municipalities: string;
  };
  loginData: {
    groupUuid: string;
    profileUuid: string;
    permissionUuid: string;
    institutionUuid: string;
    password: string;
    confirmPassword: string;
  };
  registrationData: {
    activeRegistration: boolean;
    blockRegistration: boolean;
  };
  dadosVigencia?: {
    vigenciaIndeterminada: boolean;
    dataInicial: string;
    dataFinal: string;
    isActive: boolean;
  };
  anexo: {
    uuid: string;
    name: string;
  };
}

const UserForm: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathParts = location.pathname.split('/');
  const action = pathParts[2];
  const userUuid = pathParts[3];
  const { user } = useAuth();
  const PRIMEIRO_ACESSO = !user;

  const [userFormAction, setUserFormAction] = useState<UserFormAction | null>(
    null,
  );
  const [activeStep, setActiveStep] = useState(1);
  const [municipalitiesDropdown, setMunicipalitiesDropdown] = useState<
    { name: string }[]
  >([]);
  const [loadingMunicipalities, setLoadingMunicipalities] =
    useState<boolean>(false);
  const [userStatusDropdown, setUserStatusDropdown] = useState<
    { uuid: string; description: string }[]
  >([]);
  const [groupsDropdown, setGroupsDropdown] = useState<PermissionsI>({
    data: [],
  });
  const [profilesDropdown, setProfilesDropdown] = useState<PermissionsI>({
    data: [],
  });
  const [permissionsDropdown, setPermissionsDropdown] = useState<PermissionsI>({
    data: [],
  });
  const [institutionDropdown, setInstitutionDropdown] = useState<PermissionsI>({
    data: [],
  });
  const [userDetails, setUserDetails] = useState<UserDetailsData | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [duplicateError, setDuplicateError] = useState<string | null>(null);
  const [
    permissionMandatoryValidityValidation,
    setPermissionMandatoryValidityValidation,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [arquivoAnexo, setArquivoAnexo] = useState<ArquivoData>();

  const cpfRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const [permissaoSelelcionadaNome, setpermissaoSelelcionadaNome] =
    useState<string>();

  const [dataVigenciaInicial, setdataVigenciaInicial] = useState<string>('');
  const [dataVigenciaFinal, setdataVigenciaFinal] = useState<string>('');
  const [dataVigenciaIndeterminada, setdataVigenciaIndeterminada] =
    useState<boolean>(false);

  const [primeiroAcessoInstituicao, setPrimeiroAcessoInstituicao] =
    useState<Record<string, any>>();
  const [primeiroAcessoProfile, setPrimeiroAcessoProfile] =
    useState<string>('');

  useEffect(() => {
    if (PRIMEIRO_ACESSO) {
      handlePrimeiroAcesso();
    }
    const fetchMunicipalities = async () => {
      setLoadingMunicipalities(true);

      try {
        const data = await getMunicipalitiesDropdown();
        setMunicipalitiesDropdown(data?.data);
      } catch (error) {
        console.error('Error ao buscar municipios:', error);
      } finally {
        setLoadingMunicipalities(false);
      }
    };

    fetchMunicipalities();
  }, []);

  const handlePrimeiroAcesso = () => {
    const DadosPrimeiroAcessoJSON = localStorage.getItem('firstRegisterData');
    const DadosPrimeiroAcesso = JSON.parse(DadosPrimeiroAcessoJSON || '{}');

    setPrimeiroAcessoInstituicao(DadosPrimeiroAcesso.institution);
    setPrimeiroAcessoProfile(DadosPrimeiroAcesso.selectedProfile);

    // console.log('DadosPrimeiroAcesso: ', DadosPrimeiroAcesso);
  };

  const handleGroupChange = async (groupUuid: string) => {
    if (!groupUuid) {
      setProfilesDropdown({ data: [] });
      return;
    }

    try {
      const profiles = await getProfilesDropdown(groupUuid);
      setProfilesDropdown(profiles);
      setPermissionsDropdown({ data: [] });
    } catch (error) {
      console.error('Erro ao buscar perfis:', error);
    }
  };

  const handleProfileChange = async (profileUuid: string) => {
    if (!profileUuid) {
      setPermissionsDropdown({ data: [] });
      return;
    }

    try {
      const permissions = await getPermissionsDropdown(profileUuid);
      const permissoesAjustadas = tratarPermissoesUsuario(
        permissions,
        profileUuid,
      );
      setPermissionsDropdown(permissoesAjustadas);
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
    }
  };

  const tratarPermissoesUsuario = (
    permissoes: PermissionsI,
    perfilID: any,
    profilesDropdownParm = profilesDropdown,
  ): PermissionsI => {
    const permissoesAjustadas: PermissionsI = permissoes;
    const PerfilNome = profilesDropdownParm?.data
      ?.find((item) => item.uuid === perfilID)
      ?.name?.toUpperCase();

    if (PerfilNome && permissoes?.data?.length > 0) {
      if (
        PerfilNome.indexOf('MUNICIPAL') > -1 ||
        PerfilNome.indexOf('MUNICIPIO') > -1
      ) {
        //retirar todos os registros do permissoes que possual ESTADUAL NO NOME

        permissoesAjustadas.data = permissoes.data.filter(
          (item: { name: string }) => {
            return item.name.toUpperCase().indexOf('ESTADUAL') === -1;
          },
        );
      } else {
        if (
          PerfilNome.indexOf('ESTADUAL') > -1 ||
          PerfilNome.indexOf('ESTADO') > -1
        ) {
          //retirar todos os registros do permissoes que possual MUNICIAPAL NO NOME

          permissoesAjustadas.data = permissoes.data.filter(
            (item: { name: string }) => {
              return item.name.toUpperCase().indexOf('MUNICIPAL') === -1;
            },
          );
        }
      }
    }

    return permissoesAjustadas;
  };

  const handlePermissionChange = async (permissionUuid: string) => {
    if (permissionUuid) {
      try {
        // const permissionData = await getPermission(permissionUuid);
        // setPermissionMandatoryValidityValidation(
        //   permissionData.mandatoryValidityValidation,
        // );
      } catch (error) {
        console.error('Erro ao buscar permissões:', error);
      }
    }
  };

  useEffect(() => {
    const fetchDropdowns = async () => {
      const profiles = await getProfilesDropdown();
      setProfilesDropdown(profiles);

      const permissions = await getPermissionsDropdown();
      setPermissionsDropdown(permissions);

      const groups = await getGroupsDropdown();
      setGroupsDropdown(groups);

      return { profiles, permissions, groups };
    };

    const fetchUserDetails = async (uuid: string) => {
      const dropDowns = await fetchDropdowns();
      const { profiles, permissions, groups } = dropDowns;

      try {
        const Resp = await getListUsersByFilters({
          limit: '10',
          uuid: uuid,
        });

        if (isResponseAPI(Resp)) {
          const RespAux: ResponseAPI = Resp;
          alert(RespAux.Mensagem);

          setInvalidRequest(true);
        } else {
          const userDetailsData: ResponseJSON = Resp;
          const UserAux = userDetailsData?.data[0] ?? {};

          const profileUuidAux = UserAux.permission?.profile?.uuid;
          const permissaoID = UserAux.permission?.uuid;

          if (profileUuidAux) {
            const permissoesAjustadas = tratarPermissoesUsuario(
              permissions,
              profileUuidAux,
              profiles,
            );
            setPermissionsDropdown(permissoesAjustadas);
          }

          let permissaoNome;
          if (permissions && permissaoID) {
            permissaoNome = permissions.data.find(
              (item: { uuid: any }) => item.uuid === permissaoID,
            )?.name;

            setpermissaoSelelcionadaNome(permissaoNome);
          }

          let dadosVigencia = null;

          if (getTipoRepresentanteMunicipal(permissaoNome)) {
            dadosVigencia = await getUserVigencias(uuid);
            dadosVigencia = dadosVigencia?.data;
            if (dadosVigencia && isArray(dadosVigencia)) {
              dadosVigencia = dadosVigencia[0];
            }
          }

          setUserDetails({
            personalData: {
              uuid,
              name: UserAux.name,
              cpf: formatCPF(UserAux.identification),
              email: UserAux.email,
              phone: UserAux.contactList[0]?.value,
              position: UserAux.position,
              occupation: UserAux.occupation,
              municipalityUuid: UserAux.address?.municipality,
              municipalities: UserAux.address?.municipality,
            },
            loginData: {
              groupUuid: UserAux.permission?.profile?.group?.uuid,
              profileUuid: UserAux.permission?.profile?.uuid,
              permissionUuid: UserAux.permission?.uuid,
              institutionUuid: UserAux.institutionUuid,
              password: '',
              confirmPassword: '',
            },
            registrationData: {
              activeRegistration: UserAux.isRegisterActive,
              blockRegistration: UserAux.isRegisterBlocked,
            },
            anexo: {
              uuid: UserAux.uuid,
              name: UserAux.name,
            },
          });

          if (dadosVigencia) {
            console.log('dadosVigencia', dadosVigencia);
            // aqui é o mesmo caso do visualizar que chega AAAA-MM-DD mas é esperado DD/MM/AAAA
            const convertInitialDate = dadosVigencia.dataInicial.split('-');
            const convertFinalDate = dadosVigencia.dataFinal.split('-');
            const initialDate = `${convertInitialDate[2]}/${convertInitialDate[1]}/${convertInitialDate[0]}`;
            const finalDate = `${convertFinalDate[2]}/${convertFinalDate[1]}/${convertFinalDate[0]}`;
            setdataVigenciaInicial(initialDate);
            setdataVigenciaFinal(finalDate);
            setdataVigenciaIndeterminada(
              !dadosVigencia.dataFinal
                ? true
                : dadosVigencia.vigenciaIndeterminada,
            );
          }
        }
      } catch (error) {
        alert(error);
        setInvalidRequest(true);
      }
    };

    if (action === 'cadastrar') {
      fetchDropdowns();
      setUserFormAction(UserFormAction.CADASTRAR);
    } else if (action === 'editar' && userUuid) {
      setUserFormAction(UserFormAction.EDITAR);
      fetchUserDetails(userUuid);
    }
  }, [action, location.pathname, userFormAction, userUuid]);

  const initialValues = {
    personalData: {
      name: userDetails?.personalData?.name || '',
      cpf: userDetails?.personalData?.cpf || '',
      email: userDetails?.personalData?.email || '',
      phone: userDetails?.personalData?.phone || '',
      position: userDetails?.personalData?.position || '',
      occupation: userDetails?.personalData?.occupation || '',
      municipality:
        primeiroAcessoInstituicao?.address?.municipality ||
        userDetails?.personalData?.municipalityUuid ||
        '',
      municipalities:
        primeiroAcessoInstituicao?.address?.municipality ||
        userDetails?.personalData?.municipalities ||
        '',
    },
    loginData: {
      group: userDetails?.loginData?.groupUuid || '',
      profile: userDetails?.loginData?.profileUuid || '',
      permission: userDetails?.loginData?.permissionUuid || '',
      password: '',
      confirmPassword: '',
    },
    registrationData: {
      activeRegistration:
        userDetails?.registrationData?.activeRegistration === true,
      blockRegistration:
        userDetails?.registrationData?.blockRegistration === true,
    },
    anexo: {
      uuid: userDetails?.anexo.uuid,
      name: userDetails?.anexo.name,
    },
  };

  const validationSchema = Yup.object().shape({
    personalData: Yup.object().shape({
      name: Yup.string().required(
        'Preencha esse campo corretamente, com um dado válido.',
      ),
      cpf: Yup.string()
        .required('Preencha esse campo corretamente, com um dado válido.')
        .test('cpf-valid', 'CPF inválido', (value) => validateCPF(value || '')),
      email: Yup.string()
        .email('Preencha esse campo corretamente, com um dado válido.')
        .required('Preencha esse campo corretamente, com um dado válido.'),
      phone: Yup.string().required(
        'Preencha esse campo corretamente, com um dado válido.',
      ),
      position: Yup.string().required(
        'Preencha esse campo corretamente, com um dado válido.',
      ),
      occupation: Yup.string().required(
        'Preencha esse campo corretamente, com um dado válido.',
      ),
    }),
    loginData: Yup.object().shape({
      group: Yup.string().required(
        'Preencha esse campo corretamente, com um dado válido.',
      ),
      profile: Yup.string().required(
        'Preencha esse campo corretamente, com um dado válido.',
      ),
      permission: Yup.string().required(
        'Preencha esse campo corretamente, com um dado válido.',
      ),
      password: Yup.string().when([], () => {
        if (action === 'editar') {
          return Yup.string()
            .notRequired()
            .test(
              'validate-if-filled',
              'Preencha esse campo corretamente, com um dado válido.',
              (value) => {
                if (value) {
                  return value.trim().length > 0;
                }
                return true;
              },
            );
        }
        return Yup.string().required(
          'Preencha esse campo corretamente, com um dado válido.',
        );
      }),
      confirmPassword: Yup.string().when([], (_, context) => {
        if (action === 'editar') {
          return Yup.string()
            .notRequired()
            .test(
              'validate-if-filled',
              'As senhas devem coincidir.',
              (value) => {
                const passwordValue = context.parent.password;
                if (value) {
                  return value === passwordValue;
                }
                return true;
              },
            );
        }
        return Yup.string()
          .required('Preencha esse campo corretamente, com um dado válido.')
          .oneOf([Yup.ref('password')], 'As senhas devem coincidir');
      }),
    }),
    registrationData: Yup.object().shape({
      activeRegistration: Yup.boolean().required(
        'Selecione se o cadastro está ativo.',
      ),
      blockRegistration: Yup.boolean().required(
        'Selecione se deseja bloquear o cadastro.',
      ),
    }),
  });

  const handleEditClick = () => {
    setActiveStep(1);
  };

  const clearDuplicateError = () => {
    setDuplicateError(null);
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>,
  ) => {
    if (!validarDados()) return;

    if (activeStep === 1) {
      setActiveStep(2);
      return;
    }

    if (activeStep === 2) {
      try {
        const arquivoAnexoSubmetido = await processarArquivoAnexo();
        console.log('>>> anexo <<<', arquivoAnexoSubmetido);
        const userData = construirUserData(values, arquivoAnexoSubmetido);

        if (userFormAction === UserFormAction.CADASTRAR) {
          await cadastrarUsuario(userData);
        } else if (userFormAction === UserFormAction.EDITAR) {
          await editarUsuario(values, userData);
        } else {
          alert('Erro ao tentar criar ou alterar o usuário.');
        }
      } catch (error) {
        console.error('Erro ao tentar criar ou alterar o usuário.', error);
        setErrorMessage('Erro ao tentar criar ou alterar o usuário.');
      } finally {
        actions.setSubmitting(false);
      }
    }
  };

  const processarArquivoAnexo = async (): Promise<ResponseJSON | null> => {
    if (!arquivoAnexo) return null;
    const Resp = await uploadArquivo({
      ...arquivoAnexo,
      type: 'COMPROVANTE DE REPRESENTANTE LEGAL MUNICIPAL',
    }); //RG temporario

    if (Resp.Status === 'OK') {
      return Resp.dados as ResponseJSON;
    } else {
      alert(Resp.Mensagem);
      return null;
    }
  };

  const construirUserData = (values: any, arquivoUuid: any) => {
    let userData = {
      name: values.personalData.name,
      identification: values.personalData.cpf,
      email: values.personalData.email,
      contactList: [
        {
          type: 'CELULAR',
          value: '+55' + values.personalData.phone.replace(/\D/g, ''),
        },
      ],
      position: values.personalData.position,
      occupation: values.personalData.occupation,
      groupUuid: values.loginData.group,
      profileUuid: values.loginData.profile,
      permissionUuid: values.loginData.permission,
      representativeFileUuid: arquivoUuid?.uuid,
      password: values.loginData.password,
      isRegisterActive: values.registrationData.activeRegistration,
      isRegisterBlocked: values.registrationData.blockRegistration,
      hasAcceptedConfidentialityTerm: false,
      hasAcceptedTermOfUse: false,
      address: {
        municipality: values.personalData.municipalities,
      },
      institutionUuid: null,
      dadosVigencia: getTipoRepresentanteMunicipal()
        ? {
            vigenciaIndeterminada: dataVigenciaIndeterminada,
            dataInicial: parseDate(dataVigenciaInicial),
            dataFinal: dataVigenciaFinal ? parseDate(dataVigenciaFinal) : null,
            isActive: true,
          }
        : null,
    };

    if (PRIMEIRO_ACESSO) {
      userData = {
        ...userData,
        isRegisterActive: false,
        isRegisterBlocked: false,
        hasAcceptedTermOfUse: true,
        hasAcceptedConfidentialityTerm: true,
        institutionUuid: primeiroAcessoInstituicao?.uuid,
      };
    }

    return userData;
  };

  const cadastrarUsuario = async (userData: any) => {
    const response = await createUser(userData);

    if (response.Status === 'OK') {
      setIsDialogOpen(true);
      return;
    }

    const newResponse = await createUser({
      ...userData,
      institutionUuid: null,
    });
    if (newResponse.Status === 'OK') {
      setIsDialogOpen(true);
    } else {
      alert(newResponse.Mensagem);
    }
  };

  const editarUsuario = async (values: any, userData: any) => {
    if (!userDetails?.personalData.uuid) return;

    const partialUpdateUserData = gerarDadosAtualizados(values);
    const alterouVigencia = verificarAlteracaoDeVigencia();

    console.log('>>> partialUpdateUserData <<<', partialUpdateUserData);

    if (alterouVigencia) {
      const confirmation = confirm(
        'Você está alterando a sua vigência. Caso continue será deslogado, e será exigido aprovação por Responsável. Deseja continuar?',
      );
      if (!confirmation) return;
    }

    const response = await partialUpdateUser(
      userDetails?.personalData.uuid,
      partialUpdateUserData,
    );

    if (response?.Status === 'OK') {
      setIsDialogOpen(true);
      if (alterouVigencia) {
        // navigate(ROUTES.LOGOUT);
      } else {
        alert(response.Mensagem);
      }
    }
  };

  const hasChanged = (original: any, updated: any) => {
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  const gerarDadosAtualizados = (values: any) => {
    // Criando um objeto vazio para permitir adição dinâmica de propriedades
    const updatedData: Partial<PartialUpdateUserData> = {};

    // Adiciona dados de vigência apenas se for um representante municipal
    if (getTipoRepresentanteMunicipal()) {
      updatedData.dadosVigencia = {
        vigenciaIndeterminada: dataVigenciaIndeterminada,
        dataInicial: parseDate(dataVigenciaInicial),
        dataFinal: dataVigenciaFinal ? parseDate(dataVigenciaFinal) : null,
        isActive: true,
      };
    }

    // Adiciona email se foi alterado
    if (
      hasChanged(userDetails?.personalData.email, values.personalData.email)
    ) {
      updatedData.email = values.personalData.email;
    }

    // Adiciona lista de contatos se foi alterado
    if (
      hasChanged(userDetails?.personalData.phone, values.personalData.phone)
    ) {
      updatedData.contactList = [
        {
          uuid: userDetails?.personalData.uuidPhone,
          type: 'CELULAR',
          value: values.personalData.phone,
        },
      ];
    }

    // Adiciona posição se foi alterada
    if (
      hasChanged(
        userDetails?.personalData.position,
        values.personalData.position,
      )
    ) {
      updatedData.position = values.personalData.position;
    }

    // Adiciona ocupação se foi alterada
    if (
      hasChanged(
        userDetails?.personalData.occupation,
        values.personalData.occupation,
      )
    ) {
      updatedData.occupation = values.personalData.occupation;
    }

    // Adiciona grupo se foi alterado
    if (hasChanged(userDetails?.loginData.groupUuid, values.loginData.group)) {
      updatedData.groupUuid = values.loginData.group;
    }

    // Adiciona perfil se foi alterado
    if (
      hasChanged(userDetails?.loginData.profileUuid, values.loginData.profile)
    ) {
      updatedData.profileUuid = values.loginData.profile;
    }

    // Adiciona permissão se foi alterada
    if (
      hasChanged(
        userDetails?.loginData.permissionUuid,
        values.loginData.permission,
      )
    ) {
      updatedData.permissionUuid = values.loginData.permission;
    }

    // Adiciona senha se foi alterada
    if (
      hasChanged(userDetails?.loginData.password, values.loginData.password)
    ) {
      updatedData.password = values.loginData.password;
    }

    // Adiciona status de ativação e bloqueio
    updatedData.isRegisterActive = values.registrationData.activeRegistration;
    updatedData.isRegisterBlocked = values.registrationData.blockRegistration;
    updatedData.representativeFileUuid = values.anexo.uuid;

    return updatedData;
  };

  const verificarAlteracaoDeVigencia = () => {
    if (
      userDetails?.personalData.uuid === user?.uuid &&
      getTipoRepresentanteMunicipal()
    ) {
      return (
        (!userDetails?.dadosVigencia?.dataInicial && dataVigenciaInicial) ||
        (!userDetails?.dadosVigencia?.dataFinal && dataVigenciaFinal) ||
        hasChanged(
          userDetails?.dadosVigencia?.vigenciaIndeterminada,
          dataVigenciaIndeterminada,
        ) ||
        hasChanged(
          userDetails?.dadosVigencia?.dataInicial,
          dataVigenciaInicial,
        ) ||
        hasChanged(userDetails?.dadosVigencia?.dataFinal, dataVigenciaFinal)
      );
    }
    return false;
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setActiveStep(1);
    } else {
      navigate(ROUTES.MANAGE_USER);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
    setInvalidRequest(false);
  };

  const getNameByUuid = (
    uuid: string,
    list: { data: { uuid: string; name: string }[] },
  ) => {
    const item = list.data.find((entry) => entry.uuid === uuid);
    return item ? item.name : 'N/A';
  };

  const validarDados = (): boolean => {
    if (getTipoRepresentanteMunicipal()) {
      if (!dataVigenciaInicial || dataVigenciaInicial.trim() == '') {
        alert(`Data de Inicio ${MENSAGENS.PREENCHIMENTO_OBRIGATORIO}`);
        return false;
      }

      if (!validarData(dataVigenciaInicial)) {
        alert(`Data de Início ${MENSAGENS.PREENCHIMENTO_INVALIDO}`);
        return false;
      }

      if (!dataVigenciaIndeterminada) {
        if (!dataVigenciaFinal || dataVigenciaFinal.trim() == '') {
          alert(`Data Fim ${MENSAGENS.PREENCHIMENTO_OBRIGATORIO}`);
          return false;
        }

        if (!validarData(dataVigenciaFinal)) {
          alert(`Data Fim ${MENSAGENS.PREENCHIMENTO_INVALIDO}`);
          return false;
        }

        if (!verificarDatas(dataVigenciaInicial, dataVigenciaFinal)) {
          alert(
            'A Data Final de Vigência deve ser posterior a Data Inicial. Verifique e selecione uma Data válida para continuar',
          );
          return false;
        }
      }

      if (
        userFormAction === UserFormAction.CADASTRAR &&
        !(arquivoAnexo && arquivoAnexo.nomeArquivoOriginal?.length > 0)
      ) {
        alert('Para Representante Municipal o Arquivo Anexo é obrigatório');
        return false;
      }
    }

    return true;
  };

  const getTipoRepresentanteMunicipal = (
    permissaoParm = permissaoSelelcionadaNome,
  ): boolean => {
    return (
      permissaoParm?.trim().toUpperCase() === 'REPRESENTANTE LEGAL MUNICIPAL' ||
      false
    );
  };

  return (
    <ContainerPainel
      pagina={
        user
          ? userFormAction === UserFormAction.CADASTRAR
            ? 'Cadastrar Usuário'
            : 'Editar Usuário'
          : 'Cadastro - Primeiro Acesso'
      }
      showBackButton={true}
    >
      <Box
        sx={{
          backgroundColor: '#FAFAFA',
        }}
      >
        <UserFormSteps activeStep={activeStep} />
        <Box
          sx={{
            height: '79px',
            gap: '24px',
          }}
        >
          <Typography sx={{ textAlign: 'center' }} variant='h5'>
            {activeStep === 1 ? 'Dados principais' : 'Confirmação dos dados'}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '22.88px',
              letterSpacing: '0.17px',
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            {activeStep === 1
              ? 'Informe corretamente os dados principais do usuário cadastrado.'
              : 'Confira todas as informações que foram preenchidas, e altere-as caso necessário.'}
          </Typography>
        </Box>

        {PRIMEIRO_ACESSO && (
          <>
            {primeiroAcessoProfile !== 'agricultor' && (
              <Box display={'flex'} justifyContent={'center'}>
                <ContainerForm title='Dados da Instituição' width='1152px'>
                  <Stack spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant='subtitle2' color='textSecondary'>
                          Nome Fantasia
                        </Typography>
                        <Typography variant='body1'>
                          {primeiroAcessoInstituicao?.fantasyName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant='subtitle2' color='textSecondary'>
                          CNPJ
                        </Typography>
                        <Typography variant='body1'>
                          {primeiroAcessoInstituicao?.identification}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant='subtitle2' color='textSecondary'>
                          Razão Social
                        </Typography>
                        <Typography variant='body1'>
                          {primeiroAcessoInstituicao?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant='subtitle2' color='textSecondary'>
                          Município
                        </Typography>
                        <Typography variant='body1'>
                          {primeiroAcessoInstituicao?.address?.municipality}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </ContainerForm>
              </Box>
            )}
          </>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          context={{ action }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            dirty,
          }) => {
            if (typeof values.registrationData.activeRegistration === 'string')
              values.registrationData.activeRegistration = JSON.parse(
                values.registrationData.activeRegistration,
              );
            if (typeof values.registrationData.blockRegistration === 'string')
              values.registrationData.blockRegistration = JSON.parse(
                values.registrationData.blockRegistration,
              );

            return (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {activeStep === 1 ? (
                    <>
                      <PersonalDataForm
                        municipalitiesOptions={municipalitiesDropdown}
                        loadingMunicipalities={loadingMunicipalities}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        duplicateError={duplicateError}
                        fieldsRef={{ cpfRef, emailRef }}
                        clearDuplicateError={clearDuplicateError}
                        userFormAction={
                          userFormAction
                            ? userFormAction
                            : UserFormAction.CADASTRAR
                        }
                        setFieldValue={setFieldValue}
                        primeiroAcessoInstituicao={primeiroAcessoInstituicao}
                        user={user}
                      />
                      <LoginDataForm
                        groupOptions={groupsDropdown}
                        profileOptions={profilesDropdown}
                        permissionOptions={permissionsDropdown}
                        institutionOptions={institutionDropdown}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        handleChange={(e) => {
                          const { name, value } = e.target;

                          if (name === 'loginData.group') {
                            setFieldValue('loginData.group', value);
                            setFieldValue('loginData.profile', '');
                            handleGroupChange(value);
                          }

                          if (name === 'loginData.profile') {
                            setFieldValue('loginData.profile', value);
                            setFieldValue('loginData.permission', '');
                            handleProfileChange(value);
                          }

                          if (name === 'loginData.permission') {
                            setFieldValue('loginData.permission', value);
                            handlePermissionChange(value);
                            const PermissaoNome =
                              permissionsDropdown?.data?.find(
                                (item) => item.uuid === value,
                              )?.name;

                            setpermissaoSelelcionadaNome(PermissaoNome);
                          }

                          handleChange(e);
                        }}
                        handleBlur={handleBlur}
                        userFormAction={
                          userFormAction
                            ? userFormAction
                            : UserFormAction.CADASTRAR
                        }
                      />
                      {!PRIMEIRO_ACESSO && (
                        <RegistrationDataForm
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          userFormAction={
                            userFormAction
                              ? userFormAction
                              : UserFormAction.CADASTRAR
                          }
                          permissionMandatoryValidityValidation={
                            permissionMandatoryValidityValidation
                          }
                        />
                      )}

                      {permissaoSelelcionadaNome &&
                        permissaoSelelcionadaNome.trim().toUpperCase() ===
                          'REPRESENTANTE LEGAL MUNICIPAL' && (
                          <ContainerForm
                            title='Dados da Vigência'
                            width='1150px'
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <InputDados
                                  placeholder='Data Inicial de Vigência'
                                  label='Data Inicial de Vigência'
                                  mask='99/99/9999'
                                  value={dataVigenciaInicial}
                                  onChange={(e: any) => {
                                    setdataVigenciaInicial(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <InputDados
                                  placeholder='Data Final de Vigência'
                                  label='Data Final de Vigência'
                                  mask='99/99/9999'
                                  value={dataVigenciaFinal}
                                  onChange={(e: any) => {
                                    setdataVigenciaFinal(e.target.value);
                                  }}
                                  disabled={dataVigenciaIndeterminada}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={dataVigenciaIndeterminada}
                                      onChange={(e) => {
                                        setdataVigenciaIndeterminada(
                                          e.target.checked,
                                        );
                                      }}
                                    />
                                  }
                                  label='Data final indeterminada'
                                />
                              </Grid>
                            </Grid>
                          </ContainerForm>
                        )}

                      {permissaoSelelcionadaNome &&
                        permissaoSelelcionadaNome.trim().toUpperCase() ===
                          'REPRESENTANTE LEGAL MUNICIPAL' && (
                          <AttachFileForm
                            arquivoData={arquivoAnexo}
                            setArquivoData={setArquivoAnexo}
                          />
                        )}
                    </>
                  ) : (
                    <>
                      <PersonalDataDisplay
                        values={{
                          ...values.personalData,
                        }}
                        onEditClick={handleEditClick}
                        isView={false}
                      />
                      <LoginDataDisplay
                        values={{
                          group: getNameByUuid(
                            values.loginData?.group,
                            groupsDropdown,
                          ),
                          profile: getNameByUuid(
                            values.loginData.profile,
                            profilesDropdown,
                          ),
                          permission: getNameByUuid(
                            values.loginData.permission,
                            permissionsDropdown,
                          ),
                        }}
                        onEditClick={handleEditClick}
                        isView={false}
                      />
                      <RegistrationDataDisplay
                        values={{
                          activeRegistration: values.registrationData
                            .activeRegistration
                            ? 'SIM'
                            : 'NÃO',
                          blockRegistration: values.registrationData
                            .blockRegistration
                            ? 'SIM'
                            : 'NÃO',
                          termData: null,
                        }}
                        onEditClick={handleEditClick}
                        isView={false}
                      />

                      {permissaoSelelcionadaNome &&
                        permissaoSelelcionadaNome.trim().toUpperCase() ===
                          'REPRESENTANTE LEGAL MUNICIPAL' && (
                          <ContainerForm
                            title='Dados da Vigência'
                            width='1150px'
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <InputDados
                                  placeholder='Data Inicial de Vigência'
                                  label='Data Inicial de Vigência'
                                  mask='99/99/9999'
                                  value={dataVigenciaInicial}
                                  onChange={(e: any) => {
                                    setdataVigenciaInicial(e.target.value);
                                  }}
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <InputDados
                                  placeholder='Data Final de Vigência'
                                  label='Data Final de Vigência'
                                  mask='99/99/9999'
                                  value={dataVigenciaFinal}
                                  onChange={(e: any) => {
                                    setdataVigenciaFinal(e.target.value);
                                  }}
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={dataVigenciaIndeterminada}
                                      onChange={(e) => {
                                        setdataVigenciaIndeterminada(
                                          e.target.checked,
                                        );
                                      }}
                                    />
                                  }
                                  label='Data final indeterminada'
                                  disabled
                                />
                              </Grid>
                            </Grid>
                          </ContainerForm>
                        )}
                    </>
                  )}
                </Box>

                <Box sx={{ justifySelf: 'center', padding: '32px' }}>
                  <Button
                    variant='text'
                    onClick={handleBack}
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.4px',
                      textAlign: 'left',
                      color: '#0048B6',
                      marginRight: '20px',
                    }}
                  >
                    VOLTAR
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    // disabled={!dirty}
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.4px',
                      textAlign: 'left',
                      background: '#0048B6',
                    }}
                  >
                    {activeStep === 1 ? 'AVANÇAR' : 'CONFIRMAR E SALVAR'}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>

      <Box sx={{ width: '1200px' }}>
        <DynamicDialog
          isOpen={isDialogOpen}
          icon={
            userFormAction === UserFormAction.CADASTRAR
              ? RegisteredIcon
              : UpdatedIcon
          }
          title={
            userFormAction === UserFormAction.CADASTRAR
              ? 'Usuário Cadastrado'
              : 'Usuário Alterado'
          }
          message={
            userFormAction === UserFormAction.CADASTRAR
              ? PRIMEIRO_ACESSO
                ? 'CADASTRO FEITO COM SUCESSO, AGUARDANDO A APROVAÇÃO DO GERENTE ESTADUAL'
                : 'O Usuário foi cadastrado com sucesso.'
              : 'O Usuário foi alterado com sucesso.'
          }
          buttonText='Concluir'
          onClose={() => setIsDialogOpen(false)}
          routeOnClose={ROUTES.MANAGE_USER}
        />
      </Box>

      {errorMessage && (
        <SnackbarFeedback
          isOpen={!!errorMessage}
          message={errorMessage}
          type='error'
        />
      )}

      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={() => setInvalidRequest(false)}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </ContainerPainel>
  );
};

export default UserForm;

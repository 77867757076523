export const formatCpfHiddenNumberMask = (cpf: string): string => {
  const cleanedCpf = cpf?.replace(/\D/g, '');

  if (cleanedCpf.length !== 11) {
    throw new Error('O CPF deve ter 11 dígitos');
  }

  const maskedCpf = cleanedCpf?.replace(/^(\d{3})(\d{3})/, '***.***');

  return maskedCpf?.replace(/(\d{3})(\d{2})$/, '$1-$2');
};

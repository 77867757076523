import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Header } from 'components/home';
import { ContainerForm } from 'components/shared/container_form';
import { PageTitle } from 'components/shared/page_title';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import React from 'react';
import useFirstRegisterUser from './use_first_register_user';
import ContainerPainel from 'components/container_painel';

const FirstRegisterUser: React.FC = () => {
  const {
    handleProfileChange,
    handleDocumentTypeChange,
    handleDocumentChange,
    getDocumentLabel,
    handleRegisterUser,
    error,
    selectedProfile,
    document,
    documentType,
  } = useFirstRegisterUser();

  return (
    <ContainerPainel pagina='Cadastro - Primeiro Acesso'>
      <PageTitle
        title='Cadastro - Primeiro Acesso'
        subtitle='Informe corretamente os dados para realização do Cadastro no sistema.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        <ContainerForm
          title='Perfil de Acesso'
          subtitle='É permitido selecionar apenas uma opção.'
        >
          <RadioGroup value={selectedProfile} onChange={handleProfileChange}>
            <FormControlLabel
              value='prefeitura'
              control={<Radio />}
              label='Prefeitura Municipal'
            />
            <FormControlLabel
              value='agroindustria'
              control={<Radio />}
              label='Agroindústria'
              disabled
            />
            <FormControlLabel
              value='associacao'
              control={<Radio />}
              label='Associação'
              disabled
            />
            <FormControlLabel
              value='cooperativa'
              control={<Radio />}
              label='Cooperativa'
              disabled
            />
            <FormControlLabel
              value='agricultor'
              control={<Radio />}
              label='Agricultor(a) Familiar'
              disabled
            />
          </RadioGroup>
        </ContainerForm>

        {selectedProfile && (
          <ContainerForm title={getDocumentLabel()}>
            {selectedProfile === 'agroindustria' && (
              <RadioGroup
                row
                value={documentType}
                onChange={handleDocumentTypeChange}
              >
                <FormControlLabel
                  value='cnpj'
                  control={<Radio />}
                  label='CNPJ'
                />
                <FormControlLabel value='cpf' control={<Radio />} label='CPF' />
              </RadioGroup>
            )}

            <TextField
              fullWidth
              label={documentType === 'cpf' ? 'CPF' : 'CNPJ'}
              variant='outlined'
              placeholder={
                documentType === 'cpf' ? '000.000.000-00' : '00.000.000/0000-00'
              }
              value={document}
              onChange={handleDocumentChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: documentType === 'cpf' ? 14 : 18 }}
            />
          </ContainerForm>
        )}

        {error && (
          <Typography color='error' textAlign='center'>
            {error}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            marginTop: '20px',
          }}
        >
          <Button variant='text' color='primary'>
            SAIR
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!selectedProfile || !document}
            onClick={handleRegisterUser}
          >
            AVANÇAR
          </Button>
        </Box>
      </Stack>
    </ContainerPainel>
  );
};

export default FirstRegisterUser;

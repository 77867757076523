import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { ContainerForm } from 'components/shared/container_form';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Stack } from '@mui/system';
import { PageTitle } from 'components/shared/page_title';
import { Colors } from 'theme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OrdenarModal from 'components/shared/ordenar_modal';
import FilterModal from 'components/shared/filter_modal';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
};

interface Step1Props {
  onNext: (answers: AnswersType) => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const [isOrdenarModalOpen, setIsOrdenarModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [question1, setQuestion1] = useState<string | null>(null);
  const [beneficiaries, setBeneficiaries] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleOpenOrdenarModal = () => setIsOrdenarModalOpen(true);
  const handleCloseOrdenarModal = () => setIsOrdenarModalOpen(false);

  const handleOpenFilterModal = () => setIsFilterModalOpen(true);
  const handleCloseFilterModal = () => setIsFilterModalOpen(false);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setUploadedFile(e.target.files[0]);
    }
  };

  const handleNext = () => {
    onNext({
      question1: beneficiaries,
      question2: uploadedFile?.name || null,
      question3: null,
      question4: null,
    });
  };

  const beneficiariesList = [
    { name: 'Beatriz Rodrigues da Silva', cpf: '555.091.067-75' },
    { name: 'Bruno Souza Lima', cpf: '555.091.067-75' },
    { name: 'Carlos Marinho Duarte', cpf: '555.091.067-75' },
  ];

  return (
    <>
      <PageTitle
        title='Assistência Técnica e Extensão Rural - Município'
        subtitle='Informe corretamente os dados sobre os atendimentos ATER realizados no Município.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        <Alert
          severity='info'
          sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}
        >
          <Typography
            variant='body2'
            fontSize='14px'
            textAlign='left'
            lineHeight={1.5}
          >
            Considera-se Assistência Técnica e Extensão Rural - ATER o número de
            atendimentos prestados aos beneficiários da agricultura familiar
            (unidade agro familiar) do município por profissional de ATER, por
            meio de, no mínimo, 1 (uma) visita presencial ao longo do ano.
          </Typography>
        </Alert>
        <ContainerForm title='ATER - Atendidos exclusivamente pelo Município'>
          <TextField
            id='beneficiarios-exclusivamente-municipio'
            label='Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada exclusivamente pelo Município? (Opcional)'
            variant='outlined'
            value={beneficiaries}
            onChange={(e) => setBeneficiaries(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
          />

          <Typography
            sx={{
              color: Colors.CINZA_MEDIO,
              fontWeight: 400,
              marginTop: '1rem',
            }}
          >
            Anexar Ata do CMDRS:
          </Typography>
          <Typography sx={{ color: Colors.CINZA_ESCURO, fontSize: '0.875rem' }}>
            Realize abaixo o upload da ata do CMDRS do Município contendo a
            aprovação do Relatório Técnico de atendimentos de ATER praticados
            pelo Município em 2025.
          </Typography>

          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            marginTop='1rem'
          >
            <Button
              variant='outlined'
              component='label'
              startIcon={<AttachFileIcon />}
              sx={{ textTransform: 'none' }}
            >
              Anexar Arquivo
              <input
                type='file'
                accept='image/png, image/jpeg, application/pdf'
                hidden
                onChange={handleFileUpload}
              />
            </Button>

            {uploadedFile && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5rem',
                  backgroundColor: Colors.CINZA_CLARO,
                  borderRadius: '4px',
                }}
              >
                <Typography sx={{ marginRight: '1rem' }}>
                  {uploadedFile.name}
                </Typography>
                <IconButton onClick={() => setUploadedFile(null)}>✕</IconButton>
              </Box>
            )}
          </Stack>
          <Typography
            sx={{
              fontSize: '0.75rem',
              color: Colors.CINZA_MEDIO,
              marginTop: '0.5rem',
            }}
          >
            Imagens em PNG, JPG (máximo de 8 MB) ou PDF (máximo de 20 MB)
          </Typography>
        </ContainerForm>

        <ContainerForm title='Lista de Beneficiários - Atendidos Exclusivamente pelo Município'>
          <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
            marginBottom='1rem'
          >
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                color='primary'
                endIcon={<ArrowDropDownIcon />}
                onClick={handleOpenOrdenarModal}
                sx={{
                  textTransform: 'none',
                  fontSize: '14px',
                  padding: '4px 10px',
                  borderRadius: '20px',
                  borderColor: Colors.AZUL_GOV,
                  color: Colors.AZUL_GOV,
                  '&:hover': {
                    backgroundColor: Colors.AZUL_GOV,
                    color: Colors.BRANCO,
                  },
                }}
              >
                Ordenar
              </Button>
              <Button
                variant='outlined'
                color='primary'
                endIcon={<ArrowDropDownIcon />}
                onClick={handleOpenFilterModal}
                sx={{
                  textTransform: 'none',
                  fontSize: '14px',
                  padding: '4px 10px',
                  borderRadius: '20px',
                  borderColor: Colors.AZUL_GOV,
                  color: Colors.AZUL_GOV,
                  '&:hover': {
                    backgroundColor: Colors.AZUL_GOV,
                    color: Colors.BRANCO,
                  },
                }}
              >
                Filtrar
              </Button>
            </Stack>
            <Button
              variant='contained'
              color='primary'
              sx={{ textTransform: 'none' }}
            >
              + ADICIONAR BENEFICIÁRIO(A)
            </Button>
          </Stack>

          <Typography
            variant='body2'
            sx={{ fontWeight: 400, marginBottom: '0.5rem' }}
          >
            <Checkbox /> Selecionar todos | 0 de {beneficiariesList.length}{' '}
            selecionado(s)
          </Typography>

          {beneficiariesList.map((beneficiary, index) => (
            <Stack
              key={index}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                padding: '0.5rem',
                borderBottom: `1px solid ${Colors.CINZA_CLARO}`,
              }}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <Checkbox />
                <Stack>
                  <Typography variant='body1'>{beneficiary.name}</Typography>
                  <Typography variant='body2' color={Colors.CINZA_MEDIO}>
                    CPF: {beneficiary.cpf}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant='outlined'
                color='primary'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  textTransform: 'none',
                  fontSize: '14px',
                  padding: '8px',
                  borderRadius: '5px',
                  borderColor: Colors.AZUL_GOV,
                  color: Colors.AZUL_GOV,
                  width: '150px',
                  '&:hover': {
                    backgroundColor: Colors.AZUL_GOV,
                    color: Colors.BRANCO,
                  },
                }}
              >
                <span style={{ flex: 1, textAlign: 'left' }}>Ações</span>
                <ArrowDropDownIcon />
              </Button>
            </Stack>
          ))}

          <Stack
            direction='row'
            justifyContent='center'
            spacing={1}
            marginTop='1rem'
          >
            <Button>1</Button>
            <Button>2</Button>
          </Stack>
        </ContainerForm>

        <ContainerForm title='ATER - Atendidos por Instituições parceiras do Município'>
          <FormControl>
            <FormLabel id='foreigner-label'>
              O Município realizou parceria com uma instituição executora de
              Assistência Técnica e Extensão Rural - ATER em 2025?
            </FormLabel>
            <FormLabel id='foreigner-label'>
              De acordo com o art. 23 da Portaria nº009/2024: “Para fins de
              apuração em 2026, a aferição do indicador referente à instituição
              do Conselho Municipal de Desenvolvimento Rural Sustentável - CMDRS
              (IC) irá considerar a realização das seguintes ações: I -
              Aprovação e publicação de Lei municipal que institui o CMDRS, na
              Imprensa Oficial do Estado de Mato Grosso - IOMAT ou outro veículo
              de comunicação oficial do município, no ano de 2025, garantida a
              paridade entre representantes do poder público e da sociedade
              civil. II - Apresentação de, pelo menos, 04 (quatro) atas de
              reunião plenária do CMDRS em 2025, aprovadas e assinadas pelos(as)
              conselheiros(as) presentes e pelo(a) presidente do CMDRS.
            </FormLabel>
            <RadioGroup
              aria-labelledby='foreigner-label'
              value={question1}
              onChange={(e) => setQuestion1(e.target.value)}
            >
              <FormControlLabel value='Não' control={<Radio />} label='Não' />
              <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
            </RadioGroup>
          </FormControl>
        </ContainerForm>

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary' disabled>
            Voltar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleNext}
            //disabled={!isAdvanceEnabled}
          >
            Avançar
          </Button>
        </Stack>
        <Stack />
        <OrdenarModal
          isOpen={isOrdenarModalOpen}
          onClose={handleCloseOrdenarModal}
        />
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={handleCloseFilterModal}
        />
      </Stack>
    </>
  );
};

export default Step1;

import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface UserCardStatusProps {
  status: boolean;
  blocked: boolean;
}

const UserCardStatus: React.FC<UserCardStatusProps> = ({ status, blocked }) => {
  const getStatusStyles = () => {
    if (!status) {
      return {
        icon: (
          <ErrorOutlineOutlinedIcon
            sx={{ color: '#C62828', fontSize: '20px' }}
          />
        ),
        text: 'Situação: Inativo ' + (blocked ? '(Bloqueado)' : ''),
        backgroundColor: '#D32F2F0A',
      };
    } else if (status) {
      return {
        icon: (
          <CheckCircleOutlineIcon sx={{ color: '#2E7D32', fontSize: '20px' }} />
        ),
        text: 'Situação: Ativo ' + (blocked ? '(Bloqueado)' : ''),
        backgroundColor: '#EAF2EA',
      };
    } else {
      return {
        icon: null,
        text: '',
        backgroundColor: '#E0E0E0',
      };
    }
  };

  const statusStyles = getStatusStyles();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: statusStyles.backgroundColor,
        padding: '8px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {statusStyles.icon}
      <Typography
        sx={{
          color: '#00000099',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '26.56px',
          letterSpacing: '0.4px',
          paddingLeft: '8px',
        }}
      >
        {statusStyles.text}
      </Typography>
    </Box>
  );
};

export default UserCardStatus;

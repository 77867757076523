import React from 'react';

import { Divider, Tooltip } from '@mui/material';

import { Box, Stack } from '@mui/system';
import { Header } from 'components/home';
import { CustomLabel } from 'components/shared';
import { CardMenu } from 'components/shared/card_menu';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import { ROUTES } from 'routes/constants';

const ManageCifa2025: React.FC = () => {
  return (
    <MenuProvider>
      <TopNavBar />

      <Header title='Gerenciar CIFA - 2025' showBackButton={true} />

      <Stack mt='32px' paddingX='384px' spacing='1rem'>
        <CustomLabel
          text='Gerenciar CIFA - 2025'
          labelStyle={{
            fontFamily: 'Roboto',
            fontSize: '22px',
            fontWeight: 500,
            letterSpacing: '0.15px',
            lineHeight: '35.2px',
            textAlign: 'left',
          }}
        />

        <Divider />

        <Tooltip title='ATENÇÃO! O prazo para cadastro/alteração do CIFA 2025 é 15/04/2025. Para mais informações, entre em contato através do e-mail: seiaf@agriculturafamiliar.mt.gov.br.'>
          <Box>
            <CardMenu
              title='Cadastrar Município - 2025'
              description='Realize a Inclusão do Município no Cadastro do Índice Municipal de Agricultura Familiar.'
              pathTo={ROUTES.INCLUDE_CIFA_2025}
            />
          </Box>
        </Tooltip>

        <Divider />

        <Tooltip title='ATENÇÃO! O prazo para cadastro/alteração do CIFA 2025 é 15/04/2025. Para mais informações, entre em contato através do e-mail: seiaf@agriculturafamiliar.mt.gov.br.'>
          <Box>
            <CardMenu
              title='Alterar Município - 2025'
              description='Atualize os dados do Município no Cadastro do Índice Municipal de Agricultura Familiar'
              pathTo={ROUTES.EDIT_CIFA_2025}
            />
          </Box>
        </Tooltip>

        <Divider />

        <CardMenu
          title='Visualizar Cadastro do Município - 2025'
          description='Veja os dados do Município no Cadastro do Índice Municipal de Agricultura Familiar.'
          pathTo={ROUTES.VIEW_CIFA_2025}
        />
      </Stack>
    </MenuProvider>
  );
};

export default ManageCifa2025;

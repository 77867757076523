import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

import { Alert, Stack, TextField, Typography } from '@mui/material';

import { postAnswers } from 'api/answers/answers.api';
import { FormName, PostAnswersPayload } from 'api/answers/dtos/answers.dto';
import { ListProducts } from 'components/cifa/list_products';
import { Header } from 'components/home';
import { ContainerForm } from 'components/shared/container_form';
import { FormButton, FormButtonType } from 'components/shared/form_button';
import { HorizontalStepper } from 'components/shared/horizontal_stepper';
import { Modal } from 'components/shared/modal';
import { PageTitle } from 'components/shared/page_title';
import { SnackbarFeedback } from 'components/shared/snackbar_feedback';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import { ROUTES } from 'routes/constants';
import ContainerPainel from 'components/container_painel';

export interface Product {
  name: string;
  identification: string;
  value: string;
}

const CreateFederalSchoolFeeding2025: React.FC = () => {
  const navigate = useNavigate();

  const [provided, setProvided] = useState<string>('');
  const [applied, setApplied] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const [addModal, setAddModal] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>();
  const [identification, setidentification] = useState<string>();
  const [value, setValue] = useState<string>('');
  const [cnpjProducts, setCnpjProducts] = useState<Product[]>([]);
  const [cpfProducts, setCpfProducts] = useState<Product[]>([]);
  const [providedError, setProvidedError] = useState(false);

  const handleCnpjProduct = () => {
    if (name && identification && value) {
      const product: Product = {
        name,
        identification,
        value,
      };

      setCnpjProducts((prevProducts) => {
        const existingProductIndex = prevProducts.findIndex(
          (p) => p.identification === identification,
        );
        if (existingProductIndex !== -1) {
          const updatedProducts = [...prevProducts];
          updatedProducts[existingProductIndex] = product;
          return updatedProducts;
        } else {
          return [...prevProducts, product];
        }
      });

      setidentification(undefined);
      setValue('');
      setName(undefined);
      setAddModal(undefined);
    }
  };

  const handleCpfProduct = () => {
    if (name && identification && value) {
      const product: Product = {
        name,
        identification,
        value,
      };

      setCpfProducts((prevProducts) => {
        const existingProductIndex = prevProducts.findIndex(
          (p) => p.identification === identification,
        );
        if (existingProductIndex !== -1) {
          const updatedProducts = [...prevProducts];
          updatedProducts[existingProductIndex] = product;
          return updatedProducts;
        } else {
          return [...prevProducts, product];
        }
      });

      setidentification(undefined);
      setValue('');
      setName(undefined);
      setAddModal(undefined);
    }
  };

  const handleDeleteCnpjProduct = (id: string) => {
    setCnpjProducts((prevProducts) =>
      prevProducts.filter((p) => p.identification !== id),
    );
    setidentification(undefined);
    setValue('');
    setName(undefined);
  };

  const handleDeleteCpfProduct = (id: string) => {
    setCpfProducts((prevProducts) =>
      prevProducts.filter((p) => p.identification !== id),
    );
    setidentification(undefined);
    setValue('');
    setName(undefined);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const userAccessUuid = localStorage.getItem('userAccessUuid') || '';
    const institutionUuid = localStorage.getItem('institutionUuid') || '';

    const payload: PostAnswersPayload = {
      userAccessUuid,
      institutionUuid,
      formName: FormName.ALIMENTACAO_ESCOLAR_FEDERAL,
      answer: {
        valueProvidedByPnae: parseFloat(provided.replace(/[^\d.-]/g, '')) || 0,
        valueAppliedByPnaeResource:
          parseFloat(applied?.replace(/[^\d.-]/g, '')) || 0,
        productAquiredFromCnpjByPnaeResourceList: cnpjProducts,
        productAquiredFromCpfByPnaeResourceList: cpfProducts,
      },
      referencedYear: 2025,
    };

    try {
      const response = await postAnswers(payload);
      if (response.dados.statusCode === 401) {
        setErrorMessage(response.dados.message);
        return;
      }
      navigate(ROUTES.CREATE_STATE_SCHOOL_FEEDING_2025);
    } catch (error) {
      setErrorMessage(
        'Erro ao enviar os dados da Alimentação Escolar (PNAE Federal).',
      );
    } finally {
      setLoading(false);
    }
  };

  const formatCpf = (cpf: string): string => {
    const cleanedCpf = cpf?.replace(/\D/g, '');
    return cleanedCpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatCnpj = (cnpj: string): string => {
    const cleanedCnpj = cnpj?.replace(/\D/g, '');
    return cleanedCnpj?.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5',
    );
  };

  return (
    <ContainerPainel
      pagina='CIFA - Cadastrar Município - 2025'
      showBackButton={true}
      backButtonPath={ROUTES.INCLUDE_CIFA_2025}
    >
      <HorizontalStepper activeStep={1} />

      <PageTitle
        title='Alimentação Escolar (PNAE Federal)'
        subtitle='Informe corretamente os dados sobre a aquisição de Alimentação Escolar através do PNAE Federal no Município.'
      />

      <Stack marginX='4rem'>
        <ContainerForm title='Valores para Aquisição de Alimentação Escolar - Em 2024'>
          <Stack direction='row' justifyContent='space-between' gap='1rem'>
            <NumericFormat
              id='disponibilizado'
              label='Valor disponibilizado pelo PNAE Federal em R$:'
              value={provided}
              onValueChange={(values) => {
                setProvided(values.value);
                if (values.value) setProvidedError(false);
              }}
              thousandSeparator='.'
              decimalSeparator=','
              prefix='R$ '
              customInput={TextField}
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1 }}
              error={providedError}
              helperText={providedError ? 'Este campo é obrigatório.' : ''}
            />
            <NumericFormat
              id='aplicado'
              label='Valores totais aplicados pelo município na Alimentação Escolar oriundos de todo e qualquer fornecedor no ano de 2024 '
              value={applied}
              onValueChange={(values) => {
                setApplied(values.value);
                if (values.value) setProvidedError(false);
              }}
              thousandSeparator='.'
              decimalSeparator=','
              prefix='R$ '
              customInput={TextField}
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1 }}
              error={providedError}
              helperText={providedError ? 'Este campo é obrigatório.' : ''}
            />
          </Stack>
        </ContainerForm>
      </Stack>

      <ContainerForm title='Produtos adquiridos via CNPJ (com recurso do PNAE Federal) - Valores Aplicados na Aquisição de Produtos (Opcional)'>
        {cnpjProducts.length === 0 && (
          <Alert
            severity='info'
            sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}
          >
            <Typography
              variant='body2'
              fontSize='14px'
              textAlign='left'
              lineHeight={1.5}
            >
              Você ainda não realizou a inclusão de nenhuma Aquisição de Produto
              via CNPJ. Clique no botão abaixo ”Adicionar Aquisição” para
              incluir uma Aquisição de Produto.
            </Typography>
          </Alert>
        )}

        <FormButton
          type={FormButtonType.PRIMARY}
          title='+ Adicionar Aquisição'
          onClick={() => setAddModal('CNPJ')}
        />

        {cnpjProducts.length > 0 && (
          <ListProducts
            products={cnpjProducts}
            documentType={'CNPJ'}
            edit={(value) => {
              setName(value.name), setidentification(value.identification);
              setValue(value.value);
              setAddModal('CNPJ');
            }}
            remove={(value) => handleDeleteCnpjProduct(value.identification)}
          />
        )}
      </ContainerForm>

      <ContainerForm title='Produtos adquiridos via CPF (com recurso do PNAE Federal) - Valores Aplicados na Aquisição de Produtos (Opcional)'>
        {cpfProducts.length === 0 && (
          <Alert
            severity='info'
            sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}
          >
            <Typography
              variant='body2'
              fontSize='14px'
              textAlign='left'
              lineHeight={1.5}
            >
              Você ainda não realizou a inclusão de nenhuma Aquisição de Produto
              via CPF. Clique no botão abaixo ”Adicionar Aquisição” para incluir
              uma Aquisição de Produto.
            </Typography>
          </Alert>
        )}

        <FormButton
          type={FormButtonType.PRIMARY}
          title='+ Adicionar Aquisição'
          onClick={() => setAddModal('CPF')}
        />

        {cpfProducts.length > 0 && (
          <ListProducts
            products={cpfProducts}
            documentType={'CPF'}
            edit={(value) => {
              setName(value.name), setidentification(value.identification);
              setValue(value.value);
              setAddModal('CPF');
            }}
            remove={(value) => handleDeleteCpfProduct(value.identification)}
          />
        )}
      </ContainerForm>

      <Stack
        direction={{ md: 'row-reverse' }}
        marginY='2rem'
        sx={{ gap: '1rem' }}
      >
        <FormButton
          title='Avançar'
          type={FormButtonType.PRIMARY}
          onClick={() => {
            if (!provided || provided === '0') {
              setProvidedError(true);
              return;
            }
            setProvidedError(false);
            handleSubmit();
          }}
          isDisabled={loading}
        />
        <FormButton
          title='Voltar'
          type={FormButtonType.SECONDARY}
          onClick={() => navigate(ROUTES.INCLUDE_CIFA_2025)}
        />
      </Stack>

      <Modal
        open={!!addModal}
        onClose={() => setAddModal(undefined)}
        title={`Adicionar Aquisição via ${addModal} - Produtos adquiridos com recurso do PNAE Federal`}
        buttons={[
          <FormButton
            key={1}
            type={FormButtonType.SECONDARY}
            title='Voltar'
            onClick={() => {
              setAddModal(undefined);
              setName(undefined);
              setidentification(undefined);
              setValue('');
            }}
          />,
          <FormButton
            key={0}
            type={FormButtonType.PRIMARY}
            title='Confirmar'
            onClick={() =>
              addModal === 'CNPJ' ? handleCnpjProduct() : handleCpfProduct()
            }
          />,
        ]}
        directionButtons='row'
      >
        <>
          <TextField
            id='nome'
            label='Nome/Razão social'
            variant='outlined'
            value={name}
            onChange={(e) => setName(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Stack
            direction='row'
            justifyContent='space-between'
            gap='1rem'
            mt='1rem'
          >
            <TextField
              id='documento'
              label={addModal}
              variant='outlined'
              value={
                addModal === 'CPF'
                  ? formatCpf(identification!)
                  : formatCnpj(identification!)
              }
              onChange={(e) =>
                setidentification(e.target.value.replace(/\D/g, ''))
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: addModal === 'CPF' ? 14 : 18,
              }}
              sx={{ flex: 1 }}
            />
            <NumericFormat
              id='valor'
              label='Valor'
              value={value}
              onChange={(e) => setValue(e.target.value.replace(/\D/g, ''))}
              thousandSeparator='.'
              decimalSeparator=','
              prefix='R$ '
              customInput={TextField}
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1 }}
            />
          </Stack>
        </>
      </Modal>

      {errorMessage && (
        <SnackbarFeedback isOpen type='error' message={errorMessage} />
      )}
    </ContainerPainel>
  );
};

export default CreateFederalSchoolFeeding2025;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { CustomAlert, CustomTextField } from 'components/shared';
import { Field } from 'formik';
import { UserFormAction } from 'pages/user/user_form';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import { useEffect } from 'react';

interface PersonalDataFormProps {
  municipalitiesOptions: { name: string }[];
  loadingMunicipalities: boolean;
  values: any;
  errors: any;
  touched: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  duplicateError: string | null;
  fieldsRef: {
    cpfRef: React.RefObject<HTMLInputElement>;
    emailRef: React.RefObject<HTMLInputElement>;
  };
  clearDuplicateError: () => void;
  userFormAction: UserFormAction;
  setFieldValue: (field: string, value: any) => void;
  primeiroAcessoInstituicao?: { 
    address?: {
      municipality?: string;
    };
  };
  user?: any;
}

const formatPhoneNumber = (phone: string) => {
  const cleaned = phone.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

  if (match) {
    const intlCode = match[1];
    const part1 = match[2];
    const part2 = match[3];
    return `(${intlCode}) ${part1}-${part2}`;
  }

  return phone;
};

const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  duplicateError,
  fieldsRef,
  clearDuplicateError,
  userFormAction,
  municipalitiesOptions,
  setFieldValue,
  primeiroAcessoInstituicao,
  user,
}) => {

  useEffect(() => {
    if (user?.address?.municipality) {
      setFieldValue('personalData.municipalities', user.address.municipality);
    } else if (primeiroAcessoInstituicao?.address?.municipality) {
      setFieldValue(
        'personalData.municipalities',
        primeiroAcessoInstituicao.address.municipality
      );
    }
  }, [user, primeiroAcessoInstituicao, setFieldValue]);

  const handlePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any,
  ) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    const formatted = formatPhoneNumber(value);
    setFieldValue('personalData.phone', formatted);
  };

  const errorMessage = () => {
    if (errors.personalData?.cpf && touched.personalData?.cpf) {
      return 'Não foi possível realizar a validação do "CPF". Informe-o corretamente abaixo.';
    }
    if (errors.personalData?.email && touched.personalData?.email) {
      return 'O e-mail informado é inválido. Corrija-o abaixo.';
    }
    return '';
  };

  return (
    <Box sx={{ width: '1152px', marginTop: '20px' }}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h6'>Dados pessoais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {errorMessage() && (
            <CustomAlert severity='error' message={errorMessage()} />
          )}
          {duplicateError && (
            <CustomAlert severity='error' message={duplicateError} />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Field
                name='personalData.name'
                as={CustomTextField}
                label='Nome Completo'
                variant='outlined'
                size='small'
                disabled={userFormAction === UserFormAction.EDITAR}
                value={values.personalData.name.toUpperCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.personalData?.name &&
                  Boolean(errors.personalData?.name)
                }
                helperText={
                  touched.personalData?.name && errors.personalData?.name
                }
                InputLabelProps={{ shrink: true }}
              />
              <Field name='personalData.cpf'>
                {({ field }: any) => (
                  <ReactInputMask
                    {...field}
                    mask='999.999.999-99'
                    maskChar={null}
                    onChange={(e) => {
                      field.onChange(e);
                      clearDuplicateError();
                    }}
                  >
                    {(inputProps: any) => (
                      <CustomTextField
                        {...inputProps}
                        label='CPF'
                        variant='outlined'
                        size='small'
                        inputRef={fieldsRef.cpfRef}
                        error={
                          touched.personalData?.cpf &&
                          Boolean(errors.personalData?.cpf)
                        }
                        helperText={
                          touched.personalData?.cpf && errors.personalData?.cpf
                        }
                        InputProps={{
                          ...inputProps,
                          disabled: userFormAction === UserFormAction.EDITAR,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </ReactInputMask>
                )}
              </Field>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Field
                name='personalData.email'
                as={CustomTextField}
                label='Email'
                variant='outlined'
                size='small'
                value={values.personalData.email.toUpperCase()}
                onChange={(e: React.ChangeEvent<any>) => {
                  handleChange(e);
                  clearDuplicateError();
                }}
                onBlur={handleBlur}
                inputRef={fieldsRef.emailRef}
                error={
                  touched.personalData?.email &&
                  Boolean(errors.personalData?.email)
                }
                helperText={
                  touched.personalData?.email && errors.personalData?.email
                }
                InputLabelProps={{ shrink: true }}
              />
              <Field name='personalData.phone'>
                {({ field, form: { setFieldValue, errors, touched } }: any) => (
                  <CustomTextField
                    {...field}
                    label='Telefone'
                    variant='outlined'
                    size='small'
                    value={field.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePhoneChange(e, setFieldValue)
                    }
                    onBlur={() =>
                      setFieldValue('personalData.phone', field.value, true)
                    }
                    error={
                      touched.personalData?.phone &&
                      Boolean(errors.personalData?.phone)
                    }
                    helperText={
                      touched.personalData?.phone && errors.personalData?.phone
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Field>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <FormControl
                fullWidth
                size='small'
                variant='outlined'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                  },
                  '& .MuiInputLabel-root': {
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#00000099',
                    '&.Mui-focused': {
                      color: '#00000099',
                    },
                  },
                }}
              >
                <InputLabel id='municipality-label'>Município</InputLabel>
                <Field name='personalData.municipalities'>
                  {({ field, form }: any) => (
                    <Select
                      {...field}
                      label='Município'
                      labelId='municipality-label'
                      id='municipality-select'
                      value={values.personalData.municipalities || ''}
                      defaultValue={values.personalData.municipality || ''}
                      onChange={(e) =>
                        form.setFieldValue(
                          'personalData.municipalities',
                          e.target.value,
                        )
                      }
                      onBlur={handleBlur}
                      displayEmpty
                      error={
                        touched.personalData?.municipalities &&
                        Boolean(errors.personalData?.municipalities)
                      }
                    >
                      {municipalitiesOptions.map((muni) => (
                        <MenuItem
                          key={muni.name}
                          value={muni.name}
                          sx={{
                            fontFamily: 'Roboto',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            textAlign: 'left',
                          }}
                        >
                          {muni.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>

                {touched.personalData?.municipalities &&
                  errors.personalData?.municipalities && (
                    <Typography
                      color='error'
                      variant='caption'
                      textAlign='left'
                    >
                      {errors.personalData?.municipalities}
                    </Typography>
                  )}
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Field
                name='personalData.position'
                as={CustomTextField}
                label='Cargo'
                variant='outlined'
                size='small'
                value={values.personalData.position.toUpperCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.personalData?.position &&
                  Boolean(errors.personalData?.position)
                }
                helperText={
                  touched.personalData?.position &&
                  errors.personalData?.position
                }
                InputLabelProps={{ shrink: true }}
              />
              <Field
                name='personalData.occupation'
                as={CustomTextField}
                label='Função'
                variant='outlined'
                size='small'
                value={values.personalData.occupation.toUpperCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.personalData?.occupation &&
                  Boolean(errors.personalData?.occupation)
                }
                helperText={
                  touched.personalData?.occupation &&
                  errors.personalData?.occupation
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PersonalDataForm;

import React, { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../components/login';
import { CustomAlert, CustomSelect, CustomTextField, DynamicDialog, ErrorDialog } from 'components/shared';
import { AuthStatus, useAuth } from 'contexts/auth/auth_context';
import { authService } from 'services/auth/auth_service';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { addValidity } from 'api/validity/validity.api';
import { IUser } from 'modules/auth/interfaces/storage.interface';
import RegisteredIcon from '../../assets/icons/registered-icon.svg';
import { ValidityStatusEnum } from 'common/enums';

interface DialogState {
  isOpen: boolean;
  icon: string;
  title: string;
  message: string;
  buttonText: string;
  onLogout: (() => void) | null;
}

type DialogButtonConfig = {
  text: string;
  action: () => void;
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
};

type ErrorDialogState = {
  open: boolean;
  title: string;
  message1: string;
  message2?: string | null;
  button1: DialogButtonConfig;
  button2?: DialogButtonConfig | null;
};

const LoginValidation: React.FC = () => {
  const navigate = useNavigate();
  const { authenticateWithMtLogin, logout } = useAuth();

  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogState>({
    open: false,
    title: '',
    message1: '',
    button1: {
      text: '',
      action: () => {},
      color: 'inherit',
    },
    button2: null,
  });
  
  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    icon: '',
    title: '',
    message: '',
    buttonText: 'Concluir',
    onLogout: null,
  });

  const initialValues = {
    managementTerm: {
      startDate: '',
      endDate: '',
      endDateIndefinite: false,
    },
  };
    
  const [user, setUser] = useState<IUser>(); 
  const [validityModalVisibility, setValidityModalVisibility] = useState(false)
  const [dateError, setDateError] = useState('');
  const handleSubmit = async (values: typeof initialValues) => {
      await addValidity({
        userUuid: user?.uuid,
        dataInicial: new Date(values.managementTerm.startDate),
        dataFinal:
          values.managementTerm.endDate !== ''
            ? new Date(values.managementTerm.endDate)
            : null,
        vigenciaIndeterminada: values.managementTerm.endDateIndefinite,
      });

      setValidityModalVisibility(false);
      setDialogState({
          isOpen: true,
          icon: RegisteredIcon,
          title:
            'Vigência cadastrada com sucesso - Será realizado o seu logout do sistema',
          message: `Identificamos que você é Representante Legal e está realizando o cadastro da vigência no seu próprio cadastro,
          desta forma realizaremos o seu logout do sistema.O cadastro permanecerá inativado até que o gerente estadual valide as informações da nova vigência.`,
          buttonText: 'Concluir',
          onLogout: () => logout(),
      });
    };

  const validationSchema = Yup.object({
    managementTerm: Yup.object({
      startDate: Yup.date().required('Informe a data inicial corretamente.'),
      endDateIndefinite: Yup.boolean().test(
        'is-indefinite-disabled',
        'Não é permitido definir data final indefinida em modo de edição.',
        function (value) {
          return true;
        },
      ),
    endDate: Yup.date()
      .nullable(),
    }),
  });

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleSetSessionExpiredDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Sua sessão expirou',
      message1:
        'Por segurança a sessão expirou. Você deve voltar para a tela inicial e entrar novamente.',
      button1: {
        text: 'FECHAR',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetUserBlockedDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Usuário Bloqueado',
      message1: 'O acesso do Usuário informado encontra-se bloqueado.',
      message2: `Orientamos que entre em contato com a Administração do sistema para realizar o desbloqueio do Usuário, ou Informe outro
        Usuário para realizar o acesso na página anterior.`,

      button1: {
        text: 'voltar',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);
    const handleSetUserValidityExpiredDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Termo de vigência expirado',
      message1: 'Representante legal da instuição cadastrada está com sua vigência expirada, por favor cadastrar nova vigência',

      button1: {
        text: 'Fechar',
        action() {
          logout();
        },
        color: 'primary',
      },
      button2: {
        text: 'Cadastrar Vigência',
        action() {
          setValidityModalVisibility(true);
          setErrorDialogState({
            open: false,
            title: '',
            message1: '',
            button1: {
              text: '',
              action: () => {},
              color: 'inherit',
            },
            button2: null,
          })
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetUserInactiveDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Usuário Inativo',
      message1: 'O acesso do Usuário informado não está ativo.',
      message2: `Orientamos que entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário,
        ou Informe outro Usuário para realizar o acesso na página anterior.`,

      button1: {
        text: 'voltar',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetUserNotFoundDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Usuário Não Encontrado',
      message1: 'O acesso do Usuário informado não foi localizado.',
      message2: `Orientamos que entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário,
        ou Informe outro Usuário para realizar o acesso na página anterior.`,

      button1: {
        text: 'voltar',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetRequestErrorDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Ocorreu um erro na sua solicitação',
      message1: 'Deseja atualizar a página atual?',
      button1: {
        text: 'fechar',
        action() {
          logout();
        },
        color: 'inherit',
      },
      button2: {
        text: 'atualizar',
        action() {
          handleRefresh();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  useEffect(() => {
    const validateLogin = async () => {
      const codeFromUrl = authService.getCodeFromUrl();

      if (!codeFromUrl) {
        handleSetSessionExpiredDialog();
        return;
      }

      const resultAuthenticateWithMtLogin = await authenticateWithMtLogin(
        codeFromUrl,
      );

      /* console.log(
        'resultAuthenticateWithMtLogin',
        JSON.stringify(resultAuthenticateWithMtLogin),
      ); */

      if (!resultAuthenticateWithMtLogin.success) {
        if (
          resultAuthenticateWithMtLogin.status === AuthStatus.sessionExpired
        ) {
          navigate(ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY);
          //handleSetSessionExpiredDialog();
          return;
        }
        if (resultAuthenticateWithMtLogin.status === AuthStatus.validityExpired) {
          setUser(resultAuthenticateWithMtLogin.userInfo)

          const hasPendingValidity  = resultAuthenticateWithMtLogin.userInfo?.validityList?.some((validity) => {
            if (validity.status === ValidityStatusEnum.INACTIVE) {
              if (validity.endAt) {
                const currentDate = new Date();
                const endDate = new Date(validity.endAt);
                return currentDate < endDate;
              }
              return true;
            }
            return false;
          })

          if(!hasPendingValidity){
            handleSetUserValidityExpiredDialog();
            return;
          }

          setDialogState({
              isOpen: true,
              icon: RegisteredIcon,
              title:
                'Vigência aguardando aprovação',
              message: `Identificamos que você é Representante Legal e que tem uma vigência com análise pendente,
              desta forma realizaremos o seu logout do sistema. O cadastro permanecerá inativado até que o gerente estadual valide as informações da nova vigência.`,
              buttonText: 'Concluir',
              onLogout: () => logout(),
          });
          return;
        }
        if (resultAuthenticateWithMtLogin.status === AuthStatus.userBlocked) {
          handleSetUserBlockedDialog();
          return;
        }
        if (resultAuthenticateWithMtLogin.status === AuthStatus.userInactive) {
          handleSetUserInactiveDialog();
          return;
        }
        if (resultAuthenticateWithMtLogin.status === AuthStatus.userNotFound) {
          handleSetUserNotFoundDialog();
          return;
        }

        if (resultAuthenticateWithMtLogin.status === AuthStatus.requestError) {
          handleSetRequestErrorDialog();
          return;
        }
      } else {
        if (
          resultAuthenticateWithMtLogin.status === AuthStatus.userAcceptTerms
        ) {
          //navigate(ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY);
          navigate(ROUTES.HOME);
        }
        if (
          resultAuthenticateWithMtLogin.status === AuthStatus.userAuthorized
        ) {
          navigate(ROUTES.HOME);
          //navigate(ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY);
        }
      }
    };

    validateLogin();
  }, [
    authenticateWithMtLogin,
    handleSetRequestErrorDialog,
    handleSetSessionExpiredDialog,
    handleSetUserBlockedDialog,
    handleSetUserInactiveDialog,
    handleSetUserNotFoundDialog,
    navigate,
  ]);

  return (
    <>
      <Loading />
        <DynamicDialog
          isOpen={dialogState.isOpen}
          icon={dialogState.icon}
          title={dialogState.title}
          message={dialogState.message}
          buttonText='Concluir'
          onClose={() => {
            setDialogState((prevState) => ({
              ...prevState,
              isOpen: false,
              onLogout: null,
            }));
          }}
          onLogout={dialogState.onLogout || undefined}
        />
      <Dialog open={validityModalVisibility} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              color: '#000000DE',
              marginTop: '20px',
            }}
          >
            Adicionar Nova Vigência de Gestão
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors: any = {};
              if (
                values.managementTerm.endDate &&
                values.managementTerm.startDate
              ) {
                if (
                  new Date(values.managementTerm.endDate) <
                  new Date(values.managementTerm.startDate)
                ) {
                  errors.managementTerm = {
                    endDate: 'A data final deve ser posterior à data inicial.',
                  };
                  setDateError(
                    'A data final deve ser posterior à data inicial.',
                  );
                } else {
                  setDateError('');
                }
              }
              return errors;
            }}
            validateOnMount={true}
          validateOnChange={true} 
          >
            {({
              handleChange,
              values,
              handleBlur,
              touched,
              errors,
              isValid,
              setFieldValue,
            }) => (
              <Form>
                {dateError && (
                  <CustomAlert severity='error' message={dateError} />
                )}
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
                  ></Box>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', gap: '16px'}}
                  >
                    <Box sx={{ flexBasis: '50%' }}>
                      <Field
                        name='managementTerm.startDate'
                        as={CustomTextField}
                        label='Data inicial - de'
                        type='date'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.managementTerm?.startDate &&
                          Boolean(errors.managementTerm?.startDate)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      {touched.managementTerm?.startDate &&
                        errors.managementTerm?.startDate && (
                          <Typography
                            color='error'
                            variant='caption'
                            sx={{
                              display: 'block',
                              textAlign: 'left',
                              margin: '5px 0px 0px 15px',
                            }}
                          >
                            {errors.managementTerm?.startDate}
                          </Typography>
                        )}
                    </Box>

                    <Box sx={{ flexBasis: '50%', display: 'grid', gap: '8px' }}>
                      <Field
                        name='managementTerm.endDate'
                        as={CustomTextField}
                        label='Data Final'
                        type='date'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.managementTerm?.endDate &&
                          Boolean(errors.managementTerm?.endDate)
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled={values.managementTerm.endDateIndefinite}
                        value={
                          values.managementTerm.endDateIndefinite
                            ? ''
                            : values.managementTerm.endDate
                        }
                      />
                      {touched.managementTerm?.endDate &&
                        errors.managementTerm?.endDate && (
                          <Typography
                            color='error'
                            variant='caption'
                            sx={{
                              display: 'block',
                              textAlign: 'left',
                              margin: '5px 0px 0px 15px',
                            }}
                          >
                            {errors.managementTerm?.endDate}
                          </Typography>
                        )}

                      <FormControlLabel
                        control={
                          <Field
                            name='managementTerm.endDateIndefinite'
                            type='checkbox'
                            as={Checkbox}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChange(e);
                              if (e.target.checked) {
                                setFieldValue('managementTerm.endDate', '');
                                setDateError('');
                              }
                            }}
                          />
                        }
                        label='Data Final Indeterminada'
                        componentsProps={{
                          typography: {
                            sx: {
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                              textAlign: 'left',
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <DialogActions
                  sx={{
                    justifyContent: 'flex-start',
                    padding: 0,
                    margin: '0px 0px 30px 0px',
                  }}
                >
                  <Button
                    variant='contained'
                    size='medium'
                    sx={{ backgroundColor: '#0048B6' }}
                    type='submit'
                    disabled={!isValid}
                  >
                    Confirmar
                  </Button>
                  <Button
                    size='medium'
                    onClick={() => logout()}
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <ErrorDialog
        open={errorDialogState.open}
        title={errorDialogState.title}
        message1={errorDialogState.message1}
        {...(errorDialogState.message2 && {
          message2: errorDialogState.message2,
        })}
        button1Text={errorDialogState.button1.text}
        button1Action={errorDialogState.button1.action}
        button1Color={errorDialogState.button1.color}
        {...(errorDialogState.button2 && {
          button2Text: errorDialogState.button2.text,
          button2Action: errorDialogState.button2.action,
          button2Color: errorDialogState.button2.color,
        })}
      />
    </>
  );
};

export default LoginValidation;

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Warning } from '@mui/icons-material';

interface Usuario {
  uuid: string;
  name: string;
  email: string;
  address: {
    municipality: string;
  };
  isRegisterBlocked: boolean;
  permission: {
    name: string;
  };
  city?: string;
}

interface VerificaBloqueadoProps {
  usersData: {
    data: Usuario[];
  };
}

export default function VerificaBloqueado({
  usersData,
}: VerificaBloqueadoProps) {
  const [blockedUsers, setBlockedUsers] = useState<Usuario[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (blockedUsers.length > 0 || !usersData?.data?.length) return;

    const representantesLegaisBloqueados = usersData.data.filter(
      (user) =>
        user.isRegisterBlocked &&
        user.permission.name === 'REPRESENTANTE LEGAL MUNICIPAL',
    );

    setBlockedUsers(representantesLegaisBloqueados);
    console.log(
      'representantesLegaisBloqueados',
      representantesLegaisBloqueados,
    );
    setOpenDialog(representantesLegaisBloqueados.length > 0);
  }, [usersData]);

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Warning sx={{ color: 'warning.main', fontSize: 28 }} />
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              color: '#000000DE',
            }}
          >
            Representantes Legais Bloqueados
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography color='text.secondary' mb={3} sx={{ fontSize: '16px' }}>
          Os seguintes representantes legais municipais estão com o registro
          bloqueado:
        </Typography>

        {blockedUsers.map((user) => (
          <Box
            key={user.uuid}
            sx={{
              p: 2.5,
              mb: 2,
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
              transition: 'box-shadow 0.2s ease-in-out',
              '&:hover': {
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
              },
              '&:last-child': { mb: 0 },
            }}
          >
            <Typography fontWeight='500' sx={{ fontSize: '16px', mb: 1 }}>
              {user.name}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{ fontSize: '14px', mb: 0.5 }}
            >
              {user.address.municipality}
            </Typography>
            {user.city && (
              <Typography color='text.secondary' sx={{ fontSize: '14px' }}>
                Município: {user.city}
              </Typography>
            )}
          </Box>
        ))}
      </DialogContent>

      <DialogActions sx={{ p: 3, pt: 2 }}>
        <Button
          onClick={() => setOpenDialog(false)}
          variant='contained'
          sx={{
            px: 4,
            py: 1,
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '16px',
          }}
        >
          Entendi
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useState } from 'react';
import {
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  Modal,
  Paper,
  Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Colors } from 'theme';
import { ListAgreementTermResponse } from 'api/agreement-term/api.types';
import { formatDate } from 'utils/format_date';

interface Ata {
  titlePDF: string;
  label: string;
  fileName: string;
  fileSize: string;
  onView: () => void;
}

interface Beneficiary {
  name: string;
  cpf: string;
}

interface DataConfirmationProps {
  title?: string;
  subtitle?: string;
  data?: {
    label: string;
    value: string;
    isEditable?: boolean;
    onAction?: () => void;
  }[];
  additionalText?: string;
  atas?: Ata[];
  beneficiaries?: Beneficiary[];
  titleBeneficiaries?: string;
  showEdit?: true;
  historyTerms?: ListAgreementTermResponse[];
  showHistoryTerms?: boolean;
  showTerm?: boolean;
}

const DataConfirmation: React.FC<DataConfirmationProps> = ({
  title,
  subtitle,
  data,
  atas,
  beneficiaries,
  titleBeneficiaries,
  showEdit,
  historyTerms,
  showHistoryTerms,
  showTerm,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Ata | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenModal = (ata: Ata) => {
    setSelectedFile(ata);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFile(null);
  };

  const totalPages = beneficiaries
    ? Math.ceil(beneficiaries.length / itemsPerPage)
    : 0;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleDownload = (fileUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName || 'termo_de_adesao.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Stack
      sx={{
        backgroundColor: Colors.BRANCO_GOV,
        borderRadius: '8px',
        marginTop: '1rem',
        padding: '1rem',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Title Section */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        onClick={toggleExpanded}
        sx={{
          cursor: 'pointer',
          padding: '0.5rem 1rem',
          borderBottom: isExpanded ? `1px solid ${Colors.CINZA_CLARO}` : 'none',
        }}
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <IconButton size='small' sx={{ marginRight: '0.5rem' }}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <Typography variant='h6' fontWeight='500' fontSize='1.25rem'>
            {title}
          </Typography>
        </Stack>
        {showEdit && (
          <Button
            variant='text'
            color='primary'
            onClick={(e) => e.stopPropagation()}
            sx={{ textTransform: 'none' }}
          >
            Editar
          </Button>
        )}
      </Stack>

      {/* Content Section */}
      {isExpanded && (
        <>
          {data && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem',
                padding: '1rem',
              }}
            >
              {data?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: '1 1 calc(50% - 1rem)',
                    minWidth: '200px',
                  }}
                >
                  <Typography
                    variant='body1'
                    fontWeight={400}
                    sx={{ color: Colors.CINZA_ESCURO, marginBottom: '0.5rem' }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    variant='body2'
                    fontWeight={400}
                    sx={{ color: Colors.CINZA_MEDIO }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {/* Termo leitura */}
          {showTerm && (
            <Box sx={{ marginTop: '1rem' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingBottom: '10px',
                  borderBottom: '1px solid #E0E0E0',
                }}
              >
                <Typography
                  variant='h6'
                  fontWeight='bold'
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                  }}
                >
                  TERMO DE ADESÃO MUNICIPAL
                </Typography>
              </Box>

              <Box sx={{ marginTop: '1rem', textAlign: 'justify' }}>
                <Typography variant='body1'>
                  Eu assumo o compromisso de manter confidencialidade e sigilo
                  sobre todas as informações a que tiver acesso como membro da
                  Comissão de Acolhimento e Orientação da SEAF, instaurada nos
                  termos da Portaria Interna SEAF, de 10 de outubro de 2016.
                </Typography>

                <Typography variant='body1' sx={{ marginTop: '1rem' }}>
                  Por este termo de confidencialidade e sigilo comprometo-me:
                </Typography>

                <Typography variant='body1' sx={{ marginTop: '0.5rem' }}>
                  1. A não utilizar as informações confidenciais a que tiver
                  acesso, para gerar benefício próprio exclusivo e/ou
                  unilateral, presente ou futuro, ou para o uso de terceiros;
                </Typography>

                <Typography variant='body1' sx={{ marginTop: '0.5rem' }}>
                  2. A não efetuar nenhuma gravação ou cópia da documentação
                  confidencial a que tiver acesso;
                </Typography>

                <Typography variant='body1' sx={{ marginTop: '0.5rem' }}>
                  3. A não me apropriar de material confidencial e/ou sigiloso
                  que venha a ser disponibilizado;
                </Typography>

                <Typography variant='body1' sx={{ marginTop: '0.5rem' }}>
                  4. A não repassar o conhecimento das informações
                  confidenciais, responsabilizando-me por todas as pessoas que
                  vierem a ter acesso às informações, por meu intermédio, e
                  obrigando-me, assim, a ressarcir a ocorrência de qualquer dano
                  e/ou prejuízo oriundo de uma eventual quebra de sigilo das
                  informações fornecidas.
                </Typography>

                <Typography variant='body1' sx={{ marginTop: '1rem' }}>
                  Neste Termo, as seguintes expressões serão assim definidas:
                  Informação Confidencial significará toda informação revelada
                  sob a forma escrita, verbal ou por quaisquer outros meios.
                </Typography>

                <Typography variant='body1' sx={{ marginTop: '0.5rem' }}>
                  Informação Confidencial inclui, mas não se limita, à
                  informação sobre as questões relativas ao propósito da criação
                  da Comissão de Acolhimento e Orientação, de acordo com a
                  Portaria Interna SEAF, de 10 de outubro de 2016.
                </Typography>
              </Box>
            </Box>
          )}

          {/* Histórico de Termos */}
          {showHistoryTerms && historyTerms && (
            <Box sx={{ marginTop: '1rem' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingBottom: '10px',
                  borderBottom: '1px solid #E0E0E0',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Button
                    variant='outlined'
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                      color: '#0048B6',
                      border: '1px solid #0048B6',
                      borderRadius: '16px',
                      paddingX: '16px',
                    }}
                  >
                    Ordenar
                  </Button>
                  <Button
                    variant='outlined'
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                      color: '#0048B6',
                      border: '1px solid #0048B6',
                      borderRadius: '16px',
                      paddingX: '16px',
                    }}
                  >
                    Filtrar
                  </Button>
                </Box>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    height: '50px',
                  }}
                >
                  <Checkbox
                    sx={{ color: '#000000' }}
                    //checked={selectAllChecked}
                    //onChange={handleSelectAll}
                  />
                  <Typography sx={{ color: '#000000', fontSize: '18px' }}>
                    Selecionar todos | 15 de 15 selecionado(s)
                  </Typography>
                </Box>
              </Box>
              {historyTerms.map((term, index) => (
                <Paper
                  key={index}
                  sx={{
                    overflow: 'hidden',
                    borderRadius: '8px',
                    marginBottom: '12px',
                  }}
                >
                  <Box
                    sx={{
                      padding: '16px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Checkbox color='primary' />
                      <Box>
                        <Typography variant='h6' fontWeight='bold'>
                          {formatDate(term.signatureAt)}
                        </Typography>
                        <Typography fontSize='14px' color='#666'>
                          Data da Assinatura - Representante Legal Municipal:{' '}
                          {formatDate(
                            term.legalMunicipalRepresentativeSignatureAt,
                          )}
                        </Typography>
                        <Typography fontSize='14px' color='#666'>
                          Data da Assinatura - Representante Legal Estadual:{' '}
                          {formatDate(term.legalStateRepresentativeSignatureAt)}
                        </Typography>
                        <Typography fontSize='14px' color='#666'>
                          Data do Vencimento: {formatDate(term.dueAt)}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Botão de ação */}
                    <Button
                      variant='outlined'
                      endIcon={<DownloadIcon sx={{ fontSize: 20 }} />}
                      onClick={() =>
                        handleDownload(term.fileId?.base64, term.fileId?.name)
                      }
                      sx={{
                        height: '36px',
                        minWidth: '120px',
                        borderRadius: '8px',
                        fontWeight: 'bold',
                        color: '#1976D2',
                        border: '1px solid #1976D2',
                        textTransform: 'uppercase',
                        '&:hover': {
                          backgroundColor: '#E3F2FD',
                          border: '1px solid #1565C0',
                        },
                      }}
                    >
                      Baixar Termo de Adesão
                    </Button>
                  </Box>
                  {/* Status do Termo */}
                  <Box
                    sx={{
                      backgroundColor: `${
                        term.status === 'ATIVO'
                          ? '#EAF2EA'
                          : term.status === 'INATIVO'
                          ? '#D32F2F0A'
                          : '#ED6C020A'
                      }`,
                      padding: '8px 16px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      color: `${
                        term.status === 'ATIVO'
                          ? '#123214'
                          : term.status === 'INATIVO'
                          ? '#D32F2F'
                          : '#ED6C02'
                      }`,
                    }}
                  >
                    {term.status === 'Ativo' && '✔️'}
                    {term.status === 'Bloqueado' && '⚠️'}
                    {term.status === 'Inativo' && '❗'}
                    <Typography fontWeight='400'>{`Situação: ${term.status}`}</Typography>
                  </Box>
                </Paper>
              ))}
            </Box>
          )}

          {/* ATAS Section */}
          {atas && (
            <Stack spacing={2} sx={{ marginTop: '1rem' }}>
              {atas.map((ata, index) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.CINZA_MEDIO,
                        fontWeight: '700',
                        marginTop: '1rem',
                        marginBottom: '0.5rem',
                      }}
                    >
                      {ata.label} ({ata.fileSize})
                    </Typography>
                    <Button
                      variant='text'
                      color='primary'
                      startIcon={<OpenInNewIcon />}
                      onClick={() => handleOpenModal(ata)}
                    >
                      Visualizar
                    </Button>
                  </Box>
                  <Stack
                    key={index}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    padding='0.5rem'
                    sx={{
                      backgroundColor: Colors.CINZA_CLARO,
                      borderRadius: '4px',
                    }}
                  >
                    <Box>
                      <Typography
                        variant='body2'
                        fontWeight={400}
                        sx={{ color: Colors.CINZA_MEDIO }}
                      >
                        {ata.label}
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ color: Colors.CINZA_ESCURO }}
                      >
                        {ata.fileName} ({ata.fileSize})
                      </Typography>
                    </Box>
                  </Stack>
                </>
              ))}

              {/* Beneficiaries Section */}
              {beneficiaries && (
                <>
                  <Typography
                    variant='h6'
                    sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
                  >
                    {titleBeneficiaries}
                  </Typography>
                  {beneficiaries
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage,
                    )
                    .map((beneficiary, index) => (
                      <Stack
                        key={index}
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        padding='1rem'
                        border='1px solid #ddd'
                        borderRadius='8px'
                      >
                        <Box>
                          <Typography variant='body1'>
                            {beneficiary.name}
                          </Typography>
                          <Typography variant='body2' color='textSecondary'>
                            CPF: {beneficiary.cpf}
                          </Typography>
                        </Box>
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() =>
                            console.log(
                              `Visualizar beneficiário: ${beneficiary.name}`,
                            )
                          }
                          sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                          }}
                        >
                          Visualizar
                        </Button>
                      </Stack>
                    ))}

                  {/* Pagination */}
                  <Stack
                    direction='row'
                    justifyContent='center'
                    spacing={2}
                    marginTop='1rem'
                  >
                    {[...Array(totalPages)].map((_, index) => (
                      <Button
                        key={index}
                        variant={
                          index + 1 === currentPage ? 'contained' : 'outlined'
                        }
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </>
      )}

      {/* Modal para Visualizar Arquivo */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '8px',
            p: 3,
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mb={2}
          >
            <Typography variant='h6'>Visualizar Documento</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          {selectedFile && (
            <Paper
              sx={{
                width: '100%',
                height: '500px',
                overflow: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <embed
                src={selectedFile.fileName}
                type='application/pdf'
                width='100%'
                height='100%'
              />
            </Paper>
          )}
        </Box>
      </Modal>
    </Stack>
  );
};

export default DataConfirmation;

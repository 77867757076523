import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CustomLabel from './custom_label';

interface CardMenuProps {
  title: string;
  description: string;
  pathTo: string;
  disabled?: boolean;
}

export const CardMenu = ({
  title,
  description,
  pathTo,
  disabled,
}: CardMenuProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Stack
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      onClick={!pathTo || disabled ? () => {} : () => navigate(pathTo)}
      style={{
        cursor: !pathTo || disabled ? 'default' : 'pointer',
      }}
    >
      <Stack>
        <CustomLabel
          text={title}
          labelStyle={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: '0.15px',
            lineHeight: '24px',
            textAlign: 'left',
          }}
        />

        <CustomLabel
          text={description}
          labelStyle={{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: '0.17px',
            lineHeight: '20.02px',
            textAlign: 'left',
            color: '#646464',
          }}
        />
      </Stack>

      <ChevronRightIcon />
    </Stack>
  );
};

import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Typography } from '@mui/material';

import { SideMenu } from 'components/shared';
import { useMenu } from 'contexts/menu/menu_context';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { useAuth } from 'contexts/auth/auth_context';

interface HeaderProps {
  title: string;
  showBackButton?: boolean;
  backButtonPath?: string;
}

const Header: React.FC<HeaderProps> = ({
  title,
  showBackButton = false,
  backButtonPath,
}) => {
  const { toggleMenu } = useMenu();
  const navigate = useNavigate();

  const { user } = useAuth();

  const handleBackClick = () => {
    if (backButtonPath) {
      navigate(backButtonPath);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {user && <SideMenu />}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#0048B6',
          p: 1,
        }}
      >
        {showBackButton && (
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon sx={{ color: '#FFFFFF' }} />
          </IconButton>
        )}
        <Typography
          variant='h6'
          sx={{ color: '#FFFFFF', flexGrow: 1, fontWeight: 0 }}
          textAlign='center'
        >
          {title}
        </Typography>

        {user && (
          <IconButton onClick={toggleMenu}>
            <MenuIcon sx={{ color: '#FFFFFF' }} />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default Header;

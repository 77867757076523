//TODO: Corrigir nomeclatura deste arquivo.
export interface User {
  uuid: string;
  name: string;
  cpf: string;
  email: string;
  hasAcceptedTerms: boolean;
  status: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
}

export interface PartialUpdateUserData {
  name?: string;
  cpf?: string;
  email?: string;
  contactList?: {
    uuid?: string;
    type: 'CELULAR';
    value: string;
  }[];
  position?: string;
  occupation?: string;
  groupUuid?: string;
  profileUuid?: string;
  permissionUuid?: string;
  representativeFileUuid?: string;
  password?: string;
  statusUuidToAddList?: (string | undefined)[];
  statusUuidToDelList?: (string | undefined)[];
  hasAcceptedTerms?: boolean;
  status?: UserStatus;
  address?: {
    municipality?: string;
  };
  isRegisterActive?: boolean;
  isRegisterBlocked?: boolean;
  dadosVigencia?: {
    vigenciaIndeterminada: boolean;
    dataInicial: string;
    dataFinal: string | null;
    isActive: boolean;
  } | null;
}

export interface UpdateVigencia {
  userUuid: string;
  dataInicial: string;
  dataFinal: string;
  vigenciaIndeterminada: boolean;
  isActive: boolean;
}

export enum UserStatus {
  REGISTER_ACTIVE = 'ATIVO',
  REGISTER_BLOCKED = 'REGISTRO BLOQUEADO',
  REGISTER_ENABLED = 'INATIVO',
}

export interface UserListQuery {
  nome?: string;
  cpf?: string;
  groupUuid?: string;
  profileUuid?: string;
  permissionUuid?: string;
  status?: string;
  page?: string;
  limit: string;
  sort?: string;
  uuid?: string;
}

import { PermissionsI } from 'api/common.types';
import { PaginationResponseDto } from 'api/common/dtos/pagination/pagination.response.dto';
import axios from 'axios';
import { StatusDto } from 'common/dtos/status.dto';
import { IState } from 'pages/institutions/form/types.form';
import { GetAllInstitutionQuery } from './dtos/get-all-institution.query.dto';
import { GetAllInstitutionResponse } from './dtos/get-all-institution.response.dto';
import { GetByIdInstitutionResponseDto } from './dtos/get-by-id-institution.response.dto';
import { UpdateByIdInstitutionBodyDto } from './dtos/update-by-id-institution.body.dto';
import { authService } from 'services/auth/auth_service';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/institutions';

const eseiafToken = authService.getTokenAPIInterna();

export const getAllInstitution = async (
  filters?: Partial<GetAllInstitutionQuery>,
): Promise<PaginationResponseDto<GetAllInstitutionResponse[]>> => {
  const response = await axios.get(API_URL, {
    params: {
      ...filters,
    },
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

export const getAllInstitutionFirstAccess = async (): Promise<any> => {
  const response = await axios.get(`${API_URL}/all-names-and-identifications`);
  return response.data;
};

export const getByIdInstitution = async (
  uuid: string,
): Promise<GetByIdInstitutionResponseDto> => {
  const response = await axios.get(`${API_URL}/${uuid}`);
  return response.data;
};

export const getAllStatus = async (): Promise<StatusDto[]> => {
  const response = await axios.get(`${API_URL}/status`);
  return response.data;
};

export const updateByIdInstitution = async (
  uuid: string,
  data: Pick<IState, 'institution' | 'toAddStatusList' | 'toDelStatusList'>,
): Promise<void> => {
  const body: UpdateByIdInstitutionBodyDto = {
    fantasyName: data.institution.fantasyName,
    caf: data.institution.caf,
    identification: data.institution.identification,
    name: data.institution.name,
    email: data.institution.email,
    address: {
      uuid: data.institution.address.uuid,
      cep: data.institution.address.zipcode,
      city: data.institution.address.municipality,
      district: data.institution.address.district,
      street: data.institution.address.street,
      number: data.institution.address.number,
      complement: data.institution.address.complement,
    },
    contactList: data.institution.contactList,
    groupUuid: data.institution.group.uuid,
    profileUuid: data.institution.profile.uuid,
    statusUuidToAddList: data.toAddStatusList.map((item: any) => item.uuid),
    statusUuidToDeleteList: data.toDelStatusList.map((item: any) => item.uuid),
  };

  await axios.put(`${API_URL}/${uuid}`, { ...body });
};

export const getInstitution = async (): Promise<PermissionsI> => {
  const response = await axios.get(
    `${BASE_API_URL}/institutions?page=1&take=10&sortBy=created_at&orderBy=DESC`,
    {
      headers: {
        Authorization: `Bearer ${eseiafToken}`,
      },
    },
  );

  return response.data;
};

export const getPrefectureWithoutAgreementTerm = async (): Promise<any> => {
  const response = await axios.get(
    `${API_URL}/city-hall-without-validity-agreement-term/dropdown`,
    {
      headers: {
        Authorization: `Bearer ${eseiafToken}`,
      },
    },
  );

  return response.data;
};

export const getInstitutionByCnpj = async (cnpj: string): Promise<any> => {
  const response = await axios.get(
    `${API_URL}/get-institution-by-cnpj/${cnpj}`,
    {
      headers: {
        Authorization: `Bearer ${eseiafToken}`,
      },
    },
  );
  return response.data;
};

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { ContainerForm } from 'components/shared/container_form';
import { PageTitle } from 'components/shared/page_title';
import React from 'react';
import useStep1 from './use_step1';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
  question5: string | null;
};

interface Step1Props {
  onNext: (answers: AnswersType) => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const {
    selectedProfile,
    formData,
    permissions,
    handleNext,
    handleChange,
    handleFileChange,
    handleRemoveFile,
    maskCpf,
    municipalitiesDropdown,
  } = useStep1({ onNext });

  return (
    <>
      <PageTitle
        title='Dados principais'
        subtitle='Informe corretamente os dados principais do usuário cadastrado.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        {selectedProfile !== 'agricultor' && (
          <ContainerForm title='Dados da Instituição'>
            <Stack spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Nome Fantasia
                  </Typography>
                  <Typography variant='body1'>
                    {formData?.dadosInstituicao?.fantasyName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle2' color='textSecondary'>
                    CNPJ
                  </Typography>
                  <Typography variant='body1'>
                    {formData?.dadosInstituicao?.identification}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Razão Social
                  </Typography>
                  <Typography variant='body1'>
                    {formData?.dadosInstituicao?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Município
                  </Typography>
                  <Typography variant='body1'>Cuiabá</Typography>
                </Grid>
              </Grid>
            </Stack>
          </ContainerForm>
        )}

        <ContainerForm title='Dados Pessoais'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Nome Completo'
                variant='outlined'
                name='nomeCompleto'
                value={formData.dadosPessoais.nomeCompleto}
                onChange={(e) =>
                  handleChange('dadosPessoais', 'nomeCompleto', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='CPF'
                variant='outlined'
                name='cpf'
                value={formData.dadosPessoais.cpf}
                onChange={(e) =>
                  handleChange('dadosPessoais', 'cpf', maskCpf(e.target.value))
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 14 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Email'
                variant='outlined'
                name='email'
                value={formData.dadosPessoais.email}
                onChange={(e) =>
                  handleChange('dadosPessoais', 'email', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel>Município</InputLabel>
                <Select
                  name='municipio'
                  value={formData.dadosPessoais.municipio}
                  onChange={(e) =>
                    handleChange('dadosPessoais', 'municipio', e.target.value)
                  }
                  label='Município'
                >
                  {municipalitiesDropdown.map((mun, index) => (
                    <MenuItem key={index} value={mun.name}>
                      {mun.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Cargo'
                variant='outlined'
                name='cargo'
                value={formData.dadosPessoais.cargo}
                onChange={(e) =>
                  handleChange('dadosPessoais', 'cargo', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Função'
                variant='outlined'
                name='funcao'
                value={formData.dadosPessoais.funcao}
                onChange={(e) =>
                  handleChange('dadosPessoais', 'funcao', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Telefone 1'
                variant='outlined'
                name='telefone1'
                value={formData.dadosPessoais.telefone1}
                onChange={(e) =>
                  handleChange('dadosPessoais', 'telefone1', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Telefone 2'
                variant='outlined'
                name='telefone2'
                value={formData.dadosPessoais.telefone2}
                onChange={(e) =>
                  handleChange('dadosPessoais', 'telefone2', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
          </Grid>
        </ContainerForm>

        <ContainerForm title='Dados de Login'>
          <Stack spacing={2}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel>Permissão de Usuário</InputLabel>
              <Select
                value={formData.dadosLogin.role}
                onChange={(e) =>
                  handleChange('dadosLogin', 'role', e.target.value)
                }
                label='Permissão do usuário'
              >
                {permissions?.map((per, index) => (
                  <MenuItem key={index} value={per.uuid}>
                    {per.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label='Senha'
              type='password'
              variant='outlined'
              value={formData.dadosLogin.senha}
              onChange={(e) =>
                handleChange('dadosLogin', 'senha', e.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              label='Repita a Senha'
              type='password'
              variant='outlined'
              value={formData.dadosLogin.confirmarSenha}
              onChange={(e) =>
                handleChange('dadosLogin', 'confirmarSenha', e.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </ContainerForm>

        <ContainerForm title='Dados da Vigência (Opcional no caso de Agroindústria com CNPJ não informado)'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Data Inicial de Vigência'
                type='date'
                variant='outlined'
                value={formData.dadosVigencia.dataInicial}
                onChange={(e) =>
                  handleChange('dadosVigencia', 'dataInicial', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Data Final de Vigência'
                type='date'
                variant='outlined'
                value={formData.dadosVigencia.dataFinal}
                onChange={(e) =>
                  handleChange('dadosVigencia', 'dataFinal', e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                disabled={formData.dadosVigencia.vigenciaIndeterminada}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.dadosVigencia.vigenciaIndeterminada}
                    onChange={(e) =>
                      handleChange(
                        'dadosVigencia',
                        'vigenciaIndeterminada',
                        e.target.checked,
                      )
                    }
                  />
                }
                label='Data final indeterminada'
              />
            </Grid>
          </Grid>
        </ContainerForm>

        <ContainerForm
          title='Anexar Arquivo'
          subtitle='Anexar documento que comprove o vínculo do Representante Legal com a instituição ou no caso de Agricultor Familiar um documento oficial com foto.'
        >
          <Stack spacing={2} mt={2}>
            <Box sx={{ width: 'fit-content' }}>
              <Button
                variant='outlined'
                component='label'
                startIcon={<AttachFileIcon />}
              >
                ANEXAR ARQUIVO
                <input type='file' hidden onChange={handleFileChange} />
              </Button>
            </Box>

            <Typography variant='caption'>
              Imagens em PNG, JPG (máximo de 4.8 MB) ou PDF (máximo de 20MB)
            </Typography>

            {formData.anexoArquivo && (
              <>
                {console.log(
                  'Arquivo armazenado no formData:',
                  formData.anexoArquivo,
                )}
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    padding: '8px',
                    borderRadius: '4px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: '#f9f9f9',
                  }}
                >
                  <Stack>
                    <Typography variant='body2'>
                      {formData.anexoArquivo.name}
                    </Typography>
                    <Typography variant='caption' color='textSecondary'>
                      {formData.anexoArquivo?.type?.includes('image')
                        ? 'Imagem'
                        : 'Documento'}{' '}
                      {formData.anexoArquivo?.type
                        ?.split('/')[1]
                        ?.toUpperCase()}
                      ,{(formData.anexoArquivo?.size ?? 0 / 1024).toFixed(1)} KB
                    </Typography>
                  </Stack>
                  <Box display='flex' alignItems='center'>
                    <IconButton onClick={handleRemoveFile} size='small'>
                      <CloseIcon />
                    </IconButton>
                    <Box
                      sx={{
                        bgcolor: 'green',
                        color: 'white',
                        borderRadius: '0 4px 4px 0',
                        padding: '8px',
                      }}
                    >
                      <CheckCircleIcon fontSize='small' />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Stack>
        </ContainerForm>

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary'>
            Voltar
          </Button>
          <Button variant='contained' color='primary' onClick={handleNext}>
            Avançar
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Step1;

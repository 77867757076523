import axios from 'axios';
import { PaginationResponseDto } from 'api/common/dtos/pagination/pagination.response.dto';
import { authService } from 'services/auth/auth_service';
import {
  ListAgreementTermQuery,
  ListAgreementTermResponse,
  GetAgreementTermResponse,
  CreateAgreementTermResponse,
} from './api.types';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = `${BASE_API_URL}/institutions/agreement-term`;

const eseiafToken = authService.getTokenAPIInterna();

export const createAgreementTerm = async (
  data: CreateAgreementTermResponse,
): Promise<void> => {
  await axios.post(API_URL, data, {
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getAllAgreementTerms = async (
  filters: Partial<ListAgreementTermQuery>,
): Promise<PaginationResponseDto<ListAgreementTermResponse[]>> => {
  const response = await axios.get(API_URL, {
    params: {
      ...filters,
    },
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

export const getAgreementTermById = async (
  uuid: string,
): Promise<GetAgreementTermResponse> => {
  const response = await axios.get(`${API_URL}/${uuid}`, {
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

export const getAgreementTermStatus = async (): Promise<string[]> => {
  const response = await axios.get(`${API_URL}/status`, {
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

/* export const getAgreementTermMunicipality = async (
  uuid: string,
): Promise<any> => {
  const response = await axios.get(`${API_URL}/municipality/${uuid}`, {
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
}; */

export const getAgreementTermMunicipality = async (
  uuid: string,
): Promise<any> => {
  const response = await axios.get(
    `${API_URL}?page=1&take=10&sortBy=created_at&orderBy=DESC&withDeleted=true&institutionUuid=${uuid}`,
    {
      headers: {
        Authorization: `Bearer ${eseiafToken}`,
      },
    },
  );

  return response.data;
};

export const updateAgreementTerm = async (
  uuid: string,
  data: any,
): Promise<void> => {
  await axios.patch(`${API_URL}/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const signAgreementTerm = async (
  uuid: string,
  data: any,
): Promise<void> => {
  await axios.patch(`${API_URL}/${uuid}/sign`, data, {
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
      'Content-Type': 'application/json',
    },
  });
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useHeaderFilter = () => {
  const navigate = useNavigate();
  const [sortAnchor, setSortAnchor] = useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [filterCity, setFilterCity] = useState<string>('');
  const [filterAnchor, setFilterAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [cityFilter, setCityFilter] = useState<string>('');

  const handleSort = (
    criteria: 'city-asc' | 'city-desc' | 'date-new' | 'date-old',
  ) => {
    setSortBy(criteria);
  };

  const handleFilter = (status: string | null, city: string) => {
    setFilterStatus(status);
    setFilterCity(city);
  };

  return {
    navigate,
    sortAnchor,
    setSortAnchor,
    handleSort,
    sortBy,
    hasAppliedFilters: () => filterStatus !== null || filterCity !== '',
    setFilterAnchor,
    filterAnchor,
    cityFilter,
    setCityFilter,
    handleFilter,
  };
};

export default useHeaderFilter;

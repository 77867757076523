import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ContainerForm } from 'components/shared/container_form';
import { Stack } from '@mui/system';
import { PageTitle } from 'components/shared/page_title';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
};

interface Step1Props {
  onNext: (answers: AnswersType) => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const [question1, setQuestion1] = useState<string | null>(null);
  const [question2, setQuestion2] = useState<string | null>(null);
  const [question3, setQuestion3] = useState<string | null>(null);
  const [question4, setQuestion4] = useState<string | null>(null);

  const isAdvanceEnabled =
    question1 !== null &&
    question2 !== null &&
    question3 !== null &&
    question4 !== null;

  const handleNext = () => {
    onNext({
      question1,
      question2,
      question3,
      question4,
    });
  };

  return (
    <>
      <PageTitle
        title='Cumprimento do Termo'
        subtitle='Informe corretamente os dados sobre o cumprimento dos requisitos do Termo de Adesão pelo Município.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        <ContainerForm title='PMAF - Plano de Agricultura Familiar'>
          <FormControl>
            <FormLabel id='foreigner-label'>
              O Município elaborou o Plano Municipal de Agricultura Familiar -
              PMAF?
            </FormLabel>
            <RadioGroup
              aria-labelledby='foreigner-label'
              value={question1}
              onChange={(e) => setQuestion1(e.target.value)}
            >
              <FormControlLabel value='Não' control={<Radio />} label='Não' />
              <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel id='foreigner-label'>
              O PMAF foi validado pela SEAF MT?
            </FormLabel>
            <RadioGroup
              aria-labelledby='foreigner-label'
              value={question2}
              onChange={(e) => setQuestion2(e.target.value)}
            >
              <FormControlLabel value='Não' control={<Radio />} label='Não' />
              <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
            </RadioGroup>
          </FormControl>
        </ContainerForm>

        <ContainerForm title='CMDRS - Conselho Municipal de Desenvolvimento Rural Sustentável'>
          <FormControl>
            <FormLabel id='foreigner-label'>
              O Município possui o CMDRS constituído via Lei municipal?
            </FormLabel>
            <RadioGroup
              aria-labelledby='foreigner-label'
              value={question3}
              onChange={(e) => setQuestion3(e.target.value)}
            >
              <FormControlLabel value='Não' control={<Radio />} label='Não' />
              <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
            </RadioGroup>
          </FormControl>
        </ContainerForm>

        <ContainerForm title='Formulário Prefeitura'>
          <FormControl>
            <FormLabel id='foreigner-label'>
              O Município preencheu o formulário Prefeitura?
            </FormLabel>
            <RadioGroup
              aria-labelledby='foreigner-label'
              value={question4}
              onChange={(e) => setQuestion4(e.target.value)}
            >
              <FormControlLabel value='Não' control={<Radio />} label='Não' />
              <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
            </RadioGroup>
          </FormControl>
        </ContainerForm>

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary' disabled>
            Voltar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleNext}
            //disabled={!isAdvanceEnabled}
          >
            Avançar
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Step1;

import React, { useState } from 'react';
import { Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { TopNavBar } from 'components/top_nav_bar';
import MenuIcon from '@mui/icons-material/Menu';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PainelLogoImage from '../assets/images/home-logo-image.png';
import { Header, MainCard, UserInfo } from 'components/home';
import { MenuProvider } from 'contexts/menu/menu_context';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { CardServico } from 'components/home/card_servico';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { ContainerPainel } from '../components/container_painel';

const HomePage: React.FC = () => {
  const [value, setValue] = useState(0);

  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ContainerPainel>
      <Box
        sx={{
          backgroundColor: '#0048B6',
          height: '190px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: '#FFFFFF',
                  bottom: 5,
                },
              }}
            >
              <Tab
                icon={<LeaderboardIcon sx={{ color: '#FFFFFF' }} />}
                label='DASHBOARD'
                iconPosition='start'
                sx={{
                  minHeight: 5,

                  '&.Mui-selected': {
                    color: '#FFFFFF',
                  },
                }}
              />
            </Tabs>
            <UserInfo />
          </Box>

          <Box
            sx={{
              borderRadius: '16px',
              padding: '8px',
              backgroundColor: '#FFF',
              mt: 1,
            }}
            boxShadow='0px 1px 3px 0px #0000001F'
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={PainelLogoImage}
                alt='Sistema Estadual Integrado da Agricultura Familiar'
                width='60%'
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '20px',
                  fontWeight: 500,
                  lineHeight: '32px',
                  letterSpacing: '0.15px',
                  textAlign: 'center',
                }}
              >
                Gestão de Usuários
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20.02px',
                  letterSpacing: '0.17px',
                  textAlign: 'center',
                  color: '#00000099',
                }}
              >
                Crie, altere, exclua, e visualize todos os dados dos usuários do
                sistema.
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <MainCard
                  navigate={navigate}
                  onAccess={() => navigate(ROUTES.MENU_USER)}
                />
              </Box>
            </Box>
          </Box>

          <Stack mt='1rem'>
            <CardServico
              icone={<DescriptionOutlinedIcon />}
              titulo='Gestão de Serviços'
              descricao='Realize a criação e gerenciamento dos serviços disponíveis no sistema.'
              irPara={ROUTES.MANAGE_SERVICE}
            />
          </Stack>
        </Box>
      </Box>
    </ContainerPainel>
  );
};

export default HomePage;

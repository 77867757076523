import {
  getAllInstitution,
  getAllInstitutionFirstAccess,
  getInstitutionByCnpj,
} from 'api/institutions/institution.api';
import { ceil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

type InstitutionType = {
  fantasyName?: string;
  identification?: string;
  name?: string;
  uuid?: string;
};

const useFirstRegisterUser = () => {
  const navigate = useNavigate();
  const [selectedProfile, setSelectedProfile] = useState<string>('prefeitura');
  const [document, setDocument] = useState<string>('');
  const [documentType, setDocumentType] = useState<'cnpj' | 'cpf'>('cnpj');
  const [error, setError] = useState<string | null>(null);

  const handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const profile = event.target.value;
    setSelectedProfile(profile);
    setDocument('');
    setError(null);

    if (profile === 'agroindustria') {
      setDocumentType('cnpj');
    } else if (profile === 'agricultor') {
      setDocumentType('cpf');
    } else {
      setDocumentType('cnpj');
    }
  };

  const handleDocumentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDocumentType(event.target.value as 'cnpj' | 'cpf');
    setDocument('');
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/\D/g, '');

    if (documentType === 'cpf') {
      if (value.length > 3 && value.length <= 6) {
        value = value.replace(/(\d{3})(\d+)/, '$1.$2');
      } else if (value.length > 6 && value.length <= 9) {
        value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
      } else if (value.length > 9) {
        value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
      }
    } else {
      if (value.length > 2 && value.length <= 5) {
        value = value.replace(/(\d{2})(\d+)/, '$1.$2');
      } else if (value.length > 5 && value.length <= 8) {
        value = value.replace(/(\d{2})(\d{3})(\d+)/, '$1.$2.$3');
      } else if (value.length > 8 && value.length <= 12) {
        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d+)/, '$1.$2.$3/$4');
      } else if (value.length > 12) {
        value = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/,
          '$1.$2.$3/$4-$5',
        );
      }
    }

    setDocument(value);
  };

  const getDocumentLabel = () => {
    switch (selectedProfile) {
      case 'prefeitura':
      case 'associacao':
      case 'cooperativa':
        return 'CNPJ da Instituição';
      case 'agroindustria':
        return 'CPF ou CNPJ da Agroindústria';
      case 'agricultor':
        return 'CPF do Agricultor Familiar';
      default:
        return '';
    }
  };

  const handleRegisterUser = async () => {
    if (!selectedProfile || !document) {
      setError('Por favor, preencha todos os campos antes de continuar.');
      return;
    }

    let institution: any;
    try {
      const cleanDocument = document.replace(/\D/g, '');
      const response = await getInstitutionByCnpj(cleanDocument);

      institution = response;
    } catch (error) {
      setError('CNPJ não encontrado. Verifique os dados e tente novamente.');
      return;
    }

    const data = { selectedProfile, documentType, institution };
    localStorage.setItem('firstRegisterData', JSON.stringify(data));
    navigate(ROUTES.USER_CREATE);
  };

  return {
    handleProfileChange,
    handleDocumentTypeChange,
    handleDocumentChange,
    getDocumentLabel,
    handleRegisterUser,
    error,
    selectedProfile,
    document,
    documentType,
  };
};

export default useFirstRegisterUser;

export const ROUTES = {
  LOGIN: '/',
  LOGIN_VALIDATION: '/valida-login',
  TERMS_OF_USE_AND_CONFIDENTIALITY: '/termo',
  HOME: '/painel',
  MENU_USER: '/menu-usuario',
  MANAGE_USER: '/usuarios',
  USER_CREATE: '/usuarios/cadastrar',
  USER_EDIT: '/usuarios/editar/:uuid',
  USER_VIEW: '/usuarios/visualizar/:uuid',
  INSTITUTION: '/instituicoes',
  INSTITUTION_VIEW: '/instituicoes/visualizar/:uuid',
  INSTITUTION_EDIT: '/instituicoes/editar/:uuid',

  MANAGE_SERVICE: '/menu-servicos',
  MANAGE_CIFA: '/menu-cifas',
  CIFA: '/cifa',

  CIFA2025: '/cifa-2025',
  INCLUDE_CIFA_2025: '/cifa-2025/cadastrar/ater',
  VIEW_CIFA_2025: '/cifa-2025/visualizar/ater',
  EDIT_CIFA_2025: '/cifa-2025/editar/ater',
  CREATE_FEDERAL_SCHOOL_FEEDING_2025:
    '/cifa-2025/cadastrar/alimentacao-escolar-federal',
  VIEW_FEDERAL_SCHOOL_FEEDING_2025:
    '/cifa-2025/visualizar/alimentacao-escolar-federal',
  EDIT_FEDERAL_SCHOOL_FEEDING_2025:
    '/cifa-2025/editar/alimentacao-escolar-federal',
  CREATE_STATE_SCHOOL_FEEDING_2025:
    '/cifa-2025/cadastrar/alimentacao-escolar-estadual',
  VIEW_STATE_SCHOOL_FEEDING_2025:
    '/cifa-2025/visualizar/alimentacao-escolar-estadual',
  EDIT_STATE_SCHOOL_FEEDING_2025:
    '/cifa-2025/editar/alimentacao-escolar-estadual',
  CREATE_MUNICIPAL_SCHOOL_FEEDING_2025:
    '/cifa-2025/cadastrar/alimentacao-escolar-municipal',
  VIEW_MUNICIPAL_SCHOOL_FEEDING_2025:
    '/cifa-2025/visualizar/alimentacao-escolar-municipal',
  EDIT_MUNICIPAL_SCHOOL_FEEDING_2025:
    '/cifa-2025/editar/alimentacao-escolar-municipal',

  CIFA2026: '/cifa-2026',
  INCLUDE_CIFA_2026: '/cifa-2026/cadastrar/ater',
  COMPLIANCE_TERM_2026: '/cifa-2026/cadastrar/compliance-term',
  TECH_ASSIST_2026: '/cifa-2026/cadastrar/tech-assist',

  FIRSTUSER: '/first-user',
  FIRSTUSERSTEPS: '/primeiro-usuario/cadastrar',

  TERMOFADHESION: '/term-of-adhesion',
  REGISTERTERMOFADHESION: '/register-term-of-adhesion',
  VIEWTERMSADHESION: '/view-terms-adhesion',
  VALIDATETERMSADHESION: '/validate-terms-adhesion',
  EDITTETERMSADHESION: '/edit-terms-adhesion',
};

import { Product } from 'pages/service_management/Cifa/2025/CifaCrud2025/FederalSchoolFeeding/CreateFederalSchoolFeeding2025';

export enum FormName {
  ASSISTENCIA_TECNICA_E_EXTENSAO_RURAL = 'ASSISTÊNCIA TÉCNICA E EXTENSÃO RURAL',
  ALIMENTACAO_ESCOLAR_FEDERAL = 'ALIMENTAÇÃO ESCOLAR FEDERAL',
  ALIMENTACAO_ESCOLAR_ESTADUAL = 'ALIMENTAÇÃO ESCOLAR ESTADUAL',
  ALIMENTACAO_ESCOLAR_MUNICIPAL = 'ALIMENTAÇÃO ESCOLAR MUNICIPAL',
}

export enum MunicipalPartnerType {
  EMPAER_MT = 'empaer-mt',
  EDUCATIONAL_INSTITUTION = 'educationalInstitution',
  ORGANIZATION_INSTITUTION = 'organizationInstitution',
  PRIVATE_INSTITUTION = 'privateInstitution',
}

export interface Document {
  uuid: string;
  name: string;
}

export interface MunicipalPartner {
  name: string;
  numberOfBeneficiary: number;
  partnershipDocument: Document;
  ataCmdrsDocument: Document;
}

interface TechnicalAssistanceRuralExtensionDto {
  aterExclusiveServiceMunicipality: number;
  aterServiceByMunicipalPartner: boolean;
  aterMunicipalPartnerList?: MunicipalPartner | any;
}

interface SchoolFeedingDto {
  valueProvidedByPnae: number;
  valueAppliedByPnaeResource: number;
  productAquiredFromCpfByPnaeResourceList?: Product[];
  productAquiredFromCnpjByPnaeResourceList?: Product[];
}

export interface PostAnswersPayload {
  userAccessUuid: string;
  institutionUuid: string;
  formName: FormName;
  answer: TechnicalAssistanceRuralExtensionDto | SchoolFeedingDto;
  referencedYear: number;
}

export interface GetAnswersResponse {
  data: Array<{
    uuid: string;
    formName: string;
    answer: {
      aterExclusiveServiceMunicipality: number;
      aterServiceByMunicipalPartner: boolean;
      aterMunicipalPartnerList: MunicipalPartner[];
    };
  }>;
  info: Record<string, any>;
}

export interface GetAnswersPayload {
  institutitonUuid: string;
  userAccessUuid: string;
  referencedYear: number;
}

export interface GetSchoolFeedingResponse {
  data: Array<{
    uuid: string;
    formName: string;
    answer: SchoolFeedingDto | TechnicalAssistanceRuralExtensionDto | any;
  }>;
  info: Record<string, any>;
}

import { Typography } from '@mui/material';

interface PageTitleProps {
  title: string;
  subtitle?: string;
}

import { FC } from 'react';
import CustomLabel from './custom_label';

export const PageTitle: FC<PageTitleProps> = ({ title, subtitle }) => (
  <>
    <Typography variant='h5' textAlign='center' marginY='1.5rem'>
      {title}
    </Typography>

    {subtitle && (
      <CustomLabel
        text={subtitle}
        labelStyle={{
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontWeight: 400,
          letterSpacing: '0.17px',
          lineHeight: '22.88px',
          textAlign: 'center',
        }}
      />
    )}
  </>
);

import { Box, Button, Stack, Typography } from '@mui/material';
import useStep2 from './use_step2';
import DataConfirmation from 'components/shared/data_confirmation';
import { formatDate } from 'utils/format_date';

type AnswersType = {
  question1: string | null;
  question2: string | null;
};

interface Step2Props {
  onNext: (answers: AnswersType) => void;
}

const Step2: React.FC<Step2Props> = ({ onNext }) => {
  const { formData, handleSave } = useStep2();

  return (
    <Stack marginX='4rem' spacing='2rem'>
      <DataConfirmation
        title='Dados do Termo de Adesão'
        data={[
          {
            label: 'Prefeitura',
            value: formData?.dadosTermosAdesao?.prefeituraNome,
          },
          {
            label: 'Data Assinatura',
            value: formatDate(formData?.dadosTermosAdesao?.dataAssinatura),
          },
          {
            label: 'Status',
            value: formData?.dadosTermosAdesao?.status ? 'Ativo' : 'Inativo',
          },
        ]}
      />
      <DataConfirmation
        title='Anexar Termo de Adesão'
        atas={[
          {
            titlePDF: 'Arquivo Enviado',
            label: formData?.anexoArquivo?.name,
            fileName: `${formData?.anexoArquivo?.type}`,
            fileSize: `${formData?.anexoArquivo?.size} KB`,
            onView: () => console.log('Visualizar documento'),
          },
        ]}
      />

      {/* Botões */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={2}
        marginTop='20px'
      >
        <Button
          variant='text'
          sx={{
            color: '#1976D2',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          Voltar
        </Button>

        <Button
          variant='contained'
          onClick={handleSave}
          sx={{
            backgroundColor: '#0048B6',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            '&:hover': {
              backgroundColor: '#003399',
            },
          }}
        >
          Confirmar e salvar
        </Button>
      </Box>
    </Stack>
  );
};

export default Step2;

import React, { useEffect } from 'react';

import { Divider } from '@mui/material';

import { Stack } from '@mui/system';
import { Header } from 'components/home';
import { CustomLabel } from 'components/shared';
import { CardMenu } from 'components/shared/card_menu';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import { ROUTES } from 'routes/constants';
import ContainerPainel from 'components/container_painel';
import { useAuth } from 'contexts/auth/auth_context';

const MenuCifa: React.FC = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (!(user && user.institution)) {
      alert(
        'Essa funcionalidade é restrita a usuários vinculados a uma instituição.',
      );
    }
  });

  return (
    <ContainerPainel pagina='Gerenciar CIFA' showBackButton={true}>
      <Stack mt='32px' paddingX='384px' spacing='1rem'>
        <CustomLabel
          text='Gerenciamento CIFA'
          labelStyle={{
            fontFamily: 'Roboto',
            fontSize: '22px',
            fontWeight: 500,
            letterSpacing: '0.15px',
            lineHeight: '35.2px',
            textAlign: 'left',
          }}
        />

        <Divider />

        <CardMenu
          title='Ano de 2025'
          description='Visualize as informações do questionário CIFA 2025.'
          pathTo={ROUTES.CIFA2025}
          disabled={!(user && user.institution)}
        />
        {/* <CardMenu
          title='Ano de 2026'
          description='Visualize as informações do questionário CIFA 2026.'
          pathTo={ROUTES.CIFA2026}
          disabled={!(user && user.institution)}
        /> */}
      </Stack>
    </ContainerPainel>
  );
};

export default MenuCifa;

import { signAgreementTerm } from 'api/agreement-term/agreement-term.api';
import { IUser } from 'modules/auth/interfaces/storage.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { authService } from 'services/auth/auth_service';

const useValidateTermAdhesion = ({ term }: any) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showRepresentativeData, setShowRepresentativeData] = useState(false);
  const [isSignModalOpen, setSignModalOpen] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedUser = authService.getUserDataLocalStorage();
    if (loggedUser) {
      setUser(loggedUser);
    } else {
      console.error('Usuário não encontrado no localStorage.');
    }
  }, []);

  const handleSave = async () => {
    try {
      /* if (user?.permission?.name === 'GERENTE MUNICIPAL') {
        await updateAgreementTerm(term?.uuid, {
          municipalRepresentative: {
            name: user?.name,
            identification: user?.identification,
            email: user?.email,
            occupation: user?.occupation,
            position: user?.position,
          },
        });
      }
      if (user?.permission?.name === 'GERENTE ESTADUAL') {
        await updateAgreementTerm(term?.uuid, {
          stateRepresentative: {
            name: user?.name,
            identification: user?.identification,
            email: user?.email,
            occupation: user?.occupation,
            position: user?.position,
          },
        });
      } */

      setIsModalOpen(true);
    } catch (error) {
      console.error('Erro ao atualizar', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(ROUTES.TERMOFADHESION);
  };

  const handleOpenSignModal = () => {
    setSignModalOpen(true);
  };

  const handleCloseSignModal = () => {
    setSignModalOpen(false);
  };

  const handleConfirmSign = async (password: string) => {
    await signAgreementTerm(term?.uuid, {
      password: password,
    });
    console.log('Senha digitada:', password);
    setSignModalOpen(false);
    //handleSave();
  };

  const handleOpenRepresentativeData = () => {
    setShowRepresentativeData(true);
  };

  return {
    isModalOpen,
    showRepresentativeData,
    handleOpenRepresentativeData,
    handleSave,
    handleCloseModal,
    handleOpenSignModal,
    handleCloseSignModal,
    handleConfirmSign,
    isSignModalOpen,
    user,
  };
};

export default useValidateTermAdhesion;

import React, { useEffect, useState } from 'react';
import { AuthStatus, useAuth } from './auth_context';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { CircularProgress } from '@mui/material';
import { ErrorDialog } from 'components/shared';

interface ProtectedRouteProps {
  element: JSX.Element;
}

interface DialogButtonConfig {
  text: string;
  action: () => void;
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
}

interface ErrorDialogState {
  open: boolean;
  title: string;
  message1: string;
  message2?: string | null;
  button1: DialogButtonConfig;
  button2?: DialogButtonConfig | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { isAuthenticated, isSessionValidating, checkAuthStatus, logout } =
    useAuth();

  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogState>({
    open: false,
    title: '',
    message1: '',
    button1: {
      text: '',
      action: () => {},
      color: 'inherit',
    },
    button2: null,
  });

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  // useEffect(() => {
  //   const validateAuth = async () => {
  //     const result = await checkAuthStatus();

  //     if (!result.success) {
  //       setShowErrorDialog(true);
  //       switch (result.status) {
  //         case AuthStatus.sessionExpired:
  //           setErrorDialogState({
  //             open: true,
  //             title: 'Sua sessão expirou',
  //             message1:
  //               'Por segurança a sessão expirou. Você deve voltar para a tela inicial e entrar novamente.',
  //             button1: {
  //               text: 'FECHAR',
  //               action() {
  //                 logout();
  //               },
  //               color: 'primary',
  //             },
  //           });
  //           break;
  //         case AuthStatus.userBlocked:
  //           setErrorDialogState({
  //             open: true,
  //             title: 'Usuário Bloqueado',
  //             message1: 'O acesso do Usuário informado encontra-se bloqueado.',
  //             message2: `Entre em contato com a Administração do sistema para realizar o desbloqueio do Usuário.`,
  //             button1: {
  //               text: 'Voltar',
  //               action() {
  //                 logout();
  //               },
  //               color: 'primary',
  //             },
  //           });
  //           break;
  //         case AuthStatus.userInactive:
  //           setErrorDialogState({
  //             open: true,
  //             title: 'Usuário Inativo',
  //             message1: 'O acesso do Usuário informado não está ativo.',
  //             message2: `Entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário.`,
  //             button1: {
  //               text: 'Voltar',
  //               action() {
  //                 logout();
  //               },
  //               color: 'primary',
  //             },
  //           });
  //           break;
  //         case AuthStatus.userNotFound:
  //           setErrorDialogState({
  //             open: true,
  //             title: 'Usuário Não Encontrado',
  //             message1: 'O acesso do Usuário informado não foi localizado.',
  //             message2: `Entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário.`,
  //             button1: {
  //               text: 'Voltar',
  //               action() {
  //                 logout();
  //               },
  //               color: 'primary',
  //             },
  //           });
  //           break;
  //         default:
  //           setErrorDialogState({
  //             open: true,
  //             title: 'Erro inesperado',
  //             message1: 'Deseja atualizar a página atual?',
  //             button1: {
  //               text: 'Fechar',
  //               action() {
  //                 logout();
  //               },
  //               color: 'inherit',
  //             },
  //             button2: {
  //               text: 'Atualizar',
  //               action() {
  //                 handleRefresh();
  //               },
  //               color: 'primary',
  //             },
  //           });
  //       }
  //     }
  //   };

  //   validateAuth();
  // }, [checkAuthStatus, logout]);

  if (isSessionValidating) {
    return <CircularProgress />;
  }

  if (!isAuthenticated && !showErrorDialog) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      {element}

      <ErrorDialog
        open={errorDialogState.open}
        title={errorDialogState.title}
        message1={errorDialogState.message1}
        {...(errorDialogState.message2 && {
          message2: errorDialogState.message2,
        })}
        button1Text={errorDialogState.button1.text}
        button1Action={errorDialogState.button1.action}
        button1Color={errorDialogState.button1.color}
        {...(errorDialogState.button2 && {
          button2Text: errorDialogState.button2.text,
          button2Action: errorDialogState.button2.action,
          button2Color: errorDialogState.button2.color,
        })}
      />
    </>
  );
};

export default ProtectedRoute;

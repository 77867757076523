import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Paper,
  Typography,
  CircularProgress,
  Pagination,
} from '@mui/material';
import ContainerPainel from 'components/container_painel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import useTermOfAdhesion from './use_term_of_adhesion';
import { ROUTES } from 'routes/constants';
import HeaderFilter from 'components/header_filter';
import { formatDate } from 'utils/format_date';

const TermOfAdhesion: React.FC = () => {
  const {
    navigate,
    selectedUsers,
    selectAllChecked,
    terms,
    loading,
    handleSelectAll,
    handleSelectTerm,
    anchorEl,
    open,
    handleClick,
    handleClose,
    handleDownloadSelected,
    handleDownloadTerm,
    take,
    page,
    setPage,
    totalCount,
  } = useTermOfAdhesion();

  return (
    <ContainerPainel
      pagina='Gerenciar Termos de Adesão Municipal'
      showBackButton={true}
    >
      {/* Barra Superior */}
      <HeaderFilter />

      {/* Seleção */}
      <Box sx={{ backgroundColor: '#0048B6', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingX: '40px',
            maxWidth: '1200px',
            margin: '0 auto',
            height: '50px',
          }}
        >
          <Checkbox
            sx={{ color: '#FFFFFF' }}
            checked={selectAllChecked}
            onChange={handleSelectAll}
          />
          <Typography
            sx={{ color: '#FFFFFF', fontSize: '18px', marginRight: '18px' }}
          >
            Selecionar todos | {selectedUsers.length} de {terms.length}{' '}
            selecionado(s)
          </Typography>
          {/* Botão de Download - Só aparece se tiver pelo menos 1 item selecionado */}
          {selectedUsers.length > 0 && (
            <Button
              variant='contained'
              startIcon={<DownloadIcon />}
              onClick={() => handleDownloadSelected()}
              sx={{
                backgroundColor: 'transparent',
                color: '#FFFFFF',
                border: '1px solid #FFFFFF',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                '&:hover': {
                  backgroundColor: '#E3F2FD',
                  color: '#0048B6',
                },
              }}
            >
              Baixar Termo(s) Selecionado(s)
            </Button>
          )}
        </Box>
      </Box>

      {/* Lista de Termos */}
      <Box sx={{ padding: '20px 40px', maxWidth: '1200px', margin: '0 auto' }}>
        {loading ? (
          <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
        ) : terms.length === 0 ? (
          <Typography sx={{ textAlign: 'center', marginTop: '20px' }}>
            Nenhum termo encontrado.
          </Typography>
        ) : (
          terms.map((term) => (
            <Paper
              key={term.uuid}
              sx={{
                borderRadius: '8px',
                overflow: 'hidden',
                marginBottom: '12px',
              }}
            >
              {/* Conteúdo Principal */}
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <Checkbox
                    checked={selectedUsers.includes(term.uuid)}
                    onChange={() => handleSelectTerm(term.uuid)}
                  />
                  <Box>
                    <Typography variant='h6' fontWeight='bold'>
                      {term.institution.name}
                    </Typography>
                    <Typography fontSize='14px' color='#666'>
                      Data da Assinatura: {formatDate(term.signatureAt)}
                    </Typography>
                    <Typography fontSize='14px' color='#666'>
                      Data do Vencimento: {formatDate(term.dueAt)}
                    </Typography>
                  </Box>
                </Box>

                {/* Botão de ação */}
                <Button
                  variant='outlined'
                  onClick={handleClick}
                  endIcon={<ArrowDropDownIcon />}
                  sx={{
                    height: '36px',
                    minWidth: '120px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    color: '#1976D2',
                    border: '1px solid #1976D2',
                    textTransform: 'uppercase',
                    '&:hover': {
                      backgroundColor: '#E3F2FD',
                      border: '1px solid #1565C0',
                    },
                  }}
                >
                  AÇÕES
                </Button>

                {/* Menu Dropdown */}
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 3,
                    sx: {
                      borderRadius: '8px',
                      minWidth: '200px',
                      marginTop: '6px',
                    },
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      navigate(ROUTES.VIEWTERMSADHESION, { state: term })
                    }
                  >
                    Visualizar
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(ROUTES.EDITTETERMSADHESION, { state: term })
                    }
                  >
                    Editar
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(ROUTES.VALIDATETERMSADHESION, { state: term })
                    }
                  >
                    Validar
                  </MenuItem>
                  <MenuItem onClick={() => handleDownloadTerm(term)}>
                    Baixar Termo de Adesão
                  </MenuItem>
                </Menu>
              </Box>

              {/* Status do Termo */}
              <Box
                sx={{
                  backgroundColor: `${
                    term.status === 'ATIVO'
                      ? '#EAF2EA'
                      : term.status === 'INATIVO'
                      ? '#D32F2F0A'
                      : '#ED6C020A'
                  }`,
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: `${
                    term.status === 'ATIVO'
                      ? '#123214'
                      : term.status === 'INATIVO'
                      ? '#D32F2F'
                      : '#ED6C02'
                  }`,
                }}
              >
                {term.status === 'Ativo' && '✔️'}
                {term.status === 'Bloqueado' && '⚠️'}
                {term.status === 'Inativo' && '❗'}
                <Typography fontWeight='400'>{`Situação: ${term.status}`}</Typography>
              </Box>
            </Paper>
          ))
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 4 }}>
        <Pagination
          count={Math.ceil(totalCount / take)}
          page={page}
          color='primary'
          onChange={(_, newPage) => setPage(newPage)}
        />
      </Box>
    </ContainerPainel>
  );
};

export default TermOfAdhesion;

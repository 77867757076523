import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { InstitutionStatusEnum } from 'common/enums';

interface InstitutionCardStatusProps {
  statusList: boolean;
}

const InstitutionCardStatus: React.FC<InstitutionCardStatusProps> = ({
  statusList,
}) => {
  const status = statusList
    ? {
        icon: (
          <CheckCircleOutlineIcon sx={{ color: '#2E7D32', fontSize: '20px' }} />
        ),
        text: 'Situação: Ativo',
        backgroundColor: '#EAF2EA',
      }
    : {
        icon: (
          <ErrorOutlineOutlinedIcon
            sx={{ color: '#D56565', fontSize: '20px' }}
          />
        ),
        text: 'Situação: Inativo',
        backgroundColor: '#FDF7F7',
      };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: status.backgroundColor,
        padding: '8px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {status.icon}
      <Typography
        sx={{
          color: '#00000099',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '26.56px',
          letterSpacing: '0.4px',
          paddingLeft: '8px',
        }}
      >
        {status.text}
      </Typography>
    </Box>
  );
};

export default InstitutionCardStatus;

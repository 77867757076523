import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/loading.json';
import React from 'react';

const Loading: React.FC = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      height='100vh'
      bgcolor='#f5f5f5'
    >
      <Box width={200} height={200} mb={4}>
        <Lottie animationData={loadingAnimation} loop={true} />
      </Box>
      <Typography variant='body1'>
        Estamos coletando seus dados para realizar o acesso ao aplicativo
      </Typography>
      <Typography variant='h1'>Aguarde um momento!</Typography>
    </Box>
  );
};

export default Loading;

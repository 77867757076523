import { getAgreementTermMunicipality } from 'api/agreement-term/agreement-term.api';
import { ListAgreementTermResponse } from 'api/agreement-term/api.types';
import { useAuth } from 'contexts/auth/auth_context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface LocationState {
  term?: ListAgreementTermResponse;
}

const useViewTermsAdhesion = ({ term }: LocationState) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<ListAgreementTermResponse[]>([]);
  const uuidMuni = term?.institution.uuid;

  useEffect(() => {
    fetchMunicipality();
  }, []);

  const fetchMunicipality = async () => {
    setLoading(true);

    if (!uuidMuni) {
      throw new Error('Erro no uuid');
    }

    try {
      const response = await getAgreementTermMunicipality(uuidMuni);

      setTerms(response?.data);
    } catch (error) {
      console.error('Erro ao buscar termos de adesão:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    navigate,
    terms,
  };
};

export default useViewTermsAdhesion;

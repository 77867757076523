import { getAllAgreementTerms } from 'api/agreement-term/agreement-term.api';
import { ListAgreementTermResponse } from 'api/agreement-term/api.types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useTermOfAdhesion = () => {
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [terms, setTerms] = useState<ListAgreementTermResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [filterCity, setFilterCity] = useState<string>('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [orderBy, setOrderBy] = useState<'ASC' | 'DESC'>('DESC');
  const [withDeleted, setWithDeleted] = useState(false);
  const [signatureAt, setSignatureAt] = useState<string>(
    '2001-01-01T00:00:00.000Z',
  );
  const [totalCount, setTotalCount] = useState(0);
  const [dueAt, setDueAt] = useState<string>('2001-01-01T00:00:00.000Z');

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchTerms();
  }, [
    filterStatus,
    filterCity,
    orderBy,
    page,
    take,
    withDeleted,
    //signatureAt,
    //dueAt,
  ]);

  const fetchTerms = async () => {
    setLoading(true);
    try {
      const response = await getAllAgreementTerms({
        page: String(page),
        take: String(take),
        sortBy: 'created_at',
        orderBy,
        withDeleted,
        //signatureAt,
        //dueAt,
      });

      setTerms(response.data);
      setTotalCount(response.info.count);
    } catch (error) {
      console.error('Erro ao buscar termos de adesão:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectTerm = (id: string) => {
    let updatedSelectedUsers = [...selectedUsers];

    if (updatedSelectedUsers.includes(id)) {
      updatedSelectedUsers = updatedSelectedUsers.filter(
        (userId) => userId !== id,
      );
    } else {
      updatedSelectedUsers.push(id);
    }

    setSelectedUsers(updatedSelectedUsers);
    setSelectAllChecked(updatedSelectedUsers.length === terms.length);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedUsers(terms.map((term) => term.uuid));
      setSelectAllChecked(true);
    } else {
      setSelectedUsers([]);
      setSelectAllChecked(false);
    }
  };

  const handleSelect = (uuid: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid],
    );
  };

  const handleDownloadSelected = () => {
    selectedUsers.forEach((uuid) => {
      const term = terms.find((t) => t.uuid === uuid);
      if (term?.fileId?.base64) {
        const link = document.createElement('a');
        link.href = term.fileId.base64;
        link.download = term.fileId.name || 'termo_de_adesao.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const handleDownloadTerm = (term: any) => {
    if (term.fileId && term.fileId.base64) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${term.fileId.base64}`;
      link.download = term.fileId.name || 'termo_adesao.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Arquivo não disponível para download.');
    }
  };

  return {
    navigate,
    selectedUsers,
    selectAllChecked,
    terms,
    loading,
    filterStatus,
    filterCity,
    sortBy,
    handleSelectTerm,
    handleSelectAll,
    handleSelect,
    handleDownloadSelected,
    handleDownloadTerm,
    anchorEl,
    open,
    handleClick,
    handleClose,
    take,
    page,
    setPage,
    totalCount,
  };
};

export default useTermOfAdhesion;

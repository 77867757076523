import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { institutionCardStyles } from '.';
import InstitutionCardStatus from './institution_card_status';
import InstitutionCardActions from './institution_card_actions';
import { formatCNPJ } from 'services/utils/cnpj.utils';

interface InstitutionCardProps {
  institution: any;
  isChecked: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlockUser: () => void;
  blockDisabled: boolean;
}

const InstitutionCard: React.FC<InstitutionCardProps> = ({
  institution,
  isChecked,
  onCheckboxChange,
}) => {
  return (
    <Box sx={institutionCardStyles.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Checkbox checked={isChecked} onChange={onCheckboxChange} />
        </Box>

        <Box sx={institutionCardStyles.details}>
          <Typography
            sx={{
              color: '#000000DE',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '28.26px',
              letterSpacing: '0.1px',
            }}
          >
            {institution?.fantasyName}
          </Typography>
          <Typography
            sx={{
              color: '#00000099',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '26.56px',
              letterSpacing: '0.4px',
            }}
          >
            {`CNPJ: ${formatCNPJ(institution?.identification)}`}
          </Typography>
          <Typography
            sx={{
              color: '#00000099',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '26.56px',
              letterSpacing: '0.4px',
            }}
          >
            {`Município: ${institution?.address?.municipality}`}
          </Typography>
          <Typography
            sx={{
              color: '#00000099',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '26.56px',
              letterSpacing: '0.4px',
            }}
          >
            {`Razão Social: ${institution?.name}`}
          </Typography>
          <Typography
            sx={{
              color: '#00000099',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '26.56px',
              letterSpacing: '0.4px',
            }}
          >
            {`Perfil: ${institution?.profile?.name}`}
          </Typography>
        </Box>
        <InstitutionCardActions uuid={institution?.uuid} />
      </Box>

      <InstitutionCardStatus statusList={institution?.isRegisterActive} />
    </Box>
  );
};

export default InstitutionCard;

import { updateAgreementTerm } from 'api/agreement-term/agreement-term.api';
import { uploadFile } from 'api/file/file.api';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { FormDataType } from '../use_edit_term_adhesion';

const useStep2 = (formData: FormDataType) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const base64ToFile = (
    base64: string,
    fileName: string,
    fileType: string,
  ): File => {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new File([intArray], fileName, { type: fileType });
  };

  const handleSave = async () => {
    try {
      let fileUuid = formData.anexoArquivo?.fileUuid || '';

      if (formData.anexoArquivo && formData.anexoArquivo.base64 && !fileUuid) {
        const file = base64ToFile(
          formData.anexoArquivo.base64,
          formData.anexoArquivo.name,
          formData.anexoArquivo.type,
        );

        try {
          const fileResponse = await uploadFile(
            'TERMO DE ADESAO MUNICIPAL',
            file,
          );
          fileUuid = fileResponse?.uuid;
        } catch (error) {
          console.error('Erro ao enviar arquivo:', error);
          return;
        }
      }

      const payload = {
        institutionUuid: formData.dadosTermosAdesao.prefeitura,
        signatureAt: formData.dadosTermosAdesao.dataAssinatura,
        // isActive: formData.dadosTermosAdesao.status,
        fileUuid: fileUuid || '',
      };

      await updateAgreementTerm(formData.dadosTermosAdesao.uuid, payload);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Erro ao cadastrar termo de adesão:', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(ROUTES.TERMOFADHESION);
  };

  return { handleSave, handleCloseModal, isModalOpen };
};

export default useStep2;

//TODO:Corrigir nomeclatura deste arquivo.
export enum UserStatusEnum {
  ACTIVE = 'ATIVO',
  INACTIVE = 'INATIVO',
  REGISTER_ENABLED = 'REGISTRO LIBERADO',
  REGISTER_BLOCKED = 'REGISTRO BLOQUEADO',
}

export enum ValidityStatusEnum {
  ACTIVE = 'ATIVO',
  INACTIVE = 'INATIVO',
}


export enum InstitutionStatusEnum {
  REGISTER_BLOCKED = 'REGISTER_BLOCKED',
  REGISTER_ENABLED = 'REGISTER_ENABLED',
}

export enum InstitutionContactTypeEnum {
  CELLPHONE = 'CELLPHONE',
}

export const municipios = [
  'Cuiabá',
  'Várzea Grande',
  'Rondonópolis',
  'Sinop',
  'Tangará da Serra',
  'Lucas do Rio Verde',
  'Barra do Garças',
];

export const permissoes = [
  'Administrador',
  'Representante Legal Estadual',
  'Gerente Estadual',
  'Operador Estadual',
  'Conselheiro Estadual',
];

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Box,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HiddenPasswordIcon from '../../assets/images/hidden_password.png';

const SignPasswordModal = ({ open, onClose, onConfirm }: any) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}
        >
          <img
            src={HiddenPasswordIcon}
            alt='Ícone de Senha'
            style={{ width: 80, height: 80 }}
          />
        </Box>
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Confirme com sua senha E-SEIAF
        </DialogTitle>

        <Typography
          variant='body1'
          sx={{ marginBottom: '1rem', textAlign: 'left' }}
        >
          Digite sua senha
        </Typography>

        <TextField
          fullWidth
          label='Senha'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleTogglePassword} edge='end'>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          paddingBottom: '1rem',
          width: '100%',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={() => onConfirm(password)}
          disabled={!password}
          sx={{
            width: '50%',
            fontWeight: 'bold',
            backgroundColor: '#0048B6',
            '&:hover': { backgroundColor: '#003399' },
          }}
        >
          CONFIRMAR
        </Button>
        <Button
          onClick={onClose}
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: '#0048B6',
          }}
        >
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignPasswordModal;

import { getMunicipalitiesDropdown } from 'api/municipalities/municipalities.api';
import { getPermissionsDropdown } from 'api/permission/permission.api';
import { useEffect, useState } from 'react';

type PermissionType = {
  uuid: string;
  name: string;
  description: string;
};

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
  question5: string | null;
};

interface Step1Props {
  onNext: (answers: AnswersType) => void;
}

const useStep1 = ({ onNext }: Step1Props) => {
  const [selectedProfile, setSelectedProfile] = useState<string>('');
  const [formData, setFormData] = useState({
    selectedProfile: '',
    dadosInstituicao: {
      fantasyName: '',
      identification: '',
      name: '',
      uuid: '',
    },
    dadosPessoais: {
      nomeCompleto: '',
      cpf: '',
      email: '',
      municipio: '',
      cargo: '',
      funcao: '',
      telefone1: '',
      telefone2: '',
    },
    dadosLogin: {
      role: '',
      senha: '',
      confirmarSenha: '',
    },
    dadosVigencia: {
      vigenciaIndeterminada: false,
      dataInicial: '',
      dataFinal: '',
    },
    anexoArquivo: {
      name: '',
      type: '',
      size: 0,
      base64: '',
    },
  });
  const [permissions, setPermissions] = useState<PermissionType[]>([]);
  const [municipalitiesDropdown, setMunicipalitiesDropdown] = useState<
    { name: string }[]
  >([]);

  // Utils
  const maskCpf = (cpf: string) => {
    return cpf
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      .substring(0, 14);
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await getPermissionsDropdown();
        setPermissions(response?.data);
      } catch (error) {
        console.error('Erro ao buscar permissões:', error);
      }
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    const fetchMunicipalities = async () => {
      try {
        const data = await getMunicipalitiesDropdown();
        setMunicipalitiesDropdown(data?.data);
      } catch (error) {
        console.error('Error ao buscar municipios:', error);
      }
    };

    fetchMunicipalities();
  }, []);

  const handleNext = () => {
    localStorage.setItem('formatData', JSON.stringify(formData));
    onNext({
      question1: null,
      question2: null,
      question3: null,
      question4: null,
      question5: null,
    });
  };

  useEffect(() => {
    const storedData = localStorage.getItem('firstRegisterData');

    console.log('>>>> store <<<<', JSON.stringify(storedData));
    console.log('>>>> formData <<<<', formData);

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);

        setSelectedProfile(parsedData?.selectedProfile);

        setFormData((prev) => ({
          ...prev,
          selectedProfile: parsedData.selectedProfile || '',
          dadosInstituicao: parsedData.institution
            ? {
                fantasyName: parsedData.institution.fantasyName || '',
                identification: parsedData.institution.identification || '',
                name: parsedData.institution.name || '',
                uuid: parsedData.institution.uuid || '',
              }
            : prev.dadosInstituicao,
        }));
      } catch (error) {
        console.error('Erro ao analisar os dados do localStorage:', error);
      }
    }
  }, []);

  const handleChange = (
    category: keyof typeof formData,
    field: string,
    value: any,
  ) => {
    setFormData((prev) => {
      const categoryData = prev[category] ?? {};
      const updatedData = {
        ...prev,
        [category]: {
          ...(typeof categoryData === 'object' ? categoryData : {}),
          [field]: value,
        },
      };
      localStorage.setItem('formData', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      console.log('Arquivo selecionado:', file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileBase64 = reader.result as string;

        setFormData((prev) => ({
          ...prev,
          anexoArquivo: {
            name: file.name,
            type: file.type,
            size: file.size,
            base64: fileBase64,
          },
        }));

        localStorage.setItem(
          'formData',
          JSON.stringify({
            ...formData,
            anexoArquivo: {
              name: file.name,
              type: file.type,
              size: file.size,
              base64: fileBase64,
            },
          }),
        );
      };
    }
  };

  const handleRemoveFile = () => {
    console.log('Removendo arquivo');

    localStorage.setItem(
      'formData',
      JSON.stringify({ ...formData, anexoArquivo: null }),
    );
  };

  return {
    permissions,
    selectedProfile,
    handleNext,
    handleChange,
    handleFileChange,
    handleRemoveFile,
    maskCpf,
    formData,
    municipalitiesDropdown,
  };
};

export default useStep1;

import { createContext } from 'react';

export type ConfirmOptionsType = {
  title: string;
  content: string;
  confirmationText: string;
  cancellationText: string;
};

export type AlertOptionsType = {
  title: string;
  content: string;
  type?: 'primary' | 'success' | 'error';
  okText?: string;
};

export type DialogContextType = {
  alert: (option: Partial<AlertOptionsType>) => Promise<boolean>;
  confirm: (option: Partial<ConfirmOptionsType>) => Promise<boolean>;
};

export const DialogContext = createContext<DialogContextType>({
  alert: async () => false,
  confirm: async () => false,
});

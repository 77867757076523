import React, { Children, useState } from 'react';
import { TopNavBar } from 'components/top_nav_bar';
import { Header, MainCard, UserInfo } from 'components/home';
import { MenuProvider } from 'contexts/menu/menu_context';
import Box from '@mui/system/Box/Box';

export const ContainerPainel = (props: {
  children: any;
  pagina?: string;
  showBackButton?: boolean;
  backButtonPath?: string;
}) => {
  const { children, pagina, showBackButton, backButtonPath } = props;

  return (
    <MenuProvider>
      <Box pb={2}>
        <TopNavBar />
        <Header
          title={
            pagina ??
            'E-SEIAF - Sistema Estadual Integrado da Agricultura Familiar'
          }
          showBackButton={showBackButton}
          backButtonPath={backButtonPath}
        />

        {children}
      </Box>
    </MenuProvider>
  );
};

export default ContainerPainel;

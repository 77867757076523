import axios from 'axios';
import { authService } from 'services/auth/auth_service';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/users/profiles';

export const getProfilesDropdown = async (groupUuid?: string): Promise<any> => {
  const eseiafToken = authService.getTokenAPIInterna();

  const response = await axios.get(`${API_URL}`, {
    params: groupUuid ? { groupUuid } : {},
    headers: {
      Authorization: `Bearer ${eseiafToken}`,
    },
  });

  return response.data;
};

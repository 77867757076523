import React, { useState, useEffect, useContext } from 'react';
import { TopNavBar } from 'components/top_nav_bar';
import { Box } from '@mui/system';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import {
  getUsuarioAceitouOsTermoDeUso,
  partialUpdateUser,
} from 'api/user/user.api';
import { ErrorDialog } from 'components/shared';
import { ROUTES } from 'routes/constants';
import { useNavigate } from 'react-router-dom';
import { AuthContext, useAuth } from 'contexts/auth/auth_context';
import ContainerPainel from 'components/container_painel';
import { isResponseAPI, ResponseJSON, ResponseAPI } from 'api';

const ContentBox = styled(Box)({
  backgroundColor: '#FFFFFF',
  maxWidth: '975px',
  marginTop: '30px',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',

  marginLeft: 'auto',
  marginRight: 'auto',
  height: '530px',
  overflowY: 'auto',
});

const TermsOfUseAndConfidentiality: React.FC = () => {
  const navigate = useNavigate();
  const {
    user,
    getUsuarioJaAceitouTermoUsoLocalStorage,
    setUsuarioJaAceitouTermoUsoLocalStorage,
    logout,
  } = useAuth();

  const [isChecked, setIsChecked] = useState(false);
  const [openAgreementRequiredDialog, setOpenAgreementRequiredDialog] =
    useState(false);
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [jaAceitouTermos, setJaAceitouTermos] = useState(false);

  useEffect(() => {
    carregarDados();
    return () => {};
  }, []);

  const carregarDados = async () => {
    let jaAceitouTermos = getUsuarioJaAceitouTermoUsoLocalStorage();

    if (user && jaAceitouTermos === null) {
      //buscar informação na API
      const Resp = await getUsuarioAceitouOsTermoDeUso('TERMO DE USO');

      if (isResponseAPI(Resp)) {
        const RespAPI: ResponseAPI = Resp;
        alert(RespAPI.Mensagem);
      } else {
        const RespJSON: ResponseJSON = Resp;
        jaAceitouTermos = RespJSON.jaAceitouTermos;
      }
    }

    if (jaAceitouTermos) {
      setJaAceitouTermos(true);
      setIsChecked(true);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleExitAndNavigateLogin = () => {
    logout();
    navigate(ROUTES.LOGIN);
  };

  const handleAgreeAndContinue = async () => {
    if (isChecked) {
      if (user && user.uuid) {
        try {
          const Resp = await partialUpdateUser(user.uuid, {
            hasAcceptedTerms: true,
          });
          if (Resp.Status === 'OK') {
            setUsuarioJaAceitouTermoUsoLocalStorage(true);
            navigate(ROUTES.HOME);
          } else {
            alert(Resp.Mensagem);
          }
        } catch (error) {
          setInvalidRequest(true);
        }
      } else {
        navigate(ROUTES.FIRSTUSER);
      }
    } else {
      setOpenAgreementRequiredDialog(true);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <ContainerPainel pagina='TERMO DE USO E CONFIDENCIALIDADE'>
      <Box sx={{ marginTop: '48px', textAlign: 'center' }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '22.88px',
            letterSpacing: '0.17px',
            marginTop: '20px',
          }}
        >
          Leia atentamente e concorde com o termo de uso e confidencialidade
          para que possa realizar o acesso ao sistema.
        </Typography>
      </Box>
      <ContentBox style={{ height: '400px' }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          TERMO DE USO E CONFIDENCIALIDADE
        </Typography>

        <Box sx={{ marginTop: '10px', padding: '20px' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            1) Termo de Uso e Política de Privacidade
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            Declaro para os devidos fins e efeitos, que responsabilizo-me
            integralmente pela adequada utilização dos dados pessoais a que
            tiver acesso, estando ciente de que posso vir a ser responsabilizado
            civil, criminal e administrativamente pelos danos morais ou
            materiais decorrentes da utilização, reprodução ou divulgação
            indevida dos dados sobre os deveres, requisitos e responsabilidades
            decorrentes da Lei n. 13.709, de 14 de agosto de 2018, Lei Geral de
            Proteção de Dados Pessoais – LGPD, sobre as formas de coleta,
            tratamento e compartilhamento de dados.
          </Typography>
        </Box>
      </ContentBox>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '15px',
          maxWidth: '975px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          disabled={jaAceitouTermos}
        />
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '25.74px',
            letterSpacing: '0.17px',
            textAlign: 'left',
          }}
        >
          Confirmo que li todo o termo de uso e confidencialidade e concordo com
          todo seu conteúdo.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '15px',
          gap: '16px',
        }}
      >
        <Button
          variant='text'
          color='primary'
          size='medium'
          onClick={
            jaAceitouTermos
              ? () => navigate(ROUTES.HOME)
              : handleExitAndNavigateLogin
          }
        >
          SAIR
        </Button>
        <Button
          variant='contained'
          onClick={handleAgreeAndContinue}
          sx={{
            backgroundColor: !isChecked ? '#d3d3d3' : '',
          }}
          disabled={jaAceitouTermos}
        >
          ACEITAR
        </Button>
      </Box>

      <Dialog
        open={openAgreementRequiredDialog}
        onClose={() => setOpenAgreementRequiredDialog(false)}
        maxWidth='lg'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            padding: '24px',
            boxSizing: 'border-box',
            height: 'auto',
          },
        }}
      >
        <DialogTitle>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
          >
            Confirmação de aceite dos termos de uso
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '27px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '16px',
            }}
          >
            Você ainda não realizou a confirmação de leitura e aceite dos termos
            de uso.
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '27px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
          >
            Marque a caixa “Confirmo que li todo o termo de uso e
            confidencialidade e concordo com todo seu conteúdo” e clique em
            “Aceitar” para prosseguir com seu acesso ao sistema.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button
            onClick={() => setOpenAgreementRequiredDialog(false)}
            autoFocus
          >
            VOLTAR
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={handleExitAndNavigateLogin}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </ContainerPainel>
  );
};

export default TermsOfUseAndConfidentiality;

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { isResponseAPI, ResponseJSON, ResponseAPI } from 'api';
import { getListUsersByFilters } from 'api/user/user.api';
import ContainerPainel from 'components/container_painel';
import InputDados from 'components/input_dados';
import { EmptyContent, ErrorDialog } from 'components/shared';
import { ContainerForm } from 'components/shared/container_form';
import LoginDataDisplay from 'components/user/login_data_display';
import PersonalDataDisplay from 'components/user/personal_data_display';
import RegistrationDataDisplay from 'components/user/registration_data_display';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { formatCPF } from 'services/utils/format_cpf';
import { UserDetailsData } from './type';

const UserView: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const [isEmptyContent, setIsEmptyContent] = useState(true);
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetailsData | null>(null);
  const [permissaoSelelcionadaNome, setpermissaoSelelcionadaNome] =
    useState<string>();

  const [dataVigenciaInicial, setdataVigenciaInicial] = useState<string>('');
  const [dataVigenciaFinal, setdataVigenciaFinal] = useState<string>('');
  const [dataVigenciaIndeterminada, setdataVigenciaIndeterminada] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (!uuid) {
          setIsEmptyContent(true);
          return;
        }
        const Resp = await getListUsersByFilters({
          limit: '10',
          uuid: uuid,
        });

        if (isResponseAPI(Resp)) {
          const RespAPI: ResponseAPI = Resp;
          alert(RespAPI.Mensagem);
          setIsEmptyContent(true);
        } else {
          const userDetailsData: ResponseJSON = Resp;
          const UserAux = userDetailsData?.data[0];

          const activeRegistration = UserAux.isRegisterActive ? 'SIM' : 'NÃO';
          const blockRegistration = UserAux.isRegisterBlocked ? 'SIM' : 'NÃO';

          const separatedData: UserDetailsData = {
            personalData: {
              name: UserAux.name,
              cpf: formatCPF(UserAux.identification),
              email: UserAux.email,
              phone: UserAux.contactList[0].value,
              position: UserAux.position,
              occupation: UserAux.occupation,
              municipality: UserAux.address?.municipality,
            },
            loginData: {
              group: UserAux.permission?.profile?.group,
              profile: UserAux.permission?.profile,
              permission: UserAux.permission,
            },
            registrationData: {
              activeRegistration,
              blockRegistration,
              termData: UserAux.termStartDate
                ? {
                    termStartDate: UserAux.termStartDate,
                    termEndDate: UserAux.termEndDate,
                    termEndDateIndefinite: UserAux.termEndDateIndefinite,
                  }
                : null,
            },
          };

          if (UserAux.dadosVigencia) {
            const dadosVigencia = UserAux.dadosVigencia;
            setdataVigenciaInicial(dadosVigencia.dataInicial);
            setdataVigenciaFinal(dadosVigencia.dataFinal);
            setdataVigenciaIndeterminada(dadosVigencia.vigenciaIndeterminada);
          } else if (UserAux.validity) {
            const dadosVigencia = UserAux.validity;
            const convertInitialDate = dadosVigencia.dataInicial.split('-');
            const convertFinalDate = dadosVigencia.dataFinal.split('-');
            const initialDate = `${convertInitialDate[2]}/${convertInitialDate[1]}/${convertInitialDate[0]}`;
            const finalDate = `${convertFinalDate[2]}/${convertFinalDate[1]}/${convertFinalDate[0]}`;
            setdataVigenciaInicial(initialDate);
            setdataVigenciaFinal(finalDate);
            setdataVigenciaIndeterminada(dadosVigencia.vigenciaIndeterminada);
          }
          const Permissao = UserAux?.permission?.name;
          setpermissaoSelelcionadaNome(Permissao);

          setUserDetails(separatedData);
          setIsEmptyContent(false);
        }
      } catch (error) {
        console.log(error);
        setIsEmptyContent(true);
        setInvalidRequest(true);
      }
    };

    fetchUserDetails();
  }, [uuid]);

  const handleRefresh = () => {
    window.location.reload();
    setInvalidRequest(false);
  };

  const handleBack = () => {
    navigate(ROUTES.MANAGE_USER);
  };

  const getTipoRepresentanteMunicipal = (): boolean => {
    return (
      permissaoSelelcionadaNome?.trim().toUpperCase() ===
        'REPRESENTANTE LEGAL MUNICIPAL' || false
    );
  };

  return (
    <ContainerPainel pagina={'Visualizar Usuários'} showBackButton={true}>
      <>
        {isEmptyContent ? (
          <EmptyContent />
        ) : (
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
            }}
          >
            <Box
              sx={{
                paddingTop: '50px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '22.88px',
                  letterSpacing: '0.17px',
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                Visualize todos os dados do usuário no sistema.
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {userDetails && userDetails.personalData && (
                <PersonalDataDisplay
                  values={userDetails.personalData}
                  isView={true}
                />
              )}
              {userDetails && userDetails.loginData && (
                <LoginDataDisplay
                  values={userDetails.loginData}
                  isView={true}
                />
              )}
              {userDetails && userDetails.registrationData && (
                <RegistrationDataDisplay
                  values={userDetails.registrationData}
                  isView={true}
                />
              )}

              {getTipoRepresentanteMunicipal() && (
                <ContainerForm title='Dados da Vigência' width='1150px'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <InputDados
                        placeholder='Data Inicial de Vigência'
                        label='Data Inicial de Vigência'
                        mask='99/99/9999'
                        value={dataVigenciaInicial}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputDados
                        placeholder='Data Final de Vigência'
                        label='Data Final de Vigência'
                        mask='99/99/9999'
                        value={dataVigenciaFinal}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={dataVigenciaIndeterminada} />
                        }
                        label='Data final indeterminada'
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </ContainerForm>
              )}
            </Box>

            <Stack marginY='2rem'>
              <Button
                variant='text'
                onClick={handleBack}
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                  textAlign: 'left',
                  color: '#0048B6',
                  marginRight: '20px',
                }}
              >
                VOLTAR
              </Button>
            </Stack>
          </Box>
        )}
      </>

      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={() => setInvalidRequest(false)}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </ContainerPainel>
  );
};

export default UserView;

import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { PermissionsI } from 'api/common.types';
import { CustomTextField } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { validateCPF } from 'services/utils/validate_cpf';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface FiltersDialogProps {
  open: boolean;
  onClose: () => void;
  applyFilters: (
    name: string,
    cpf: string,
    selectedGroup: string,
    selectedProfile: string,
    selectedPermission: string,
    selectedUserStatus: string,
    selectedMunicipality: string,
  ) => Promise<boolean>;
  groupsDropdown?: PermissionsI;
  profilesDropdown?: PermissionsI;
  permissionsDropdown?: PermissionsI;
  userStatusDropdown?: PermissionsI;
  municipalitiesDropdown?: PermissionsI;
  initialName: string;
  initialCpf: string;
  initialGroup: string;
  initialProfile: string;
  initialPermission: string;
  initialUserStatus: string;
  initialMunicipality: string;
}

const FiltersDialog: React.FC<FiltersDialogProps> = ({
  open,
  onClose,
  applyFilters,
  groupsDropdown,
  profilesDropdown,
  permissionsDropdown,
  userStatusDropdown,
  municipalitiesDropdown,
  initialName,
  initialCpf,
  initialGroup,
  initialProfile,
  initialPermission,
  initialUserStatus,
  initialMunicipality,
}) => {
  const [name, setName] = useState(initialName || '');
  const [cpf, setCpf] = useState(initialCpf || '');
  const [cpfError, setCpfError] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(initialGroup || '');
  const [selectedProfile, setSelectedProfile] = useState(initialProfile || '');
  const [selectedPermission, setSelectedPermission] = useState(
    initialPermission || '',
  );
  const [selectedUserStatus, setSelectedUserStatus] = useState(
    initialUserStatus || '',
  );
  const [selectedMunicipality, setSelectedMunicipality] = useState(
    initialMunicipality || '',
  );
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (open) {
      setName(initialName || '');
      setCpf(initialCpf || '');
      setSelectedGroup(initialGroup || '');
      setSelectedProfile(initialProfile || '');
      setSelectedPermission(initialPermission || '');
      setSelectedUserStatus(initialUserStatus || '');
    }
  }, [
    open,
    initialName,
    initialCpf,
    initialGroup,
    initialProfile,
    initialPermission,
    initialUserStatus,
  ]);

  const handleClear = () => {
    setName('');
    setCpf('');
    setSelectedGroup('');
    setSelectedProfile('');
    setSelectedPermission('');
    setSelectedUserStatus('');
    setCpfError('');
    setErrorMessage('');
  };

  const handleConfirm = async () => {
    const formattedCPf = cpf.replace(/\D/g, '');

    const filtersAppliedSuccessfully = await applyFilters(
      name,
      formattedCPf,
      selectedGroup,
      selectedProfile,
      selectedPermission,
      selectedUserStatus,
      selectedMunicipality,
    );

    if (!filtersAppliedSuccessfully) {
      setErrorMessage(
        'Não foi possível localizar nenhum Usuário com os dados informados.',
      );
    } else {
      setErrorMessage('');
      onClose();
    }
  };

  const handleCpfBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, '');

    if (value.length > 0 && value.length < 11) {
      setCpfError('Informe o CPF corretamente');
      setIsConfirmDisabled(true);
    } else if (value.length === 11 && !validateCPF(value)) {
      setCpfError('Informe o CPF corretamente');
      setIsConfirmDisabled(true);
    } else {
      setCpfError('');
      setIsConfirmDisabled(false);
    }
  };

  const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, '');

    let formattedValue = value;
    if (value.length > 11) formattedValue = value.slice(0, 11);

    formattedValue = formattedValue
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    setCpf(formattedValue);

    if (value.length > 11 && !validateCPF(formattedValue)) {
      setCpfError('Informe o CPF corretamente');
      setIsConfirmDisabled(true);
    } else {
      setCpfError('');
      setIsConfirmDisabled(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      sx={{
        '& .MuiPaper-root': {
          height: 'auto',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          margin: 0,
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <Box sx={{ width: '1200px', marginTop: '60px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '80px',
              height: '5px',
              borderRadius: '100px 0px 0px 0px',
              backgroundColor: '#00000026',
            }}
          />
        </Box>

        <DialogTitle sx={{ padding: 0, marginBottom: '15px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '38.4px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
            >
              Filtrar
            </Typography>
            <Button startIcon={<CloseIcon />} onClick={handleClear}>
              LIMPAR
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          {errorMessage && (
            <Box sx={{ margin: '20px 0px 10px 0px' }}>
              <Alert
                severity='error'
                sx={{ position: 'relative !important', height: '48px' }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20.02px',
                    letterSpacing: '0.17px',
                    textAlign: 'left',
                  }}
                >
                  Não foi possível localizar nenhum Usuário com os dados
                  informados.
                </Typography>
              </Alert>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <CustomTextField
                label='Nome (Opcional)'
                variant='outlined'
                size='small'
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{
                  marginTop: '10px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    legend: {
                      width: '85px',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: '5px',
                    top: '5px',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                    color: '#00000099',
                  },
                }}
              />

              <CustomTextField
                label='CPF (Opcional)'
                variant='outlined'
                size='small'
                fullWidth
                value={cpf}
                onChange={handleCpfChange}
                onBlur={handleCpfBlur}
                error={!!cpfError}
                helperText={cpfError}
                sx={{
                  marginTop: '10px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    legend: {
                      width: '75px',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: '5px',
                    top: '5px',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                    color: '#00000099',
                  },
                }}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <CustomTextField
                label='Grupo (Opcional)'
                variant='outlined'
                size='small'
                select
                fullWidth
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
                sx={{
                  marginTop: '10px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    legend: {
                      width: '85px',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: '5px',
                    top: '5px',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                    color: '#00000099',
                  },
                }}
              >
                {groupsDropdown?.data?.map((group) => (
                  <MenuItem
                    key={group?.uuid}
                    value={group.uuid}
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                      textAlign: 'left',
                    }}
                  >
                    {group.name}
                  </MenuItem>
                ))}
              </CustomTextField>

              <CustomTextField
                label='Perfil (Opcional)'
                variant='outlined'
                size='small'
                select
                fullWidth
                value={selectedProfile}
                onChange={(e) => setSelectedProfile(e.target.value)}
                sx={{
                  marginTop: '10px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    legend: {
                      width: '80px',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: '5px',
                    top: '5px',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                    color: '#00000099',
                  },
                }}
              >
                {profilesDropdown?.data?.map((profile) => (
                  <MenuItem
                    key={profile.uuid}
                    value={profile.uuid}
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                      textAlign: 'left',
                    }}
                  >
                    {profile.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <CustomTextField
                label='Permissão (Opcional)'
                variant='outlined'
                size='small'
                select
                fullWidth
                value={selectedPermission}
                onChange={(e) => setSelectedPermission(e.target.value)}
                sx={{
                  marginTop: '10px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    legend: {
                      width: '105px',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: '5px',
                    top: '5px',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                    color: '#00000099',
                  },
                }}
              >
                {permissionsDropdown?.data?.map((permission) => (
                  <MenuItem
                    key={permission.uuid}
                    value={permission.uuid}
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                      textAlign: 'left',
                    }}
                  >
                    {permission.name}
                  </MenuItem>
                ))}
              </CustomTextField>

              {/* <CustomTextField
                label='Status (Opcional)'
                variant='outlined'
                size='small'
                select
                fullWidth
                value={selectedUserStatus}
                onChange={(e) => setSelectedUserStatus(e.target.value)}
                sx={{
                  marginTop: '10px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    legend: {
                      width: '90px',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: '5px',
                    top: '5px',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                    color: '#00000099',
                  },
                }}
              >
                {userStatusDropdown.map((status) => (
                  <MenuItem
                    key={status.uuid}
                    value={status.uuid}
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                      textAlign: 'left',
                    }}
                  >
                    {status.name}
                  </MenuItem>
                ))}
              </CustomTextField> */}
            </Box>

            {/* <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                paddingRight: '7px',
                width: '50%',
              }}
            >
              <Autocomplete
                sx={{ width: '100%' }}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) =>
                  setSelectedMunicipality(value ? value.uuid : '')
                }
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label='Município (Opcional)'
                    variant='outlined'
                    size='small'
                    fullWidth
                    sx={{
                      marginTop: '10px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        legend: {
                          width: '105px',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        left: '5px',
                        top: '5px',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '12px',
                        letterSpacing: '0.15px',
                        color: '#00000099',
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                      }}
                    >
                      {option.name}
                    </span>
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option.uuid === value.uuid
                }
              />
            </Box> */}
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: 'flex-start',
            padding: 0,
            margin: '30px 0px 30px 0px',
          }}
        >
          <Button
            variant='contained'
            size='medium'
            sx={{ backgroundColor: '#0048B6' }}
            onClick={handleConfirm}
            disabled={isConfirmDisabled}
          >
            confirmar
          </Button>
          <Button size='medium' onClick={onClose}>
            voltar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default FiltersDialog;

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  addValidity,
  editValidity,
  getValidity,
} from 'api/validity/validity.api';
import {
  CustomAlert,
  CustomSelect,
  CustomTextField,
  DynamicDialog,
  ErrorDialog,
} from 'components/shared';
import { useAuth } from 'contexts/auth/auth_context';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { IUserTerms } from 'types/api_permission_responses';
import { ITermsData, ITermsDataResponse } from 'types/termsData_responses';
import * as Yup from 'yup';
import closeFilledIcon from '../../assets/icons/close_filled.svg';
import RegisteredIcon from '../../assets/icons/registered-icon.svg';
import UpdatedIcon from '../../assets/icons/updated-icon.svg';
import UserTermCard from './user_term_card';

interface ManageTermProps {
  manageUserTermDialog: {
    open: boolean;
    userUuid: string;
    userName: string;
    close: () => void;
  };
}

interface DialogState {
  isOpen: boolean;
  icon: string;
  title: string;
  message: string;
  buttonText: string;
  onLogout: (() => void) | null;
}

const formatDateToInput = (date: string | Date | null | undefined): string => {
  if (!date) return '';
  const d = new Date(date);
  return d.toISOString().split('T')[0];
};

const ManageUserTerm: React.FC<ManageTermProps> = ({
  manageUserTermDialog,
}) => {
  const { user, logout } = useAuth();

  const [isFormTermDialogOpen, setIsFormTermDialogOpen] = useState(false);
  const [dateError, setDateError] = useState('');
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [termsData, setTermsData] = useState<IUserTerms[] | null>(null);
  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    icon: '',
    title: '',
    message: '',
    buttonText: 'Concluir',
    onLogout: null,
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [termToEdit, setTermToEdit] = useState<IUserTerms | null>(null);

  const initialValues = {
    managementTerm: {
      id: termToEdit?.id || '',
      status: termToEdit?.status || '',
      startDate: termToEdit?.startDate || '',
      endDate: termToEdit?.endDate || '',
      endDateIndefinite: termToEdit?.endDateIndefinite || false,
    },
  };

  const validationSchema = Yup.object({
    managementTerm: Yup.object({
      id: Yup.string().required('Informe o ID corretamente.'),
      status: Yup.string().required('Selecione o Status.'),
      startDate: Yup.date().required('Informe a data inicial corretamente.'),
      endDateIndefinite: Yup.boolean().test(
        'is-indefinite-disabled',
        'Não é permitido definir data final indefinida em modo de edição.',
        function (value) {
          return isEditMode ? !value : true;
        },
      ),
      endDate: Yup.date()
        .nullable()
        .when(['endDateIndefinite', 'startDate'], {
          is: (endDateIndefinite: boolean, startDate: Date | undefined) =>
            !endDateIndefinite && !!startDate,
          then: (schema) =>
            schema
              .required(
                'Informe a data final corretamente ou marque como indeterminada.',
              )
              .test(
                'is-before-original',
                'A data final deve ser anterior ou igual à data final original.',
                function (value) {
                  if (isEditMode && termToEdit?.endDate && value) {
                    const originalEndDate = new Date(termToEdit.endDate);
                    originalEndDate.setDate(originalEndDate.getDate() + 1);
                    const isBeforeOriginal = new Date(value) <= originalEndDate;
                    if (!isBeforeOriginal) {
                      setDateError(
                        'Ao alterar a vigência, só é permitido definir a data final antes do previsto (caso o representante legal não atue mais como representante atual da instituição antes do previsto).',
                      );
                    } else {
                      setDateError('');
                    }
                    return isBeforeOriginal;
                  }
                  setDateError('');
                  return true;
                },
              ),
        }),
    }),
  });

  const handleRefresh = () => {
    window.location.reload();
    setInvalidRequest(false);
  };

  const handleEdit = (idTerm: string) => {
    const term = termsData?.find((term) => term.id === idTerm) || null;
    setTermToEdit(term);
    setIsEditMode(true);
    setIsFormTermDialogOpen(true);
  };

  const handleAdd = () => {
    setDateError('');
    setTermToEdit(null);
    setIsEditMode(false);
    setIsFormTermDialogOpen(true);
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      if (!isEditMode) {
        await addValidity({
          id: values.managementTerm.id,
          status: values.managementTerm.status,
          startDate: new Date(values.managementTerm.startDate),
          endDate:
            values.managementTerm.endDate !== ''
              ? new Date(values.managementTerm.endDate)
              : null,
          endDateIndefinite: values.managementTerm.endDateIndefinite,
        });

        if (user?.uuid === manageUserTermDialog.userUuid) {
          setDialogState({
            isOpen: true,
            icon: RegisteredIcon,
            title:
              'Vigência cadastrada com sucesso - Será realizado o seu logout do sistema',
            message: `Identificamos que você é Representante Legal e está realizando o cadastro da vigência no seu próprio cadastro,
            desta forma realizaremos o seu logout do sistema.O cadastro permanecerá inativado até que o gerente estadual valide as informações da nova vigência.`,
            buttonText: 'Concluir',
            onLogout: () => logout(),
          });
        } else {
          setDialogState({
            isOpen: true,
            icon: RegisteredIcon,
            title: 'Vigência cadastrada com sucesso',
            message:
              'A nova vigência foi cadastrada com sucesso. O cadastro do usuário permanecerá inativado até que o Gerente Estadual valide as informações da nova vigência.',
            buttonText: 'Concluir',
            onLogout: null,
          });
        }
      } else if (isEditMode && termToEdit) {
        await editValidity(manageUserTermDialog.userUuid, {
          id: values.managementTerm.id,
          status: values.managementTerm.status,
          startDate: new Date(values.managementTerm.startDate),
          endDate:
            values.managementTerm.endDate !== ''
              ? new Date(values.managementTerm.endDate)
              : null,
          endDateIndefinite: values.managementTerm.endDateIndefinite,
        });

        if (user?.uuid === manageUserTermDialog.userUuid) {
          setDialogState({
            isOpen: true,
            icon: RegisteredIcon,
            title:
              'Vigência alterada com sucesso - Será realizado o seu logout do sistema',
            message: `Identificamos que você é Representante Legal e está realizando a alteração da vigência no seu próprio cadastro,
             desta forma realizaremos o seu logout do sistema. O cadastro permanecerá inativado até que o gerente estadual valide as informações da nova vigência.`,
            buttonText: 'Concluir',
            onLogout: () => logout(),
          });
        } else {
          setDialogState({
            isOpen: true,
            icon: UpdatedIcon,
            title: 'Vigência alterada com sucesso',
            message:
              'A vigência foi alterada com sucesso. O cadastro do usuário permanecerá inativado até que o Gerente Estadual valide as informações da nova vigência.',
            buttonText: 'Concluir',
            onLogout: null,
          });
        }
      }

      await fetchUserTerms(manageUserTermDialog.userUuid);
    } catch (error) {
      console.error('Error adding user permission term:', error);
      setInvalidRequest(true);
    }
  };

  const fetchUserTerms = async (userUuid: string): Promise<any> => {
    try {
      const termsData: ITermsDataResponse | null = await getValidity(userUuid);

      if (!termsData || !termsData.data || termsData.data.length === 0) {
        setTermsData([]);
        return;
      }

      const termsDataArray = termsData.data;
      console.log(termsDataArray);
      const termsDataArrayFormatted = termsDataArray.map(
        (term: ITermsData, index: number) => ({
          id: term.uuid,
          startDate: formatDateToInput(term.dataInicial),
          endDate: formatDateToInput(term.dataFinal),
          endDateIndefinite: term.dataFinal === null,
          status: term.isActive ? 'ATIVO' : 'INATIVO',
        }),
      );

      setTermsData(termsDataArrayFormatted as any);
    } catch (error) {
      console.error('Error fetching users:', error);
      setInvalidRequest(true);
      return null;
    }
  };

  useEffect(() => {
    const loadUserTerms = async () => {
      if (manageUserTermDialog.userUuid) {
        await fetchUserTerms(manageUserTermDialog.userUuid);
      }
    };

    loadUserTerms();
  }, [manageUserTermDialog.userUuid]);

  return (
    <>
      <Dialog
        open={manageUserTermDialog.open}
        fullWidth
        maxWidth='lg'
        sx={{ width: '100%' }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '38.4px',
                letterSpacing: '0.15px',
                textAlign: 'left',
                color: '#000000DE',
                marginTop: '20px',
              }}
            >
              Vigências Cadastradas
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '32px',
                letterSpacing: '0.15px',
                textAlign: 'left',
                color: '#000000DE',
                marginTop: '15px',
              }}
            >
              Usuário: {manageUserTermDialog.userName}
            </Typography>
          </Box>
          <Box>
            <Box
              component='button'
              onClick={() => {
                manageUserTermDialog.close && manageUserTermDialog.close();
                setTermsData(null);
              }}
              sx={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: '8px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#F0F0F0',
                },
              }}
            >
              <img src={closeFilledIcon} alt='Close' />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!termsData && (
            <Alert severity={'warning'} sx={{ marginBottom: '20px' }}>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20.02px',
                  letterSpacing: '0.17px',
                  textAlign: 'left',
                }}
              >
                Não localizamos Vigência cadastrada para esse usuário. Clique no
                botão abaixo ”Adicionar nova Vigência” para informar uma nova
                Vigência válida para o usuário.
              </Typography>
            </Alert>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0px 0px 20px 0px',
            }}
          >
            <Button
              variant='contained'
              sx={{ backgroundColor: '#0048B6' }}
              onClick={handleAdd}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                  color: '#FFFFFF',
                }}
              >
                ADICIONAR NOVA VIGÊNCIA
              </Typography>
            </Button>
          </Box>

          {termsData?.map((term) => {
            return (
              <UserTermCard key={term.id} termData={term} onEdit={handleEdit} />
            );
          })}
        </DialogContent>
      </Dialog>
      {/* FORM TERM DIALOG */}
      <Dialog open={isFormTermDialogOpen} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              color: '#000000DE',
              marginTop: '20px',
            }}
          >
            {!isEditMode
              ? 'Adicionar Nova Vigência de Gestão'
              : 'Alterar Vigência de Gestão'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors: any = {};
              if (
                values.managementTerm.endDate &&
                values.managementTerm.startDate
              ) {
                if (
                  new Date(values.managementTerm.endDate) <
                  new Date(values.managementTerm.startDate)
                ) {
                  errors.managementTerm = {
                    endDate: 'A data final deve ser posterior à data inicial.',
                  };
                  setDateError(
                    'A data final deve ser posterior à data inicial.',
                  );
                } else {
                  setDateError('');
                }
              }
              return errors;
            }}
          >
            {({
              handleChange,
              values,
              handleBlur,
              touched,
              errors,
              isValid,
              setFieldValue,
            }) => (
              <Form>
                {dateError && (
                  <CustomAlert severity='error' message={dateError} />
                )}
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
                  >
                    <Box sx={{ flexBasis: '50%' }}>
                      <Field
                        name='managementTerm.id'
                        as={CustomTextField}
                        label='ID'
                        variant='outlined'
                        size='small'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.managementTerm?.id &&
                          Boolean(errors.managementTerm?.id)
                        }
                        helperText={
                          touched.managementTerm?.id &&
                          errors.managementTerm?.id
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        disabled={isEditMode}
                        sx={{
                          marginTop: '10px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            legend: {
                              width: '20px',
                            },
                          },
                        }}
                      />
                    </Box>

                    <Box sx={{ flexBasis: '50%' }}>
                      <FormControl size='small' fullWidth>
                        <InputLabel
                          sx={{
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            fontWeight: 400,
                            letterSpacing: '0.15px',
                            textAlign: 'left',
                            color: '#00000099',
                            '&.Mui-focused': {
                              color: '#00000099',
                            },
                            marginTop: '12px',
                          }}
                        >
                          Status da Vigência
                        </InputLabel>
                        <Field
                          name='managementTerm.status'
                          as={CustomSelect}
                          label='Status da Vigência'
                          size='small'
                          onChange={handleChange}
                          fullWidth
                          onBlur={handleBlur}
                          error={
                            touched.managementTerm?.status &&
                            Boolean(errors.managementTerm?.status)
                          }
                          sx={{
                            marginTop: '10px',
                            '& .MuiOutlinedInput-notchedOutline': {
                              legend: {
                                width: '85px',
                              },
                            },
                          }}
                        >
                          <MenuItem
                            value='ATIVO'
                            sx={{
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                              textAlign: 'left',
                            }}
                          >
                            ATIVO
                          </MenuItem>
                          <MenuItem
                            value='INATIVO'
                            sx={{
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                              textAlign: 'left',
                            }}
                          >
                            INATIVO
                          </MenuItem>
                        </Field>
                      </FormControl>
                      {touched.managementTerm?.status &&
                        errors.managementTerm?.status && (
                          <Typography
                            color='error'
                            variant='caption'
                            sx={{
                              display: 'block',
                              textAlign: 'left',
                              margin: '5px 0px 0px 15px',
                            }}
                          >
                            {errors.managementTerm?.status}
                          </Typography>
                        )}
                    </Box>
                  </Box>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
                  >
                    <Box sx={{ flexBasis: '50%' }}>
                      <Field
                        name='managementTerm.startDate'
                        as={CustomTextField}
                        label='Data inicial - de'
                        type='date'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.managementTerm?.startDate &&
                          Boolean(errors.managementTerm?.startDate)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      {touched.managementTerm?.startDate &&
                        errors.managementTerm?.startDate && (
                          <Typography
                            color='error'
                            variant='caption'
                            sx={{
                              display: 'block',
                              textAlign: 'left',
                              margin: '5px 0px 0px 15px',
                            }}
                          >
                            {errors.managementTerm?.startDate}
                          </Typography>
                        )}
                    </Box>

                    <Box sx={{ flexBasis: '50%', display: 'grid', gap: '8px' }}>
                      <Field
                        name='managementTerm.endDate'
                        as={CustomTextField}
                        label='Data Final'
                        type='date'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.managementTerm?.endDate &&
                          Boolean(errors.managementTerm?.endDate)
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled={values.managementTerm.endDateIndefinite}
                        value={
                          values.managementTerm.endDateIndefinite
                            ? ''
                            : values.managementTerm.endDate
                        }
                      />
                      {touched.managementTerm?.endDate &&
                        errors.managementTerm?.endDate && (
                          <Typography
                            color='error'
                            variant='caption'
                            sx={{
                              display: 'block',
                              textAlign: 'left',
                              margin: '5px 0px 0px 15px',
                            }}
                          >
                            {errors.managementTerm?.endDate}
                          </Typography>
                        )}

                      <FormControlLabel
                        control={
                          <Field
                            name='managementTerm.endDateIndefinite'
                            type='checkbox'
                            as={Checkbox}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChange(e);
                              if (e.target.checked) {
                                setFieldValue('managementTerm.endDate', '');
                                setDateError('');
                              }
                            }}
                          />
                        }
                        label='Data Final Indeterminada'
                        componentsProps={{
                          typography: {
                            sx: {
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                              textAlign: 'left',
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <DialogActions
                  sx={{
                    justifyContent: 'flex-start',
                    padding: 0,
                    margin: '0px 0px 30px 0px',
                  }}
                >
                  <Button
                    variant='contained'
                    size='medium'
                    sx={{ backgroundColor: '#0048B6' }}
                    type='submit'
                    disabled={!isValid}
                  >
                    Confirmar
                  </Button>
                  <Button
                    size='medium'
                    onClick={() => setIsFormTermDialogOpen(false)}
                  >
                    Voltar
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <DynamicDialog
        isOpen={dialogState.isOpen}
        icon={dialogState.icon}
        title={dialogState.title}
        message={dialogState.message}
        buttonText='Concluir'
        onClose={() => {
          setDialogState((prevState) => ({
            ...prevState,
            isOpen: false,
            onLogout: null,
          }));
          setIsFormTermDialogOpen(false);
        }}
        onLogout={dialogState.onLogout || undefined}
      />
      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={() => setInvalidRequest(false)}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </>
  );
};

export default ManageUserTerm;

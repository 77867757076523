import React, { useState } from 'react';

import { Header } from 'components/home';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import { PageTitle } from 'components/shared/page_title';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { ItemForm } from 'components/shared/item_form';
import { ROUTES } from 'routes/constants';

export interface InstitutionI {
  id: number;
  name: string;
}

const CreateCifa2026: React.FC = () => {
  const [institutions, setInstitutions] = useState<InstitutionI[]>([]);
  const [complianceTerm, setComplianceTerm] = useState({});
  const [techAssist, setTechAssist] = useState({});

  const handleBack = () => {
    console.log('Voltar clicado');
  };

  return (
    <MenuProvider>
      <TopNavBar />

      <Header title='CIFA - Cadastrar Município - 2026' showBackButton={true} />

      <PageTitle
        title='Cadastro de Município - CIFA 2026'
        subtitle='Responda todo o questionário corretamente. Apenas após o preenchimento de todas as etapas abaixo, você poderá confirmar o Cadastro do Município. '
      />

      <Stack marginX='4rem' spacing='2rem'>
        <ItemForm
          number={1}
          title='Cumprimento do Termo'
          subtitle='Informe os dados sobre o cumprimento dos requisitos do Termo de Adesão pelo Município.'
          status='Aguardando Preenchimento'
          pathTo={ROUTES.COMPLIANCE_TERM_2026}
          state={{ setComplianceTerm }}
        />
        <ItemForm
          number={2}
          title='Assistência Técnica e Extensão Rural'
          subtitle='Preencha as informações sobre os atendimentos ATER realizados no Município.'
          status='Preenchimento Pendente'
          pathTo={ROUTES.TECH_ASSIST_2026}
        />
        <ItemForm
          number={3}
          title='Alimentação Escolar'
          subtitle='Informe os dados sobre a aquisição de Alimentação Escolar no Município.'
          status='Preenchimento Pendente'
        />
        <ItemForm
          number={4}
          title='Recurso Financeiro Aplicado'
          subtitle='Preencha as informações sobre os valores destinados e aplicados à dinamização da agricultura familiar no Município.'
          status='Preenchimento Pendente'
        />

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary' onClick={handleBack}>
            Voltar
          </Button>
          <Button variant='contained' color='primary' disabled>
            Confirmar e Enviar
          </Button>
        </Stack>

        <Stack marginX='4rem' marginTop='2rem'></Stack>
      </Stack>
    </MenuProvider>
  );
};

export default CreateCifa2026;

import { getPrefectureWithoutAgreementTerm } from 'api/institutions/institution.api';
import { useEffect, useState } from 'react';

type AnswersType = {
  question1: string | null;
  question2: string | null;
};

interface Step1Props {
  onNext: (answers: AnswersType) => void;
}

const useStep1 = ({ onNext }: Step1Props) => {
  const [prefecture, setPrefecture] = useState<
    { uuid: string; name: string }[]
  >([]);
  const [signatureDate, setSignatureDate] = useState('');
  const [isActive, setIsActive] = useState('Sim');
  const [file, setFile] = useState<File | null>(null);
  const [formData, setFormData] = useState({
    dadosTermosAdesao: {
      prefeitura: '',
      dataAssinatura: '',
      status: false,
    },
    anexoArquivo: {
      name: '',
      type: '',
      size: 0,
      base64: '',
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileBase64 = reader.result as string;

        setFormData((prev) => ({
          ...prev,
          anexoArquivo: {
            name: file.name,
            type: file.type,
            size: file.size,
            base64: fileBase64,
          },
        }));

        localStorage.setItem(
          'formData',
          JSON.stringify({
            ...formData,
            anexoArquivo: {
              name: file.name,
              type: file.type,
              size: file.size,
              base64: fileBase64,
            },
          }),
        );
      };
    }
  };

  const handleRemoveFile = () => {
    console.log('Removendo arquivo');

    localStorage.setItem(
      'formData',
      JSON.stringify({ ...formData, anexoArquivo: null }),
    );
  };

  const handleNext = () => {
    const selectedPrefecture = prefecture.find(
      (pre) => pre.uuid === formData.dadosTermosAdesao.prefeitura,
    );

    const updatedFormData = {
      ...formData,
      dadosTermosAdesao: {
        ...formData.dadosTermosAdesao,
        prefeituraNome: selectedPrefecture?.name || '',
      },
    };

    console.log('>>>>> OBJ <<<<<', updatedFormData);

    localStorage.setItem('formatDataTerms', JSON.stringify(updatedFormData));

    onNext({
      question1: null,
      question2: null,
    });
  };

  useEffect(() => {
    const fetchPrefecture = async () => {
      try {
        const data = await getPrefectureWithoutAgreementTerm();
        setPrefecture(data?.data);
      } catch (error) {
        console.error('Erro ao buscar prefeituras:', error);
      }
    };

    fetchPrefecture();
  }, []);

  const handleChange = (field: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      dadosTermosAdesao: {
        ...prev.dadosTermosAdesao,
        [field]: value,
      },
    }));
  };

  return {
    prefecture,
    setPrefecture,
    handleChange,
    signatureDate,
    setSignatureDate,
    isActive,
    setIsActive,
    file,
    handleFileChange,
    handleRemoveFile,
    handleNext,
    formData,
  };
};

export default useStep1;

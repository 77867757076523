import ContainerPainel from 'components/container_painel';
import { StepperHorizontal } from 'components/shared/stepper_horizontal';
import React from 'react';
import useRegisterTermOfAdhesion from './use_register_term_of_adhesion';
import Step1 from './step1';
import Step2 from './step2';

const RegisterTermOfAdhesion: React.FC = () => {
  const { activeStep, steps, handleNext } = useRegisterTermOfAdhesion();

  return (
    <ContainerPainel
      pagina='Cadastrar Termos de Adesão Municipal'
      showBackButton={true}
    >
      <StepperHorizontal activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <Step1 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 1 && (
        <Step2 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
    </ContainerPainel>
  );
};

export default RegisterTermOfAdhesion;

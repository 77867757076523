import React, { useState } from 'react';
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ContainerForm } from 'components/shared/container_form';
import { Stack } from '@mui/system';
import { PageTitle } from 'components/shared/page_title';
import OrdenarModal from 'components/shared/ordenar_modal';
import FilterModal from 'components/shared/filter_modal';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
};

interface Step3Props {
  onNext: (answers: AnswersType) => void;
}

const Step3: React.FC<Step3Props> = ({ onNext }) => {
  const [isOrdenarModalOpen, setIsOrdenarModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [question1, setQuestion1] = useState<string | null>(null);
  const [beneficiaries, setBeneficiaries] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleCloseOrdenarModal = () => setIsOrdenarModalOpen(false);

  const handleCloseFilterModal = () => setIsFilterModalOpen(false);

  const handleNext = () => {
    onNext({
      question1: beneficiaries,
      question2: uploadedFile?.name || null,
      question3: null,
      question4: null,
    });
  };

  return (
    <>
      <PageTitle
        title='Assistência Técnica e Extensão Rural - Instituições de Ensino'
        subtitle='Informe corretamente os dados sobre os atendimentos ATER realizados com a parceira.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        <Alert
          severity='info'
          sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}
        >
          <Typography
            variant='body2'
            fontSize='14px'
            textAlign='left'
            lineHeight={1.5}
          >
            Considera-se Assistência Técnica e Extensão Rural - ATER o número de
            atendimentos prestados aos beneficiários da agricultura familiar
            (unidade agro familiar) do município por profissional de ATER, por
            meio de, no mínimo, 1 (uma) visita presencial ao longo do ano.
          </Typography>
        </Alert>

        <ContainerForm title='ATER - Atendidos pelas Instituições de Ensino'>
          <FormControl>
            <FormLabel id='foreigner-label'>
              O Município realizou parceria com Instituições de Ensino em 2025?
            </FormLabel>
            <RadioGroup
              aria-labelledby='foreigner-label'
              value={question1}
              onChange={(e) => setQuestion1(e.target.value)}
            >
              <FormControlLabel value='Não' control={<Radio />} label='Não' />
              <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
            </RadioGroup>
          </FormControl>
        </ContainerForm>

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary' disabled>
            Voltar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleNext}
            //disabled={!isAdvanceEnabled}
          >
            Avançar
          </Button>
        </Stack>
        <Stack />
        <OrdenarModal
          isOpen={isOrdenarModalOpen}
          onClose={handleCloseOrdenarModal}
        />
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={handleCloseFilterModal}
        />
      </Stack>
    </>
  );
};

export default Step3;

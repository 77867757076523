import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface OrderDialogProps {
  open: boolean;
  selectedSort: string;
  onClose: () => void;
  handleSortChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  applySort: () => void;
}

const OrderDialog: React.FC<OrderDialogProps> = ({
  open,
  selectedSort,
  onClose,
  handleSortChange,
  applySort,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      sx={{
        '& .MuiPaper-root': {
          height: '280px',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          margin: 0,
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <Box sx={{ width: '1200px', marginTop: '60px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '80px',
              height: '5px',
              borderRadius: '100px 0px 0px 0px',
              backgroundColor: '#00000026',
            }}
          />
        </Box>

        <DialogTitle sx={{ padding: 0, marginBottom: '15px' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
          >
            Ordenar
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <TextField
            select
            label='Ordenar'
            value={selectedSort}
            onChange={handleSortChange}
            size='small'
            fullWidth
            sx={{
              height: '72px',
              marginTop: '10px',
            }}
            InputLabelProps={{
              sx: {
                left: '5px',
                top: '5px',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '12px',
                letterSpacing: '0.15px',
                color: '#00000099',
              },
            }}
            SelectProps={{
              sx: {
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
                color: '#000000DE',
                textAlign: 'left',
              },
            }}
          >
            <MenuItem
              key='recent'
              value='DESC'
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
            >
              Do mais recente ao mais antigo
            </MenuItem>
            <MenuItem
              key='oldest'
              value='ASC'
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
            >
              Do mais antigo ao mais recente
            </MenuItem>
          </TextField>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'flex-start', padding: 0 }}>
          <Button
            variant='contained'
            size='medium'
            sx={{ backgroundColor: '#0048B6' }}
            onClick={applySort}
          >
            confirmar
          </Button>
          <Button size='medium' onClick={onClose}>
            voltar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default OrderDialog;

import { Box, Button, Stack } from '@mui/material';
import { ListAgreementTermResponse } from 'api/agreement-term/api.types';
import ContainerPainel from 'components/container_painel';
import DataConfirmation from 'components/shared/data_confirmation';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useViewTermsAdhesion from './use_view_terms_adhesion';
import { formatDate } from 'utils/format_date';

interface LocationState {
  state?: ListAgreementTermResponse;
}

const ViewTermsAdhesion: React.FC = () => {
  const location = useLocation() as unknown as LocationState;
  const term = location.state;
  const { terms } = useViewTermsAdhesion({ term });

  if (!term) {
    return <p>Termo não encontrado.</p>;
  }

  return (
    <ContainerPainel pagina='Termo de Adesão Municipal' showBackButton>
      <Stack marginX='4rem' spacing='2rem'>
        <DataConfirmation
          title='Dados do Termo de Adesão'
          data={[
            {
              label: 'Prefeitura',
              value: term?.institution?.name,
            },
            {
              label: 'Data do Vencimento',
              value: formatDate(term?.dueAt),
            },
            {
              label: 'Data da Assinatura - Representante Legal Municipal',
              value: term?.municipalSignatureDate,
            },
            {
              label: 'Data da Assinatura - Representante Legal Estadual',
              value: term?.stateSignatureDate,
            },
            {
              label: 'Status',
              value: term?.status,
            },
          ]}
        />
        <DataConfirmation
          title='Termo de Adesão Vigente'
          atas={[
            {
              titlePDF: 'Arquivo Enviado',
              label: term?.file?.name,
              fileName: term?.file?.name,
              fileSize: term?.file?.mimeType,
              onView: () => console.log('Visualizar documento'),
            },
          ]}
        />
        {terms && (
          <DataConfirmation
            title='Histórico Termos de Adesão'
            historyTerms={terms}
            showHistoryTerms={true}
          />
        )}
      </Stack>

      {/* Botões */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={2}
        marginTop='20px'
      >
        <Button
          variant='text'
          sx={{
            color: '#1976D2',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          Voltar
        </Button>
      </Box>
    </ContainerPainel>
  );
};

export default ViewTermsAdhesion;

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ListAgreementTermResponse } from 'api/agreement-term/api.types';
import { formatDate } from 'utils/format_date';

export type FormDataType = {
  dadosTermosAdesao: {
    uuid: string;
    prefeitura: string;
    prefeituraNome: string;
    dataAssinatura: string;
    status: boolean;
  };
  anexoArquivo: {
    fileUuid: string | null;
    name: string;
    type: string;
    size: number;
    base64: string;
  } | null;
};

const defaultFormData: FormDataType = {
  dadosTermosAdesao: {
    uuid: '',
    prefeitura: '',
    prefeituraNome: '',
    dataAssinatura: '',
    status: false,
  },
  anexoArquivo: null,
};

const convertTermToFormData = (
  term: ListAgreementTermResponse,
): FormDataType => {
  return {
    dadosTermosAdesao: {
      uuid: term.uuid,
      prefeitura: term.institution?.uuid || '',
      prefeituraNome: term.institution?.name || '',
      dataAssinatura: term.signatureAt,
      status: term.status === 'ATIVO',
    },
    anexoArquivo: term.file
      ? {
          fileUuid: term.file.uuid,
          name: term.file.name,
          type: term.file.mimeType,
          size: term.file.size,
          base64: term.file.base64,
        }
      : null,
  };
};

const useEditTermAdhesion = () => {
  const location = useLocation();

  const term = (location.state as ListAgreementTermResponse) || null;

  const [activeStep, setActiveStep] = useState<number>(0);
  const [formData, setFormData] = useState<FormDataType | null>(null);

  // Inicializa os dados do formulário com o termo recebido (se houver)
  useEffect(() => {
    if (term) {
      setFormData(convertTermToFormData(term));
    }
  }, [term]);

  const handleNext = (updatedData?: Partial<FormDataType>) => {
    if (updatedData) {
      setFormData((prev) => ({
        ...prev,
        ...updatedData,
        dadosTermosAdesao: {
          ...prev!.dadosTermosAdesao,
          ...updatedData.dadosTermosAdesao,
        },
        anexoArquivo:
          updatedData.anexoArquivo !== undefined
            ? updatedData.anexoArquivo
            : prev!.anexoArquivo,
      }));
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const steps = ['Dados principais', 'Confirmação dos Dados'];
  console.log('formData', formData);
  return {
    activeStep,
    steps,
    formData,
    setFormData,
    handleNext,
    handleBack,
  };
};

export default useEditTermAdhesion;

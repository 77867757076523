import ContainerPainel from 'components/container_painel';
import { StepperHorizontal } from 'components/shared/stepper_horizontal';
import React from 'react';
import useEditTermAdhesion from './use_edit_term_adhesion';
import Step1 from './step1';
import Step2 from './step2';

const EditTermOfAdhesion: React.FC = () => {
  const { activeStep, steps, formData, setFormData, handleNext, handleBack } =
    useEditTermAdhesion();

  if (!formData) return null;

  return (
    <ContainerPainel pagina='Editar Termos de Adesão Municipal' showBackButton>
      <StepperHorizontal activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <Step1
          formData={formData}
          setFormData={setFormData}
          onNext={() => handleNext()}
        />
      )}
      {activeStep === 1 && <Step2 formData={formData} onBack={handleBack} />}
    </ContainerPainel>
  );
};

export default EditTermOfAdhesion;

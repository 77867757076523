import React from 'react';
import { Field } from 'formik';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomAlert, CustomSelect, CustomTextField } from 'components/shared';
import { UserFormAction } from 'pages/user/user_form';
import { set } from 'lodash';

interface LoginDataFormProps {
  groupOptions: { data: { uuid: string; name: string }[] };
  profileOptions: { data: { uuid: string; name: string }[] };
  permissionOptions: { data: { uuid: string; name: string }[] };
  institutionOptions: { data: { uuid: string; name: string }[] };
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  userFormAction: UserFormAction;
}

const LoginDataForm: React.FC<LoginDataFormProps> = ({
  groupOptions,
  profileOptions,
  permissionOptions,
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const getErrorMessage = () => {
    if (errors.loginData?.group && touched.loginData?.group) {
      return 'Selecione a opção que deseja do "Grupo do Perfil" abaixo para prosseguir.';
    }
    if (errors.loginData?.profile && touched.loginData?.profile) {
      return 'Selecione a opção que deseja do "Perfil do Usuário" abaixo para prosseguir.';
    }
    if (errors.loginData?.permission && touched.loginData?.permission) {
      return 'Selecione a opção que deseja da "Permissão do Usuário" abaixo para prosseguir.';
    }
    if (errors.loginData?.password && touched.loginData?.password) {
      return 'Preencha o campo "Senha de assinatura digital" corretamente e com um dado válido.';
    }
    return null;
  };
  const errorMessage = getErrorMessage();

  return (
    <Box sx={{ width: '1152px', marginTop: '20px' }}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h6'>Dados de login</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {errorMessage && (
            <CustomAlert severity='error' message={errorMessage} />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <FormControl size='small' fullWidth>
                <InputLabel
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: 400,
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                    color: '#00000099',
                    '&.Mui-focused': {
                      color: '#00000099',
                    },
                  }}
                >
                  Grupo do Perfil
                </InputLabel>
                <Field
                  name='loginData.group'
                  as={CustomSelect}
                  labelId='group-label'
                  id='group-select'
                  label='Grupo do Perfil'
                  size='small'
                  value={values.loginData.group}
                  onChange={(e: any) => {
                    const selectedValue = e.target.value;
                    setFieldValue('loginData.group', selectedValue);
                    setFieldValue('loginData.profile', '');
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.loginData?.group && Boolean(errors.loginData?.group)
                  }
                >
                  {groupOptions?.data?.map((option) => (
                    <MenuItem
                      key={option.uuid}
                      value={option.uuid}
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>

                {touched.loginData?.group && errors.loginData?.group && (
                  <Typography
                    color='error'
                    variant='caption'
                    textAlign={'left'}
                  >
                    {errors.loginData?.group}
                  </Typography>
                )}
              </FormControl>

              <FormControl size='small' fullWidth>
                <InputLabel
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: 400,
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                    color: '#00000099',
                    '&.Mui-focused': {
                      color: '#00000099',
                    },
                  }}
                >
                  Perfil do Usuário
                </InputLabel>
                <Field
                  name='loginData.profile'
                  as={CustomSelect}
                  labelId='profile-label'
                  id='profile-select'
                  label='Perfil do Usuário'
                  size='small'
                  value={values.loginData.profile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.loginData?.profile &&
                    Boolean(errors.loginData?.profile)
                  }
                >
                  {profileOptions?.data?.map((option) => (
                    <MenuItem
                      key={option.uuid}
                      value={option.uuid}
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>

                {touched.loginData?.profile && errors.loginData?.profile && (
                  <Typography
                    color='error'
                    variant='caption'
                    textAlign={'left'}
                  >
                    {errors.loginData?.profile}
                  </Typography>
                )}
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <FormControl size='small' fullWidth>
                <InputLabel
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: 400,
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                    color: '#00000099',
                    '&.Mui-focused': {
                      color: '#00000099',
                    },
                  }}
                >
                  Permissão do Usuário
                </InputLabel>
                <Field
                  name='loginData.permission'
                  as={CustomSelect}
                  labelId='permission-label'
                  id='permission-select'
                  label='Permissão do Usuário'
                  size='small'
                  value={values.loginData.permission}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.loginData?.permission &&
                    Boolean(errors.loginData?.permission)
                  }
                >
                  {permissionOptions?.data.map((option) => (
                    <MenuItem
                      key={option.uuid}
                      value={option.uuid}
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>

                {touched.loginData?.permission &&
                  errors.loginData?.permission && (
                    <Typography
                      color='error'
                      variant='caption'
                      textAlign={'left'}
                    >
                      {errors.loginData?.permission}
                    </Typography>
                  )}
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Field
                name='loginData.password'
                as={CustomTextField}
                label='Senha de assinatura digital'
                variant='outlined'
                size='small'
                type='password'
                value={values.loginData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.loginData?.password &&
                  Boolean(errors.loginData?.password)
                }
                helperText={
                  touched.loginData?.password && errors.loginData?.password
                }
                InputLabelProps={{ shrink: true }}
              />

              <Field
                name='loginData.confirmPassword'
                as={CustomTextField}
                label='Repita a senha de assinatura digital'
                variant='outlined'
                size='small'
                type='password'
                value={values.loginData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.loginData?.confirmPassword &&
                  Boolean(errors.loginData?.confirmPassword)
                }
                helperText={
                  touched.loginData?.confirmPassword &&
                  errors.loginData?.confirmPassword
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LoginDataForm;

import React from 'react';
import facebookOutlinedIcon from '../../assets/icons/facebook_outlined.svg';
import instagramOutlinedIcon from '../../assets/icons/instagram_outlined.svg';
import twitterFilledIcon from '../../assets/icons/twitter_filled.svg';
import youtubeOutlinedIcon from '../../assets/icons/youtube_outlined.svg';
import {
  CustomAppBar,
  CustomToolbar,
  LeftButtons,
  CustomButton,
  RightButtons,
  CustomRightButton,
} from './top_links_bar.styles';

const TopLinksBar: React.FC = () => {
  return (
    <CustomAppBar>
      <CustomToolbar>
        <LeftButtons>
          <a
            href='https://www.transparencia.mt.gov.br/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomButton>Transparência</CustomButton>
          </a>
          <a
            href='https://legislacao.mt.gov.br/mt'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomButton>Legislação</CustomButton>
          </a>
          <a
            href='https://portal.mt.gov.br/app/catalog/orgao'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomButton>Secretarias do Governo</CustomButton>
          </a>
          <a
            href='https://www.secom.mt.gov.br/contatos'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomButton>Contatos GovMT</CustomButton>
          </a>
        </LeftButtons>
        <RightButtons>
          <a
            href='https://www.facebook.com/govmatogrosso/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomRightButton>
              <img src={facebookOutlinedIcon} alt='Facebook' />
            </CustomRightButton>
          </a>
          <a
            href='https://x.com/GovMatoGrosso'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomRightButton>
              <img src={instagramOutlinedIcon} alt='Instagram' />
            </CustomRightButton>
          </a>
          <a
            href='https://www.instagram.com/govmatogrosso/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomRightButton>
              <img src={twitterFilledIcon} alt='Twitter' />
            </CustomRightButton>
          </a>
          <a
            href='https://www.youtube.com/@secom_mt'
            target='_blank'
            rel='noopener noreferrer'
          >
            <CustomRightButton>
              <img src={youtubeOutlinedIcon} alt='YouTube' />
            </CustomRightButton>
          </a>
        </RightButtons>
      </CustomToolbar>
    </CustomAppBar>
  );
};

export default TopLinksBar;

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Paper,
  Avatar,
  Typography,
} from '@mui/material';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AlertOptionsType } from './DialogContext';

type AlertType = 'primary' | 'success' | 'error';

type AlertDialogProps = AlertOptionsType & {
  open: boolean;
  onClick: () => void;
  type?: AlertType;
};

const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  title,
  content,
  okText,
  onClick,
  type = 'primary',
}) => {
  const handleClose = (_event: React.SyntheticEvent, reason: string) => {
    if (reason === 'backdropClick') return;
    onClick();
  };

  const iconMapping = {
    primary: <TaskOutlinedIcon />,
    success: <CheckCircleOutlinedIcon />,
    error: <ErrorOutlineOutlinedIcon />,
  };

  const getAvatarStyle = (type: AlertType) => {
    switch (type) {
      case 'success':
        return { backgroundColor: '#4caf50' };
      case 'error':
        return { backgroundColor: '#f44336' };
      case 'primary':
      default:
        return { backgroundColor: '#2196f3' };
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      /* PaperComponent={Paper}
      PaperProps={{ sx: { borderRadius: '0.25rem', padding: '8px' } }} */
      maxWidth='sm'
      fullWidth
      sx={{ '.MuiPaper-root': { padding: '1.5rem' } }}
    >
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        <Avatar sx={getAvatarStyle(type)}>{iconMapping[type]}</Avatar>
      </div>
      <DialogTitle id='alert-dialog-title'>
        <Typography variant='h6' align='center' fontWeight='bold'>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          id='alert-dialog-description'
          variant='body1'
          align='center'
        >
          {content}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={onClick} color={type} autoFocus>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;

import { Institution } from 'pages/institutions';
import InstitutionView from 'pages/institutions/institution_view';
import InstitutionForm from 'pages/institutions/form/institution.form';
import CreateCifa from 'pages/service_management/Cifa/2025/CifaCrud2025/CreateCifa2025';
import CreateFederalSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/FederalSchoolFeeding/CreateFederalSchoolFeeding2025';
import ViewFederalSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/FederalSchoolFeeding/ViewFederalSchoolFeeding2025';
import CreateMunicipalSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/MunicipalSchoolFeeding/CreateMunicipalSchoolFeeding2025';
import CreateStateSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/StateSchoolFeeding/CreateStateSchoolFeeding2025';
import ViewCifa2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/ViewCifa2025';
import ManageCifa2025 from 'pages/service_management/Cifa/2025/manage_cifa_2025';
import ComplianceTerm from 'pages/service_management/Cifa/2026/CifaCrud2026/ComplianceTerm';
import CreateCifa2026 from 'pages/service_management/Cifa/2026/CifaCrud2026/CreateCifa2026';
import TechAssist from 'pages/service_management/Cifa/2026/CifaCrud2026/TechAssist';
import ManageCifa2026 from 'pages/service_management/Cifa/2026/ManageCifa2026';
import MenuCifa from 'pages/service_management/menu_cifa';
import MenuService from 'pages/service_management/menu_service';
import { TermsOfUseAndConfidentiality } from 'pages/terms_of_use_and_confidentiality';
import { ManageUser, MenuUser, UserView, UserForm } from 'pages/user';
import React from 'react';
import Home from '../pages/home';
import { Login, LoginValidation } from '../pages/login';
import NotFoundPage from '../pages/not_found_page';
import { ROUTES } from './constants';
import ViewMunicipalSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/MunicipalSchoolFeeding/ViewMunicipalSchoolFeeding2025';
import ViewStateSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/StateSchoolFeeding/ViewStateSchoolFeeding2025';
import EditCifa2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/EditCifa2025';
import EditFederalSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/FederalSchoolFeeding/EditFederalSchoolFeeding2025';
import EditStateSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/StateSchoolFeeding/EditStateSchoolFeeding2025';
import EditMunicipalSchoolFeeding2025 from 'pages/service_management/Cifa/2025/CifaCrud2025/MunicipalSchoolFeeding/EditMunicipalSchoolFeeding2025';
import FirstRegisterUser from 'pages/first_register_user';
import InitialSteps from 'pages/first_register_user/initial_steps';
import TermOfAdhesion from 'pages/term_of_adhesion';
import RegisterTermOfAdhesion from 'pages/term_of_adhesion/register_term_of_adhesion';
import ViewTermsAdhesion from 'pages/term_of_adhesion/view_terms_adhesion';
import ValidateTermAdhesion from 'pages/term_of_adhesion/validate_term_adhesion';
import EditTermOfAdhesion from 'pages/term_of_adhesion/edit_term_adhesion';

export interface RouteConfig {
  path: string;
  element: React.FC<any>;
  protectedRoute: boolean;
  exact?: boolean;
}

const RoutesConfig: RouteConfig[] = [
  {
    path: '*',
    element: NotFoundPage,
    protectedRoute: false,
  },
  {
    path: ROUTES.LOGIN,
    element: Login,
    protectedRoute: false,
  },
  {
    path: ROUTES.LOGIN_VALIDATION,
    element: LoginValidation,
    protectedRoute: false,
  },
  {
    path: ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY,
    element: TermsOfUseAndConfidentiality,
    protectedRoute: false,
  },
  {
    path: ROUTES.HOME,
    element: Home,
    protectedRoute: true,
  },
  {
    path: ROUTES.MENU_USER,
    element: MenuUser,
    protectedRoute: true,
  },
  {
    path: ROUTES.MANAGE_USER,
    element: ManageUser,
    protectedRoute: true,
  },
  {
    path: ROUTES.USER_CREATE,
    element: UserForm,
    protectedRoute: false,
  },
  {
    path: ROUTES.USER_EDIT,
    element: UserForm,
    protectedRoute: true,
  },
  {
    path: ROUTES.USER_VIEW,
    element: UserView,
    protectedRoute: true,
  },
  {
    path: ROUTES.INSTITUTION,
    element: Institution,
    protectedRoute: true,
  },
  {
    path: ROUTES.INSTITUTION_VIEW,
    element: InstitutionView,
    protectedRoute: true,
  },
  {
    path: ROUTES.INSTITUTION_EDIT,
    element: InstitutionForm,
    protectedRoute: true,
  },
  {
    path: ROUTES.MANAGE_SERVICE,
    element: MenuService,
    protectedRoute: true,
  },
  {
    path: ROUTES.MANAGE_CIFA,
    element: MenuCifa,
    protectedRoute: true,
  },
  {
    path: ROUTES.CIFA2025,
    element: ManageCifa2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.INCLUDE_CIFA_2025,
    element: CreateCifa,
    protectedRoute: true,
  },
  {
    path: ROUTES.CIFA2026,
    element: ManageCifa2026,
    protectedRoute: true,
  },
  {
    path: ROUTES.INCLUDE_CIFA_2026,
    element: CreateCifa2026,
    protectedRoute: true,
  },
  {
    path: ROUTES.COMPLIANCE_TERM_2026,
    element: ComplianceTerm,
    protectedRoute: true,
  },
  {
    path: ROUTES.TECH_ASSIST_2026,
    element: TechAssist,
    protectedRoute: true,
  },
  {
    path: ROUTES.CREATE_FEDERAL_SCHOOL_FEEDING_2025,
    element: CreateFederalSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.CREATE_STATE_SCHOOL_FEEDING_2025,
    element: CreateStateSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.CREATE_MUNICIPAL_SCHOOL_FEEDING_2025,
    element: CreateMunicipalSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.VIEW_CIFA_2025,
    element: ViewCifa2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.VIEW_FEDERAL_SCHOOL_FEEDING_2025,
    element: ViewFederalSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.VIEW_MUNICIPAL_SCHOOL_FEEDING_2025,
    element: ViewMunicipalSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.VIEW_STATE_SCHOOL_FEEDING_2025,
    element: ViewStateSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.EDIT_CIFA_2025,
    element: EditCifa2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.EDIT_FEDERAL_SCHOOL_FEEDING_2025,
    element: EditFederalSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.EDIT_STATE_SCHOOL_FEEDING_2025,
    element: EditStateSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.EDIT_MUNICIPAL_SCHOOL_FEEDING_2025,
    element: EditMunicipalSchoolFeeding2025,
    protectedRoute: true,
  },
  {
    path: ROUTES.FIRSTUSER,
    element: FirstRegisterUser,
    protectedRoute: false,
  },
  {
    path: ROUTES.FIRSTUSERSTEPS,
    element: InitialSteps,
    protectedRoute: false,
  },
  {
    path: ROUTES.TERMOFADHESION,
    element: TermOfAdhesion,
    protectedRoute: true,
  },
  {
    path: ROUTES.REGISTERTERMOFADHESION,
    element: RegisterTermOfAdhesion,
    protectedRoute: true,
  },
  {
    path: ROUTES.VIEWTERMSADHESION,
    element: ViewTermsAdhesion,
    protectedRoute: true,
  },
  {
    path: ROUTES.VALIDATETERMSADHESION,
    element: ValidateTermAdhesion,
    protectedRoute: true,
  },
  {
    path: ROUTES.EDITTETERMSADHESION,
    element: EditTermOfAdhesion,
    protectedRoute: true,
  },
];

export default RoutesConfig;

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Paper,
} from '@mui/material';
import { ConfirmOptionsType } from './DialogContext';

type ConfirmDialogProps = ConfirmOptionsType & {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  content,
  confirmationText,
  cancellationText,
  onConfirm,
  onCancel,
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    PaperComponent={Paper}
    PaperProps={{ sx: { borderRadius: '0.25rem', padding: '8px' } }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        {cancellationText}
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        {confirmationText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;

import { createAgreementTerm } from 'api/agreement-term/agreement-term.api';
import { uploadFile } from 'api/file/file.api';
import { useDialog } from 'components/Dialog/useDialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const useStep2 = () => {
  const { alert } = useDialog();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    dadosTermosAdesao: {
      prefeituraNome: '',
      prefeitura: '',
      dataAssinatura: '',
      status: false,
    },
    anexoArquivo: {
      name: '',
      type: '',
      size: 0,
      base64: '',
    },
  });

  const base64ToFile = (
    base64: string,
    fileName: string,
    fileType: string,
  ): File => {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new File([intArray], fileName, { type: fileType });
  };

  const handleSave = async () => {
    try {
      let fileUuid = '';

      if (formData?.anexoArquivo && formData?.anexoArquivo?.base64) {
        console.log('Convertendo base64 para arquivo...');
        const file = base64ToFile(
          formData?.anexoArquivo?.base64,
          formData?.anexoArquivo?.name,
          formData?.anexoArquivo?.type,
        );

        try {
          console.log('Enviando arquivo...');
          const fileResponse = await uploadFile(
            'TERMO DE ADESAO MUNICIPAL',
            file,
          );
          fileUuid = fileResponse?.uuid;
        } catch (error) {
          console.log('Erro ao enviar arquivo:', error);
          return;
        }
      }

      const payload = {
        institutionUuid: formData?.dadosTermosAdesao?.prefeitura || '',
        signatureAt: formData?.dadosTermosAdesao?.dataAssinatura || '',
        //isActive: formData?.dadosTermosAdesao?.status ? true : false,
        fileUuid: fileUuid || '',
      };

      try {
        const response = await createAgreementTerm(payload);
        console.log('>>> RES <<<', response);
        setIsModalOpen(true);

        alert({
          okText: 'VOLTAR',
          title: 'Termo de Adesão Municipal Cadastrado',
          content: 'O Termo de Adesão Municipal foi cadastrado com sucesso.',
        }).then(() => navigate(ROUTES.TERMOFADHESION));
      } catch (error: any) {
        console.error('Erro ao cadastrar termo de adesão:', error);
        alert({
          okText: 'VOLTAR',
          title: 'Erro ao cadastrar vigência',
          type: 'error',
          content:
            error?.response?.data?.message ??
            'Ocorreu um erro ao tentar salvar o termo de adesão. Por favor, tente novamente.',
        });
      }
    } catch (error) {
      console.error('Erro ao cadastrar termo de adesão:', error);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem('formatDataTerms');
    if (storedData) {
      const parsedData = JSON.parse(storedData);

      if (parsedData.anexoArquivo) {
        parsedData.anexoArquivo.size = Number(parsedData.anexoArquivo.size);
      }

      setFormData(parsedData);
    }
  }, []);

  return {
    navigate,
    formData,
    handleSave,
  };
};

export default useStep2;

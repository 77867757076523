import { useState } from 'react';

type AnswersType = {
  question1: string | null;
  question2: string | null;
};

const useRegisterTermOfAdhesion = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [answers, setAnswers] = useState<AnswersType>({
    question1: null,
    question2: null,
  });

  const handleNext = (newAnswers?: AnswersType) => {
    if (newAnswers) setAnswers(newAnswers);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const steps = ['Dados principais', 'Confirmação dos Dados'];

  return {
    activeStep,
    steps,
    handleNext,
  };
};

export default useRegisterTermOfAdhesion;

import { useState, useEffect } from 'react';
import { FormDataType } from '../use_edit_term_adhesion';
import { getPrefectureWithoutAgreementTerm } from 'api/institutions/institution.api';

const useStep1 = ({
  onNext,
  formData,
  setFormData,
}: {
  onNext: () => void;
  formData: FormDataType;
  setFormData: React.Dispatch<React.SetStateAction<FormDataType>>;
}) => {
  const [prefecture, setPrefecture] = useState<
    { uuid: string; name: string }[]
  >([]);

  useEffect(() => {
    const fetchPrefecture = async () => {
      try {
        const data = await getPrefectureWithoutAgreementTerm();
        console.log({
          uuid: formData.dadosTermosAdesao.prefeitura,
          name: formData.dadosTermosAdesao.prefeituraNome,
        });
        setPrefecture([
          {
            uuid: formData.dadosTermosAdesao.prefeitura,
            name: formData.dadosTermosAdesao.prefeituraNome,
          },
          ...data?.data,
        ]);
      } catch (error) {
        console.error('Erro ao buscar prefeituras:', error);
      }
    };

    if (formData?.dadosTermosAdesao?.prefeitura) fetchPrefecture();
  }, [formData]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileBase64 = reader.result as string;
        setFormData((prev) => ({
          ...prev,
          anexoArquivo: {
            fileUuid: null,
            name: file.name,
            type: file.type,
            size: file.size,
            base64: fileBase64,
          },
        }));
      };
    }
  };

  const handleRemoveFile = () => {
    setFormData((prev) => ({
      ...prev,
      anexoArquivo: null,
    }));
  };

  const handleChange = (field: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      dadosTermosAdesao: {
        ...prev.dadosTermosAdesao,
        [field]: value,
      },
    }));
  };

  const handleNext = () => {
    // Atualiza o nome da prefeitura com base na lista obtida
    const selectedPrefecture = prefecture.find(
      (pre) => pre.uuid === formData.dadosTermosAdesao.prefeitura,
    );
    setFormData((prev) => ({
      ...prev,
      dadosTermosAdesao: {
        ...prev.dadosTermosAdesao,
        prefeituraNome: selectedPrefecture?.name || '',
      },
    }));

    onNext();
  };

  return {
    prefecture,
    handleChange,
    handleFileChange,
    handleRemoveFile,
    handleNext,
    formData,
  };
};

export default useStep1;

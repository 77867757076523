import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import useHeaderFilter from './use_header_filter';
import { ROUTES } from 'routes/constants';

const HeaderFilter: React.FC = () => {
  const {
    navigate,
    setSortAnchor,
    sortAnchor,
    handleSort,
    hasAppliedFilters,
    setFilterAnchor,
    filterAnchor,
    cityFilter,
    setCityFilter,
    handleFilter,
  } = useHeaderFilter();

  return (
    <Box
      sx={{
        backgroundColor: '#0048B6',
        paddingX: '40px',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Button
            variant='outlined'
            endIcon={<ArrowDropDownIcon />}
            sx={{
              color: '#FFFFFF',
              border: '1px solid #FFFFFF',
              borderRadius: '16px',
              paddingX: '16px',
            }}
            onClick={(e) => setSortAnchor(e.currentTarget)}
          >
            Ordenar
          </Button>
          <Menu
            anchorEl={sortAnchor}
            open={Boolean(sortAnchor)}
            onClose={() => setSortAnchor(null)}
          >
            <MenuItem onClick={() => handleSort('city-asc')}>
              Cidade (A-Z)
            </MenuItem>
            <MenuItem onClick={() => handleSort('city-desc')}>
              Cidade (Z-A)
            </MenuItem>
            <MenuItem onClick={() => handleSort('date-new')}>
              Mais recente
            </MenuItem>
            <MenuItem onClick={() => handleSort('date-old')}>
              Mais antiga
            </MenuItem>
          </Menu>
          <Button
            variant='outlined'
            endIcon={<ArrowDropDownIcon />}
            sx={{
              color: hasAppliedFilters() ? '#0048B6' : '#FFFFFF',
              backgroundColor: hasAppliedFilters() ? '#FFFFFF' : 'transparent',
              border: '1px solid #FFFFFF',
              borderRadius: '16px',
              paddingX: '16px',
            }}
            onClick={(e) => setFilterAnchor(e.currentTarget)}
          >
            Filtrar
          </Button>
          <Menu
            anchorEl={filterAnchor}
            open={Boolean(filterAnchor)}
            onClose={() => setFilterAnchor(null)}
          >
            <TextField
              label='Cidade'
              variant='outlined'
              size='small'
              sx={{ margin: '10px' }}
              value={cityFilter}
              onChange={(e) => setCityFilter(e.target.value)}
            />
            <MenuItem onClick={() => handleFilter(null, cityFilter)}>
              Todos
            </MenuItem>
            <MenuItem onClick={() => handleFilter('Ativo', cityFilter)}>
              Ativo
            </MenuItem>
            <MenuItem onClick={() => handleFilter('Bloqueado', cityFilter)}>
              Bloqueado
            </MenuItem>
            <MenuItem onClick={() => handleFilter('Inativo', cityFilter)}>
              Inativo
            </MenuItem>
          </Menu>
          {hasAppliedFilters() && (
            <Button
              variant='text'
              startIcon={<CloseIcon />}
              sx={{ color: '#FFFFFF' }}
            >
              Limpar filtros
            </Button>
          )}
        </Box>
        <Button
          variant='outlined'
          startIcon={<AddIcon />}
          sx={{ color: '#FFFFFF', border: '1px solid #FFFFFF' }}
          onClick={() => navigate(ROUTES.REGISTERTERMOFADHESION)}
        >
          CADASTRAR TERMO DE ADESÃO
        </Button>
      </Box>
    </Box>
  );
};

export default HeaderFilter;

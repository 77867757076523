import axios from 'axios';
import React from 'react';
import CryptoJS from 'crypto-js';
import { Box, IconButton, Tooltip } from '@mui/material';

// import ImagemPDF from '../imagens/pdf_icone.png';
// import ImagemExcel from '../imagens/excel_icone.png';

export const FONT_SIZE = {
  verySmall: 6,
  small: 8,
  smallLarge: 10,
  medium: 12,
  mediumLarge: 14,
  large: 18,
  extraLarge: 20,
};

export const MODOS = {
  LISTAR: 'listar',
  CADASTRAR: 'cadastrar',
  VISUALIZAR: 'visualizar',
  ALTERAR: 'alterar',
  EXCLUIR: 'excluir',
  EXPORTAR_CSV: 'exportar_csv',
  IMPRIMIR: 'imprimir',
  EXPORTAR_PDF: 'exportar_pdf',
};

export const MENSAGENS = {
  EXCLUIR_REGISTRO:
    'Tem certeza que deseja remover o registro selecionado? Essa ação não pode ser desfeita. Deseja continuar?',
  REGISTRO_EXCLUIDO: 'Registro excluído com sucesso!',
  REGISTRO_INATIVADO: 'Registro inativado com sucesso!',
  REGISTRO_ATIVADO: 'Registro ativado com sucesso!',
  REGISTRO_CADASTRADO: 'Registro cadastrado com sucesso!',
  ERRO_OPERACAO: 'Erro ao executar a operação.',
  SAIR_TELA: 'Tem certeza que deseja sair da tela?',

  REGISTRO_CADASTRADO_SUCESSO: 'Registro cadastrado com sucesso!',
  REGISTRO_ALTERADO_SUCESSO: 'Registro alterado com sucesso!',
  REGISTRO_EXCLUIDO_SUCESSO: 'Registro excluído com sucesso!',

  PREENCHIMENTO_OBRIGATORIO: 'é de preenchimento obrigatório',
  PREENCHIMENTO_INVALIDO: 'informado é inválido',

  REGISTRO_JA_CADASTRADO: 'inserido já está cadastrado',

  DATA_MAIOR_DATA_ATUAL: 'informada é maior que a data atual',
  DATA_MENOR_DATA_ATUAL: 'informada é menor que a data atual',
};

export const getCPFFormatado = (CPF = '') => {
  const FormattedCPF = CPF.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
    '$1.$2.$3-$4',
  );
  return FormattedCPF;
};

export const getCNPJFormatado = (CNPJ = '') => {
  return CNPJ.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5',
  );
};

export const getTelefoneFormatado = (Tel = '') => {
  const FormattedTel = Tel.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
  return FormattedTel;
};

export const getDataFormatada = (DataISO = '') => {
  if (!DataISO || DataISO.indexOf('/') > -1) {
    return DataISO;
  }

  const FormattedData = new Date(DataISO).toLocaleDateString('pt-BR');
  return FormattedData;
};

export const validarCPF = (strCPF: string) => {
  let Soma;
  let Resto;
  Soma = 0;

  strCPF = strCPF.replace(/[^0-9]/g, '');
  if (strCPF == '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export const validarCNPJ = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  //CNPJ.replace(/[^0-9]/g, ''),

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(0))) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(1))) return false;

  return true;
};

export const validarData = (data: string) => {
  // Verifica se o formato está correto
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!regex.test(data)) {
    return false;
  }

  const [day, month, year] = data.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  // Verifica se a data é válida
  if (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  ) {
    return true;
  }

  return false;
};

export const parseDate = (dateString: string): string => {
  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  return date.toISOString();
};

export const verificarDatas = (
  dataInicio: string,
  dataFim: string,
  aceitarIgual?: boolean,
) => {
  const inicio = parseDate(dataInicio);
  const fim = parseDate(dataFim);

  if (aceitarIgual) {
    if (fim < inicio) {
      return false;
    }
  } else {
    if (fim <= inicio) {
      return false;
    }
  }

  return true;
};

export const ucFirstAllWords = (str: string) => {
  const pieces = str.split(' ');
  for (let i = 0; i < pieces.length; i++) {
    const j = pieces[i].charAt(0).toUpperCase();
    pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join(' ');
};

export const getNomeFormatado = (str: string, numeros = true) => {
  const Ex = /[^a-zA-ZáéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕÇ\s]/g;
  const ExNumeros = /[^a-zA-Z0-9-/áéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕÇ\s]/g;
  return str.replace(numeros ? ExNumeros : Ex, '').trim();
};

export const validarEmail = (Email: string) => {
  const RegexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (RegexEmail.test(Email)) {
    return true;
  }

  return false;
};

// Função para criptografar uma string
export const criptografar = (data: string) => {
  const encrypted = CryptoJS.AES.encrypt(
    data,
    process.env.SECRET_CRIPTO ?? '',
  ).toString();
  return encrypted;
};

// Função para descriptografar uma string
export const decriptografar = (data: string) => {
  const bytes = CryptoJS.AES.decrypt(data, process.env.SECRET_CRIPTO ?? '');
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export const getVersao = () => {
  return process.env.VERSAO ?? '';
};

export const getfloatToTimeString = (HorasFloat: number): string => {
  try {
    //if (isNaN(HorasFloat)) return '00:00';
    const hours = Math.floor(HorasFloat);
    const minutes = Math.round((HorasFloat - hours) * 60);
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    return `${paddedHours}:${paddedMinutes}`;
  } catch (e) {
    //console.log('Erro ao converter horas', e);
    return '00:00';
  }
};

// export const ExportarPDF = (props: any) => (
//   <Tooltip
//     title='Exportar PDF'
//     componentsProps={{ tooltip: { sx: { fontSize: '0.9rem' } } }}
//   >
//     <Box m={1} ml={2}>
//       <IconButton
//         style={{ padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }}
//         onClick={props.onClick}
//       >
//         <img
//           src={ImagemPDF} // Substitua pela URL da imagem do PDF
//           alt='PDF'
//           style={{ width: 24, height: 24 }}
//         />
//       </IconButton>
//     </Box>
//   </Tooltip>
// );
// export const ExportarExcel = (props: any) => (
//   <Tooltip
//     title='Exportar Excel'
//     componentsProps={{ tooltip: { sx: { fontSize: '0.9rem' } } }}
//   >
//     <Box m={1} ml={0}>
//       <IconButton
//         style={{ padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }}
//         onClick={props.onClick}
//       >
//         <img
//           src={ImagemExcel} // Substitua pela URL da imagem do PDF
//           alt='Excel'
//           style={{ width: 24, height: 24 }}
//         />
//       </IconButton>
//     </Box>
//   </Tooltip>
// );

export const getMascaraMoeda = (value: string | number): string => {
  if (value == undefined) return '';
  if (value === 0 || value === '0') return 'R$ 0,00';
  if (typeof value === 'number') {
    return maskCurrency(value);
  } else {
    const onlyDigits = value
      .split('')
      .filter((s) => /\d/.test(s))
      .join('')
      .padStart(3, '0');
    const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
    return maskCurrency(parseFloat(digitsFloat));
  }
};

const maskCurrency = (valor: number): string => {
  const locale = 'pt-BR';
  const currency = 'BRL';

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(valor);
};

export const getFloatPorcent = (value: string): number => {
  try {
    const float = parseFloat(value.replace(/[^0-9,.-]/g, '').replace(',', '.'));
    return float;
  } catch (e) {
    return 0;
  }
};
export const getFloat = (value: string): number => {
  try {
    const sanitizedValue = value
      .replace(/[^0-9,.-]/g, '') // Remove qualquer caractere que não seja número, vírgula, ponto ou traço
      .replace(/\./g, '') // Remove os pontos (separadores de milhar)
      .replace(',', '.'); // Substitui a vírgula pelo ponto (separador decimal)

    const float = parseFloat(sanitizedValue);
    return float;
  } catch (e) {
    return 0;
  }
};

export const getMesesDoAno = () => {
  return [
    { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'Julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembro' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' },
    { value: 12, label: 'Dezembro' },
  ];
};

export const getAnosReferencia = () => {
  const AnoAtual = new Date().getFullYear();
  const ArrayAnos = [{ value: '2024', label: '2024' }];

  if (ArrayAnos.findIndex((a) => a.value == AnoAtual.toString()) == -1)
    ArrayAnos.push({ value: AnoAtual.toString(), label: AnoAtual.toString() });
  return ArrayAnos;
};

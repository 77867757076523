import React, { useState } from 'react';

import type { AlertColor, SnackbarCloseReason } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';

interface ErroFeedbackProps {
  isOpen: boolean;
  type: AlertColor;
  message: string;
}

export const SnackbarFeedback: React.FC<ErroFeedbackProps> = ({
  isOpen,
  type,
  message,
}: ErroFeedbackProps) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ mb: '2rem' }}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        variant='filled'
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

import { GetAnswersPayload, PostAnswersPayload } from './dtos/answers.dto';
import {
  fetchConsultar,
  fetchCadastrar,
  fetchAlterar,
  ResponseAPI,
  ResponseJSON,
} from 'api';

const ROTA = 'forms/answers';

export const postAnswers = async (
  payload: PostAnswersPayload,
): Promise<ResponseAPI> => {
  return fetchCadastrar(ROTA, payload);
};

export const getAnswers = async (
  payload: GetAnswersPayload,
): Promise<ResponseAPI | ResponseJSON> => {
  const { userAccessUuid, institutitonUuid, referencedYear } = payload;

  let Query = '';
  if (userAccessUuid) Query += `userAccessUuid=${userAccessUuid}&`;
  if (institutitonUuid) Query += `institutitonUuid=${institutitonUuid}&`;
  if (referencedYear) Query += `referencedYear=${referencedYear}&`;

  return await fetchConsultar(`${ROTA}/?${Query}`);
};

export const getAnswersSchoolFeeding = async (
  payload: GetAnswersPayload,
): Promise<ResponseAPI | ResponseJSON> => {
  const { userAccessUuid, institutitonUuid, referencedYear } = payload;

  let Query = '';
  if (userAccessUuid) Query += `userAccessUuid=${userAccessUuid}&`;
  if (institutitonUuid) Query += `institutitonUuid=${institutitonUuid}&`;
  if (referencedYear) Query += `referencedYear=${referencedYear}&`;

  return await fetchConsultar(`${ROTA}/?${Query}`);
};

export const putAnswers = async (
  payload: PostAnswersPayload,
  id: string,
): Promise<ResponseAPI> => {
  return fetchAlterar(`${ROTA}/${id}`, payload);
};

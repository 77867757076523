import { isResponseAPI, ResponseAPI, ResponseJSON } from 'api';
import { IUser } from 'modules/auth/interfaces/storage.interface';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import {
  getTokenAPIInterna,
  getTokenMtLogin,
  getUserInfoMtLogin,
} from 'services/auth/api_service';
import { authService } from 'services/auth/auth_service';
import { getURLMTLogout } from 'services/auth/config';

export enum AuthStatus {
  requestError = 'REQUEST_ERROR',
  validityExpired = 'VALIDITY_EXPIRED',
  sessionExpired = 'SESSION_EXPIRED',
  userNotFound = 'USER_NOT_FOUND',
  userBlocked = 'USER_BLOCKED',
  userInactive = 'USER_INACTIVE',
  userAcceptTerms = 'USER_ACCEPT_TERMS',
  userAuthorized = 'USER_AUTHORIZED',
  falhaLogin = 'FALHA_LOGIN',
  representanteMuniciapInativo = 'REPRESENTANTE_MUNICIPIO_INATIVO',
}

interface AuthContextData {
  user: IUser | null;
  isAuthenticated: boolean;
  isSessionValidating: boolean;
  authenticateWithMtLogin: (
    code: string,
  ) => Promise<{ success: boolean; status: AuthStatus; message?: string; userInfo?: IUser }>;
  validateExistingSession: () => Promise<{
    success: boolean;
    status: AuthStatus;
    message?: string;
  }>;
  checkAuthStatus: () => Promise<{
    success: boolean;
    status: AuthStatus;
    message?: string;
  }>;
  logout: () => void;
  setUsuarioJaAceitouTermoUsoLocalStorage: (value: boolean) => void;
  getUsuarioJaAceitouTermoUsoLocalStorage: () => boolean | null;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSessionValidating, setIsSessionValidating] = useState(true);

  const validateExistingSession = useCallback(async () => {
    setIsSessionValidating(true);

    const token = authService.getMtLoginTokeLocalStorage();
    if (!token) {
      setIsSessionValidating(false);
      return {
        success: false,
        status: AuthStatus.sessionExpired,
        message: 'Failed to get token from MtLogin',
      };
    }

    const userInfoResult = await getUserInfoMtLogin(token);

    if (!userInfoResult.userInfo) {
      setIsSessionValidating(false);

      navigate(ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY);

      return {
        success: false,
        status: AuthStatus.sessionExpired,
        message: userInfoResult.error?.error_description,
      };
    }

    const userStatusResult = await authService.checkUserStatusByCpf(
      userInfoResult.userInfo.cpf,
    );

    if (userStatusResult.success) {
      setUser(userStatusResult.userInfo!);
      setIsAuthenticated(true);
      authService.setUserDataLocalStorage(userStatusResult.userInfo!);

      setIsSessionValidating(false);
      if (!userStatusResult.userInfo!.hasAcceptedTerms) {
        return { success: true, status: AuthStatus.userAcceptTerms };
      }

      return { success: true, status: AuthStatus.userAuthorized };
    } else {
      return userStatusResult;
    }
  }, [navigate]);

  const authenticateWithMtLogin = useCallback(
    async (code: string) => {
      try {
        let mtToken = authService.getMtLoginTokeLocalStorage();

        if(!mtToken){
          const tokenResult = await getTokenMtLogin(code);

          if (!tokenResult.accessToken) {
            return {
              success: false,
              status: AuthStatus.sessionExpired,
              message: 'Failed to get token from MtLogin',
            };
          }
          mtToken  = tokenResult.accessToken;
          authService.setMtLoginTokeLocalStorage(mtToken);
        }

        const RespTokenAPIInterna = await getTokenAPIInterna(
          mtToken,
        );

        if (RespTokenAPIInterna.error && RespTokenAPIInterna.error.error === 'validity' ) {
          return {
            success: false,
            status: AuthStatus.validityExpired,
            message: 'Account validity has expired',
            userInfo: RespTokenAPIInterna.error.userInfo,
          };
        }

        if (!RespTokenAPIInterna.token ) {
          return {
            success: false,
            status: AuthStatus.sessionExpired,
            message: 'Failed to get token from API Interna',
          };
        }

        authService.setTokenAPIInterna(RespTokenAPIInterna.token);

        return await validateExistingSession();
      } catch (error) {
        return {
          success: false,
          status: AuthStatus.sessionExpired,
          message: 'Authentication failed',
        };
      }
    },
    [validateExistingSession],
  );

  const checkAuthStatus = useCallback(async () => {
    setIsSessionValidating(true);
    const token = authService.getMtLoginTokeLocalStorage();

    if (!token) {
      setIsSessionValidating(false);
      return {
        success: false,
        status: AuthStatus.sessionExpired,
        message: 'Sessão expirada',
      };
    }

    const userInfoResult = await getUserInfoMtLogin(token);

    if (!userInfoResult.userInfo) {
      setIsSessionValidating(false);
      return {
        success: false,
        status: AuthStatus.sessionExpired,
        message: userInfoResult.error?.error_description,
      };
    }

    const userStatusResult = await authService.checkUserStatusByCpf(
      userInfoResult.userInfo.cpf,
    );

    if (userStatusResult.success) {
      setUser(userStatusResult.userInfo!);
      setIsAuthenticated(true);
      authService.setUserDataLocalStorage(userStatusResult.userInfo!);
      setIsSessionValidating(false);
      return { success: true, status: AuthStatus.userAuthorized };
    } else {
      setIsSessionValidating(false);
      return userStatusResult;
    }
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
    authService.removerItensLocalStorage();
    window.location.href = getURLMTLogout();
  }, []);

  useEffect(() => {
    validateExistingSession();
  }, [validateExistingSession]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isSessionValidating,
        authenticateWithMtLogin,
        validateExistingSession,
        checkAuthStatus,
        logout,
        setUsuarioJaAceitouTermoUsoLocalStorage:
          authService.setUsuarioJaAceitouTermoUsoLocalStorage,
        getUsuarioJaAceitouTermoUsoLocalStorage:
          authService.getUsuarioJaAceitouTermoUsoLocalStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  return useContext(AuthContext);
};

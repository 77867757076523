import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormDataType } from './types';
import { ROUTES } from 'routes/constants';
import { createUser } from 'api/user/user.api';
import { uploadFile } from 'api/file/file.api';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
  question5: string | null;
};

interface Step2Props {
  onNext: (answers: AnswersType) => void;
}

const useStep2 = ({ onNext }: Step2Props) => {
  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormDataType>({
    dadosInstituicao: {
      fantasyName: '',
      identification: '',
      name: '',
      uuid: '',
    },
    dadosPessoais: {
      nomeCompleto: '',
      cpf: '',
      email: '',
      municipio: '',
      cargo: '',
      funcao: '',
      telefone1: '',
      telefone2: '',
    },
    dadosLogin: {
      role: '',
      senha: '',
      confirmarSenha: '',
    },
    dadosVigencia: {
      vigenciaIndeterminada: false,
      dataInicial: '',
      dataFinal: '',
    },
    anexoArquivo: null,
  });

  useEffect(() => {
    const storedData = localStorage.getItem('formData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);

      if (parsedData.anexoArquivo) {
        parsedData.anexoArquivo.size = Number(parsedData.anexoArquivo.size);
      }

      setFormData(parsedData);
    }
  }, []);

  const base64ToFile = (
    base64: string,
    fileName: string,
    fileType: string,
  ): File => {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new File([intArray], fileName, { type: fileType });
  };

  const handleSave = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      let fileUuid = '';

      if (formData.anexoArquivo && formData.anexoArquivo.base64) {
        console.log('Convertendo base64 para arquivo...');
        const file = base64ToFile(
          formData.anexoArquivo.base64,
          formData.anexoArquivo.name,
          formData.anexoArquivo.type,
        );

        console.log('Arquivo convertido:', file);

        try {
          console.log('Enviando arquivo...');
          const fileResponse = await uploadFile('RG', file);
          fileUuid = fileResponse.uuid;
          console.log('Arquivo enviado com sucesso:', fileResponse);
        } catch (fileError) {
          console.error('Erro ao enviar arquivo:', fileError);
          setErrorMessage('Erro ao enviar arquivo. Tente novamente.');
          setIsErrorModalOpen(true);
          setIsLoading(false);
          return;
        }
      }

      const formattedCpf = maskCpf(formData?.dadosPessoais?.cpf || '');

      const userData = {
        name: formData?.dadosPessoais?.nomeCompleto,
        identification: formattedCpf,
        email: formData?.dadosPessoais?.email,
        password: formData?.dadosLogin?.senha,
        contactList: [
          {
            type: 'CELULAR',
            value: formData?.dadosPessoais?.telefone1,
          },
        ],
        position: formData?.dadosPessoais?.cargo,
        occupation: formData?.dadosPessoais?.funcao,
        address: {
          municipality: formData?.dadosPessoais?.municipio,
        },
        isRegisterActive: true,
        isRegisterBlocked: false,
        hasAcceptedTermOfUse: true,
        hasAcceptedConfidentialityTerm: true,
        istitutionUuid: formData?.dadosInstituicao?.uuid,
        permissionUuid: formData?.dadosLogin?.role,
        representativeFileUuid: fileUuid,
        /* dadosVigencia: {
          vigenciaIndeterminada: formData?.dadosVigencia?.vigenciaIndeterminada,
          dataInicial: formData?.dadosVigencia?.dataInicial,
          dataFinal: formData?.dadosVigencia?.dataFinal,
        }, */
      };

      try {
        const response = await createUser(userData);
        console.log('Usuário criado com sucesso:', response);
        setIsSuccessModalOpen(true);
      } catch (userError: any) {
        setErrorMessage(userError?.response?.data?.message);
        setIsErrorModalOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    navigate(ROUTES.LOGIN);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const maskCpf = (cpf: string) => {
    return cpf
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  };

  const maskPhone = (phone: string) => {
    return phone
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d{1,4})$/, '$1-$2');
  };

  const maskDate = (date: string) => {
    return date
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d{4})$/, '$1/$2');
  };

  return {
    isSuccessModalOpen,
    isErrorModalOpen,
    errorMessage,
    isLoading,
    formData,
    handleSave,
    handleCloseSuccessModal,
    handleCloseErrorModal,
    maskCpf,
    maskPhone,
    maskDate,
  };
};

export default useStep2;

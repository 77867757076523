import { Box, Button, Stack } from '@mui/material';
import useStep2 from './use_step2';
import DataConfirmation from 'components/shared/data_confirmation';
import AlertModal from 'components/shared/alert_modal';
import { formatDate } from 'utils/format_date';

interface Step2Props {
  onBack: () => void;
  formData: any;
}

const Step2: React.FC<Step2Props> = ({ onBack, formData }) => {
  const { handleSave, handleCloseModal, isModalOpen } = useStep2(formData);

  return (
    <Stack marginX='4rem' spacing='2rem'>
      <DataConfirmation
        title='Dados do Termo de Adesão'
        data={[
          {
            label: 'Prefeitura',
            value: formData.dadosTermosAdesao.prefeituraNome,
          },
          {
            label: 'Data Assinatura',
            value: formatDate(formData.dadosTermosAdesao.dataAssinatura),
          },
          {
            label: 'Status',
            value: formData.dadosTermosAdesao.status ? 'Ativo' : 'Inativo',
          },
        ]}
      />
      <DataConfirmation
        title='Anexar Termo de Adesão'
        atas={[
          {
            titlePDF: 'Arquivo Enviado',
            label: formData.anexoArquivo?.name || '',
            fileName: formData.anexoArquivo?.type || '',
            fileSize: formData.anexoArquivo
              ? `${(formData.anexoArquivo.size / 1024).toFixed(0)} KB`
              : '',
            onView: () => console.log('Visualizar documento'),
          },
        ]}
      />

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={2}
        marginTop='20px'
      >
        <Button
          variant='text'
          onClick={onBack}
          sx={{
            color: '#1976D2',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          Voltar
        </Button>

        <Button
          variant='contained'
          onClick={handleSave}
          sx={{
            backgroundColor: '#0048B6',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            '&:hover': { backgroundColor: '#003399' },
          }}
        >
          Confirmar e salvar
        </Button>
      </Box>

      <AlertModal
        open={isModalOpen}
        title='Termo de Adesão Municipal Cadastrado'
        message='O Termo de Adesão Municipal foi cadastrado com sucesso.'
        buttonLabel='Voltar'
        onClose={handleCloseModal}
      />
    </Stack>
  );
};

export default Step2;

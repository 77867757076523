import { Header } from 'components/home';
import { StepperHorizontal } from 'components/shared/stepper_horizontal';
import { TopNavBar } from 'components/top_nav_bar';
import { MenuProvider } from 'contexts/menu/menu_context';
import React, { useState } from 'react';
import Step2 from './step2/index';
import Step1 from './step1/index';
import ContainerPainel from 'components/container_painel';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
};

const InitialSteps: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [answers, setAnswers] = useState<AnswersType>({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
  });
  const steps = ['Dados principais', 'Confirmação dos Dados'];

  const handleNext = (newAnswers?: AnswersType) => {
    if (newAnswers) setAnswers(newAnswers);
    setActiveStep((prevStep) => prevStep + 1);
  };

  return (
    <ContainerPainel
      pagina='Cadastro de Usuário - Novo Representante'
      showBackButton={true}
    >
      <StepperHorizontal activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <Step1 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 1 && (
        <Step2 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
    </ContainerPainel>
  );
};

export default InitialSteps;

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ContainerForm } from 'components/shared/container_form';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useStep1 from './use_step1';

type AnswersType = {
  question1: string | null;
  question2: string | null;
};

interface Step1Props {
  onNext: (answers: AnswersType) => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const {
    prefecture,
    handleChange,
    handleFileChange,
    handleRemoveFile,
    handleNext,
    formData,
  } = useStep1({ onNext });

  return (
    <Stack marginX='4rem' spacing='2rem'>
      {/* Dados do Termo de Adesão */}
      <ContainerForm title='Dados do Termo de Adesão'>
        <Grid container spacing={2}>
          {/* Campo Prefeitura */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Prefeitura</InputLabel>
              <Select
                value={formData.dadosTermosAdesao.prefeitura}
                onChange={(e) => handleChange('prefeitura', e.target.value)}
                label='Prefeitura'
              >
                {prefecture.map((pre) => (
                  <MenuItem key={pre.uuid} value={pre.uuid}>
                    {pre.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Campo Data de Assinatura */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label='Data da Assinatura'
              type='date'
              InputLabelProps={{ shrink: true }}
              value={formData.dadosTermosAdesao.dataAssinatura}
              onChange={(e) => handleChange('dataAssinatura', e.target.value)}
            />
          </Grid>

          {/* Campo Status (Sim/Não) */}
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <Typography
                variant='body1'
                gutterBottom
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#000000DE',
                }}
              >
                Termo de Adesão Municipal Ativo:
              </Typography>
              <RadioGroup
                row
                value={formData.dadosTermosAdesao.status}
                onChange={(e) => handleChange('status', e.target.value)}
              >
                <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
                <FormControlLabel value='Não' control={<Radio />} label='Não' />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </ContainerForm>

      {/* Anexar Termo de Adesão */}
      <ContainerForm
        title='Anexar Termo de Adesão'
        subtitle='Realize abaixo o upload do Termo de Adesão Municipal assinado.'
      >
        <Button
          variant='outlined'
          component='label'
          startIcon={<AttachFileIcon />}
          sx={{
            color: '#1976D2',
            border: '1px solid #1976D2',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            width: '30%',
            minWidth: '200px',
          }}
        >
          ANEXAR TERMO DE ADESÃO MUNICIPAL
          <input type='file' hidden accept='.pdf' onChange={handleFileChange} />
        </Button>

        <Typography fontSize='14px' color='gray' mt={1}>
          Arquivo em PDF (máximo de 20MB)
        </Typography>

        {formData.anexoArquivo.name && (
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
              marginTop: '16px',
              borderRadius: '8px',
              backgroundColor: '#F5F5F5',
            }}
          >
            <Box display='flex' alignItems='center' gap={2}>
              <AttachFileIcon color='disabled' />
              <Box>
                <Typography fontWeight='bold'>
                  {formData.anexoArquivo.name}
                </Typography>
                <Typography fontSize='12px' color='gray'>
                  Arquivo PDF, {(formData.anexoArquivo.size / 1024).toFixed(0)}{' '}
                  kb
                </Typography>
              </Box>
            </Box>

            <Box display='flex' alignItems='center'>
              <IconButton onClick={handleRemoveFile}>
                <CloseIcon />
              </IconButton>
              <Box
                sx={{
                  backgroundColor: 'green',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }}
              >
                <CheckCircleIcon sx={{ color: 'white', fontSize: '20px' }} />
              </Box>
            </Box>
          </Paper>
        )}
      </ContainerForm>

      {/* Botões */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={2}
        marginTop='20px'
      >
        <Button
          variant='text'
          sx={{
            color: '#1976D2',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          Voltar
        </Button>

        <Button
          variant='contained'
          onClick={handleNext}
          sx={{
            backgroundColor: '#0048B6',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            '&:hover': {
              backgroundColor: '#003399',
            },
          }}
        >
          Avançar
        </Button>
      </Box>
    </Stack>
  );
};

export default Step1;
